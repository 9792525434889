import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onlogout } from "../../../../../redux/slice/User.slice";
import { useSupprimerUserMutation } from "../../../../../utils/api/user/user.api";
import { SuppressionTypeFromData } from "../../../../../utils/api/user/user.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/Utils";

function UseDeleteAccountForm() {
  const user = useAppSelector((s) => s?.user?.user);
  const validationSchema = yup.object().shape({
    password: yup.string().label("Le mot de passe").required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<SuppressionTypeFromData>({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useAppDispatch();

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useSupprimerUserMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Votre compte a été supprimé avec succés",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        showConfirmButton: true,
        // timer: 5000,
        allowOutsideClick: false,
      }).then(() => {
        reset();
        onHide("deleteAccountModal");
        dispatch(onlogout());
        window.location.pathname = "/";
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data ? err?.data : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleReset = () => {
    reset();
  };

  const onSubmit = async (data: SuppressionTypeFromData) => {
    await sendData(data);
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseDeleteAccountForm;
