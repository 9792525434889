import React from "react";
import { useStep } from "react-hooks-helper";
import { BiUserPlus } from "react-icons/bi";
import BreadCrumb from "../../../shared/BreadCrumb";
import { useStepType } from "../../../../utils/type";
import SecuriteRh from "./ParametreRhTabs/SecuriteRh";
import PolitiqueRh from "./ParametreRhTabs/PolitiqueRh";
import AddOrUpdateSécurite from "./Modals/AddOrUpdateSécurite";
import AddOrUpdatePolitiqueRh from "./Modals/AddOrUpdatePolitiqueRh";

const steps = [
  { id: "securite", Component: SecuriteRh },
  { id: "politique-rh", Component: PolitiqueRh },
];

const tabs = ["Sécurité", "Politique de confidentialité"];

function ParametreRh() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Paramètres" title="Paramètres" />
        </div>
        {index === 0 && (
          <>
            {/* <button
              className="workcare-dash-admin-btn-ajout"
              data-bs-toggle="modal"
              data-bs-target="#AddOrUpdateSecurite"
            >
              <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button> */}

            <div
              className="modal fade"
              id="AddOrUpdateSecurite"
              aria-labelledby="AddOrUpdateSecuriteLabel"
              aria-hidden="true"
            >
              <AddOrUpdateSécurite modalId="AddOrUpdateSecurite" />
            </div>
          </>
        )}
        {index === 1 && (
          <>
            {/* <button
              className="workcare-dash-admin-btn-ajout"
              data-bs-toggle="modal"
              data-bs-target="#AddOrUpdatePolitique"
            >
              <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button> */}

            <div
              className="modal fade"
              id="AddOrUpdatePolitique"
              aria-labelledby="AddOrUpdatePolitiqueLabel"
              aria-hidden="true"
            >
              <AddOrUpdatePolitiqueRh modalId="AddOrUpdatePolitique" />
            </div>
          </>
        )}
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParametreRh;
