import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./BreadCrumb.css";

const BreadCrumb = ({
  pageName,
  title,
  pathName = "/admin/dashboard",
  secondPath,
}: {
  pageName: string;
  title: string;
  pathName?: string;
  secondPath?: string;
}) => {
  return (
    <div className="breadcrumb-component">
      <section className="breadcrumb-container container-fluid">
        <div className="breadcrumb-page-title-content-body flex-sb-mobile">
          <div className="breadcrumb-page-container-title-content-body gap-2">
            <h1 className="workcare-dash-admin-titre">{title}</h1>
            <div className="breadcrumb-row gap-2 d-flex align-items-center">
              <div className="base-home-container d-flex align-items-center">
                <div className="home-label-container">
                  <NavLink to={pathName} className="home-breadcrumb-link">
                    {" "}
                    <span className="home-label">Dashboard</span>
                  </NavLink>
                </div>
                <div className="arrow-icon-container">
                  <MdKeyboardArrowRight className="arrow-icon" />
                </div>
              </div>

              <div className="second-level-breadcrumb-container">
                {!!secondPath && (
                  <NavLink
                    to={secondPath}
                    className="breadcrumb-second-level-label no-link"
                  >
                    {" "}
                    {pageName}{" "}
                  </NavLink>
                )}
                {!secondPath && (
                  <span className="breadcrumb-second-level-label">
                    {" "}
                    {pageName}{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BreadCrumb;
