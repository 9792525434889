import React from 'react'
import BreadCrumb from './BreadCrumb';
import ListeNotificationTable from './ListeNotificationTable';

function ListeNotification() {
    return (
        <div className="dashbord-visiteur-component">
          <div className="workcare-dash-admin-titre-btn-container mb-4">
            <div className="workcare-dash-admin-titre-container">
              <BreadCrumb
                pageName="Notifications"
                title="Notifications"
              />
            </div>
          </div>
          <div className="workcare-dash-admin-page-content-container">
            <ListeNotificationTable />
          </div>
        </div>
      );
}

export default ListeNotification