import React from "react";
import { createMarkup, showModal } from "../../../../utils/Utils";
import { BiEditAlt } from "react-icons/bi";
import AddOrUpdatePolitique from "../Modals/AddOrUpdatePolitique";
import { DeleteCondition } from "./PolitiqueConfidentialite";
import { AlertInfo } from "../../../common/Alert";
import { useGetMentionLegaleQuery } from "../../../../utils/api/condition/condition.api";

function MentionLegale() {
  const { data = { results: [] }, isLoading } = useGetMentionLegaleQuery({});
  return (
    <div>
      <ul className="workcares-custom-ul">
        {data?.results?.length === 0 && <AlertInfo message="Pas de données" />}
        {data?.results?.map((contenu) => (
          <li
            key={contenu?.id}
            className="mb-5 kanimmo-cond-utilisation-ol-custom-li"
          >
            <span className="custom-condition-title">Mention légale</span>
            <div className="kanimmo-cond-utilisation-ol-item-container mt-3">
              <p
                className="mb-3 custom-politique-confidentialite"
                dangerouslySetInnerHTML={createMarkup(contenu?.text)}
              />
            </div>
            <div className="workcares-cond-utilisation-ol-btn-container">
              <button
                className="btn workcares-cond-utilisation-ol-btn-edit"
                // data-bs-toggle="modal"
                // data-bs-target={`#UpdateMentionLegal${contenu?.slug}`}
                onClick={() => showModal(`UpdateMentionLegal${contenu?.slug}`)}
              >
                <BiEditAlt />
              </button>
              <DeleteCondition item={contenu} />

              <div
                className="modal fade"
                id={`UpdateMentionLegal${contenu?.slug}`}
                aria-labelledby="UpdateMentionLegalLabel"
                aria-hidden="true"
              >
                <AddOrUpdatePolitique
                  modalId={`UpdateMentionLegal${contenu?.slug}`}
                  condition={contenu}
                  type="ml"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div
        className="modal fade"
        id="AddOrUpdateMentionLegale"
        aria-labelledby="AddOrUpdateMentionLegaleLabel"
        aria-hidden="true"
      >
        <AddOrUpdatePolitique modalId="AddOrUpdateMentionLegale" type="ml" />
      </div>
    </div>
  );
}

export default MentionLegale;
