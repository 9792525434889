import React from 'react'
import { BtnCloseModal, BtnSubmit } from '../../../common/Button';
import { InputPassword } from '../../../common/Input';
import UseDeleteProfil from '../../Parametres/useForm/UseDeleteProfil';
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { onlogout } from "../../../../redux/slice/User.slice";


function SupprimerProfilModal() {
	const { user } = useAppSelector((s) => s?.user);
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		passwordHandleReset
	  } = UseDeleteProfil(user?.slug);
	
    return (
		<div
			className="modal fade"
			id="deleteProfileModale"
			aria-labelledby="deleteProfileModaleLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header custom-modal-header px-2 py-1">
						<h5 className="modal-title" id="ChangePasswordModalLabel">
                            Supprimer mon profil
						</h5>
                        <div className='workcares-btn-close-modal-container'>
						    <BtnCloseModal label="" onClick={() => passwordHandleReset()} />
                        </div>
					</div>
					<form action="" onSubmit={onSubmit}>
						<div className="row p-3">
							<div className="col-12 mb-3">
								<InputPassword
									label="Mot de passe actuel"
									id="password"
									placeholder="Mot de passe actuel"
									{...register("password")}
                  					error={errors?.password}
								/>
							</div>
							<div className="flex-r">
								<BtnSubmit label="Supprimer" isLoading={isLoading} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default SupprimerProfilModal