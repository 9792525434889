import React from 'react';
import ThematiqueTable from '../Parametrestables/ThematiqueTable';

function Thematique() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <ThematiqueTable />
    </div>
  )
}

export default Thematique;