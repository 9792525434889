import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsCameraFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Avatars from "../../../assets/appImages/Avatars.png";
import { useAppDispatch } from "../../../redux/hooks";
import { onSetUser } from "../../../redux/slice/User.slice";
import { useAddOrEditUserMutation } from "../../../utils/api/user/user.api";
import { IUser } from "../../../utils/api/user/user.type";
import { Color } from "../../../utils/theme";
import { getAvatar, getName, useLocationState } from "../../../utils/Utils";
import { BtnSubmit } from "../../common/Button";
import { InputIcon } from "../../common/Input";
import UseCrudUtilisateur from "./useForm/UseCrudUtilisateur";

function DetailsUtilisateur() {
  const [isFromDetail, setIsFromDetail] = useState(true);
  const user = useLocationState<IUser>(undefined);

  const [avatar, setAvatar] = useState<any>(null);

  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const dispatch = useAppDispatch();
  const { register, onSubmit, errors, isLoading } = UseCrudUtilisateur(
    "modalId",
    user,
    isFromDetail
  );

  useEffect(() => {
    if (done) {
      console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);
  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, data: fd });
  };
  const navigate = useNavigate()
  // console.log("salarie", user)
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="workcare-dash-admin-titre-container">
            <h4 className="workcare-dash-admin-titre-deux">
            <BiArrowBack onClick={() => navigate(-1)} className="workcares-icon-back" />
              Détails de l'utilisateur
            </h4>
          </div>
          <div className="workcares-dash-admin-profil-main-page-container ps-0 py-3">
            <div className="container-profil-user-admin py-5 text-center position-relative">
              <div className="container-img-profil-user mb-4">
                <img
                  src={getAvatar(user?.avatar)}
                  alt="Profil"
                  className="img-profil-user-admin"
                />

                {/* <label
										htmlFor="input-test"
										className="input-testt"
									>
										<img
											src={
												avatar ||
												getAvatar(user?.avatar)
											}
											alt="user avatar"
											className="img-profil-user-admin kanimmo-dash-locataire-param-img-avatar"
										/>
										<div className="kanimmo-user-avatar-edit-hover">
											<BsCameraFill className="edit-avatar-camera-icon" />
										</div>
									</label>
									<input
										type="file"
										className="input-add-avatar"
										id="input-test"
										onChange={(e) => changeImage(e)}
									/> */}
              </div>
              <div className="content-text-name-user">
                <h2 className="name-user-admin">{getName(user)}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <form action="" onSubmit={onSubmit}>
                <div className="row">
                  <p className="custom-titre-details-parametre">
                    Informations personnelles
                  </p>
                  <div className="row d-flex align-items-center  mb-5">
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Prénom"
                        id="prenom"
                        placeholder="Prénom"
                        {...register("prenom")}
                        error={errors?.prenom}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Nom"
                        id="nom"
                        placeholder="Nom"
                        {...register("nom")}
                        error={errors?.nom}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputIcon
                        type="email"
                        label="Adresse mail"
                        id="email"
                        placeholder="Email"
                        {...register("email")}
                        error={errors?.email}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Adresse"
                        id="adresse"
                        placeholder="Adresse"
                        {...register("adresse")}
                        error={errors?.adresse}
                      />
                    </div>
                    {/* <div className="col-md-6">
                      <InputIcon
                        type="password"
                        label="Mot de passe"
                        id="password"
                        placeholder="********"
                      />
                    </div> */}
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Numéro de téléphone"
                        id="phone"
                        placeholder="Téléphone"
                        {...register("telephone")}
                        error={errors?.telephone}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-container flex-r mt-4">
                  <BtnSubmit label="Terminer" isLoading={isLoading} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsUtilisateur;
