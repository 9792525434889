import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BiDownload } from "react-icons/bi";
import { FaRegFileWord, FaTrashAlt } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import word from "../../../../../../assets/icons/word.png";
import pdf from "../../../../../../assets/icons/pdf.png";
import { useGetDocumentsByPatientQuery } from "../../../../../../utils/api/document/document.api";
import { Document } from "../../../../../../utils/api/document/document.type";
import { IRdv } from "../../../../../../utils/api/rdv/rdv.type";
import DocumentDatas from "./DocumentDatas";
import { get_url_extension } from "../../../../../../utils/Utils";
import moment from "moment";
import TableSkeleton from "../../../../../common/TableSkeleton";
import { AlertInfo } from "../../../../../common/Alert";
import { CustomPagination } from "../../../../../common/CustomPagination";
import ShowDocumentFile from "../modal/ShowDocumentFile";
import { BsFileEarmarkPdf } from "react-icons/bs";

function DocumentTable({ rdv }: { rdv: IRdv }) {
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetDocumentsByPatientQuery({
      slug: rdv?.salarie?.slug,
      page,
      limit: perPage,
    });
  // console.log("data docs", data);
  const actionFormatter = (cell: string, row: Document) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-praticient-voir with-tooltip"
            data-tooltip-content="Visualiser"
            data-bs-toggle="modal"
            data-bs-target={`#showDocument${cell}`}
          >
            {get_url_extension(row?.document) === "pdf" && (
              <BsFileEarmarkPdf className="btn-action-praticient-voir-icon" />
            )}
            {get_url_extension(row?.document) !== "pdf" && (
              <FaRegFileWord className="btn-action-praticient-voir-icon" />
            )}
          </button>
          {/* <button
          className="btn btn-action-praticient-edit with-tooltip"
          data-tooltip-content="Modifier"
        >
          <GrEdit />
        </button>
        <button
          className="btn btn-action-praticient-supp with-tooltip"
          data-tooltip-content="Supprimer"
        >
          <FaTrashAlt />
        </button> */}
        </div>
        <ShowDocumentFile item={row} modalId={`showDocument${cell}`} />
      </>
    );
  };

  const documentFormatter = (cell: string) => {
    let name = cell?.substring(cell?.lastIndexOf("/") + 1)?.split(".")[0];
    return (
      <div>
        <img
          src={get_url_extension(cell) !== "pdf" ? word : pdf}
          alt="user avatar"
          className="workcares-table-doc-img"
        />{" "}
        {name}
      </div>
    );
  };

  const columns = [
    {
      dataField: "document",
      text: "Nom du document",
      formatter: (cell: string) => documentFormatter(cell),
    },
    {
      dataField: "created_at",
      text: "Date d’ajout",
      formatter: (cell:string) => moment(cell).format("DD/MM/YYYY")
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Document) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {!!isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DocumentTable;
