import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  CompteRendu,
  CompteRenduFormData,
} from "../../../../../../utils/api/compteRendu/compteRendu.type";
import { useAddOrUpdateCompteRenduMutation, useDeleteCompteRenduMutation } from "../../../../../../utils/api/compteRendu/compteRendu.api";
import { cleannerError, onHide } from "../../../../../../utils/Utils";
import { Color } from "../../../../../../utils/theme";
import { useLocation, useNavigate } from "react-router-dom";

function UseCrudCompteRenduForm(modalId: string, item?: CompteRendu) {
  const location = useLocation();
  let { state } = location;
  console.log("state", state);
  const validationSchema = yup.object().shape({
    id: yup.string(),
    date: yup.string().required().label("La date"),
    fichier: yup.mixed().when("id", {
      is: (val) => !val,
      then: yup.mixed().required().label("Le fichier").nullable(),
    }),
    rdv: yup.string().label("Le rendez-vous").nullable().default(state?.id),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<CompteRenduFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateCompteRenduMutation();
  const [description, setDescription] = React.useState<any>("");

  const handleChange = (value: any) => {
    setValue("commentaire", value);
    setDescription(value);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!state?.id) {
        navigate(-1);
      } else {
        setValue("rdv", state?.id);
      }
    }, 50);
  }, [state]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Compte rendu ${item ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!item) {
          handleReset();
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    console.log(Object.keys(errors));
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: CompteRenduFormData) => {
    console.log("DATA SUBMITED", data);
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "fichier" && data[key]) {
        fd.append(key, data[key]);
      } else {
        fd.append(key, data[key]);
      }
    }
    await sendData({ slug: item?.slug, data: fd });
  };

  useEffect(() => {
    if (item?.id) {
      setValue("id", item?.id);
      let fields: (keyof CompteRenduFormData)[] = [
        "rdv",
        "date",
        "commentaire",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "rdv") {
          setValue(field, item[field]);
        } else {
          setValue(field, item?.rdv?.id);
        }
      }
      if (item?.commentaire) {
        setDescription(item?.commentaire);
      }
    } else {
      setValue("id", "");
    }
  }, [item]);

  const handleReset = () => {
    reset();
    setDescription("");
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleReset,
    handleChange,
    description,
    setValue,
  };
}

export default UseCrudCompteRenduForm;

export function DeleteCompteRendu(slug: string) {
  const [deleteData] = useDeleteCompteRenduMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce compte rendu ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result:any) => {
      
      if (result?.isConfirmed) {
        if (!result?.value?.data?.error) {
          Swal.fire({
            icon: "success",
            title: `Compte rendu supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result?.value?.data?.error?.message
              ? result?.value?.data?.error?.message
              : `Erreur de statut ${result?.value?.data?.error?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
      
    });
  };
  return onDelete;
}