import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRoutes } from "./routes/router";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useMeQuery } from "./utils/api/user/user.api";
import { onSetUser } from "./redux/slice/User.slice";

function App() {
  const routes = useRoutes(AppRoutes);
  const isConnected = useAppSelector((s) => !!s.user?.token);

  return( 
    <div className="app-routing-container">
      {isConnected && <FetchUser />}
      {routes}
    </div>
  )
}

export default App;

function FetchUser() {
  const { isSuccess, data } = useMeQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      // console.log("data", data);
      dispatch(onSetUser(data));
    }
  }, [isSuccess, data]);

  return null;
}
