import React, { useEffect, useState } from "react";
import {
  Pagination as ReactPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import ReactPaginate from "react-paginate";

const PER_PAGE = 24;
type Page = {
  page: number;
  next?: boolean;
  previous?: boolean;
  active: boolean;
};
type PaginationProps = {
  page: number;
  onPageChange: (nextPage: number) => any;
  total: number;
  perPage?: number;
};

function Pagination({
  page,
  onPageChange,
  total,
  perPage = PER_PAGE,
}: PaginationProps) {
  return (
    /* @ts-ignore */
    <ReactPaginate
      breakLabel="..."
      initialPage={page - 1}
      nextLabel=">"
      onPageChange={(page) => onPageChange(page?.selected + 1)}
      pageRangeDisplayed={5}
      pageCount={total}
      previousLabel="<"
      pageClassName="page-item m-1"
      pageLinkClassName="page-link custom-page-link"
      previousClassName="page-item"
      previousLinkClassName="d-none"
      nextClassName="page-item"
      nextLinkClassName="d-none"
      breakClassName="page-item m-1"
      breakLinkClassName="page-link custom-break-link"
      containerClassName="pagination"
      activeClassName="active"
    />
  );
}

export default Pagination;
