import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { useGetQuestionsAutoDiagnosticQuery } from "../../../../utils/api/autoDiagnostique/autoDiagnostique";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";

import { useGetThematiqueQuery } from "../../../../utils/api/thematique/thematique";
import { DeleteThematique } from "../useForm/UseCrudThematique";

import ShowQuestionModal from "../../Contenus/Modals/ShowQuestionModal";
import { Thematique } from "../../../../utils/api/thematique/thematique.type";
import AddOrEditThematiqueModal from "../Modals/AddOrEditThematiqueModal";
import { showModal } from "../../../../utils/Utils";
import { NavLink, useParams } from "react-router-dom";

function ThematiqueTable() {
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetThematiqueQuery({ limit: perPage, page });

  const libelleFormatter = (cell: any, row: any) => {
    return (
      <p className="text-custom-libelle mb-0">
        {" "}
        {!!row?.libelle && row?.libelle}
      </p>
    );
  };

  const actionFormatter = (cell: string, row: Thematique) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <NavLink
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Minutes cares"
            to={`/admin/thematique/${cell}/minutes-cares`}
            state={row}
          >
            <AiFillEye />
          </NavLink>
          <button
            className="btn btn-action-admin with-tooltip"
            data-tooltip-content="Modifier"
            // data-bs-toggle="modal"
            // data-bs-target={`#editThematiqueModal${row?.slug}`}
            onClick={() => showModal(`editThematiqueModal${row?.slug}`)}
          >
            <MdModeEditOutline />
          </button>

          <DeleteThematique item={row} />
        </div>
        <div
          className="modal fade"
          id={`editThematiqueModal${cell}`}
          aria-labelledby={`editThematiqueModal${cell}Label`}
          aria-hidden="true"
        >
          <AddOrEditThematiqueModal
            modalId={`editThematiqueModal${cell}`}
            contenu={row}
          />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "libelle",
      formatter: (cell: any, row: any) => libelleFormatter(cell, row),
      text: "Libelle",
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Thematique) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table thematique-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ThematiqueTable;
