import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/Utils";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { useAppDispatch } from "../../../redux/hooks";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";

function useLoginForm() {
  const dispatch = useAppDispatch()
  const validationSchema = yup.object().shape({
    email: yup.string().required().label("Email"),
    password: yup.string().required().label("Mot de passe"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [loginUser, { isLoading, isSuccess, error, isError, data }] = useLoginUserMutation();

  useEffect(() => {
    if (data) {
      dispatch(onSetUserToken({ user: data.data, token: data.token }));
    }
  }, [data, dispatch]);


  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Connexion réussie!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (isError) {
      const err = error as any;

      const { message = "Une erreur inconnue s'est produite" } = err.data || {};
      setError("email", { message: message });
    }
  }, [isLoading, isSuccess, isError, error, setError]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors, clearErrors]);

  const onSubmit = (data: LoginFormData) => {
      if (data?.email) {
        data["email"] = (data?.email).toLowerCase();
      }
      if (data?.username) {
        data["username"] = (data?.username).toLowerCase();
      }

      console.log(data);
      loginUser(data);
  };
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default useLoginForm;
