/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
  BtnSuprimer,
} from "../../../../common/Button";
import ErrorMessage from "../../../../common/ErrorMessage";
import { InputAgenda, FormError, Input } from "../../../../common/Input";
import "../Disponibilite.css";
import UseCrudDisponibilite, {
  DeleteDispobilite,
} from "../useForm/UseCrudDisponibilite";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function GererDisponibiliteModal({
  disponibilite,
  modalId,
}: {
  disponibilite?: any;
  modalId: string;
}) {
  const {
    register,
    errors,
    onSubmit,
    setValue,
    isLoading,
    ListRh,
    control,
    Controller,
    onChangeEntreprise,
    entreprise,
    handleReset,
    handleChecked,
    isChecked,
  } = UseCrudDisponibilite(modalId, disponibilite);

  useEffect(() => {
    if (disponibilite) {
      setValue("date", disponibilite?.date);
      setValue("slug", disponibilite?.slug);
    }
  }, [disponibilite]);

  const animatedComponents = makeAnimated();

  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header-deux px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {disponibilite ? "Modifier" : "Gérer"} mes disponibilités
          </h5>
          <div className="workcares-btn-close-modal-container custom-workcares-btn-close">
            <BtnCloseModal label="" onClick={handleReset} />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <InputAgenda
                    type="date"
                    label="Date"
                    id="date"
                    {...register("date")}
                  />
                  {errors.date && (
                    <ErrorMessage message={errors.date?.message} />
                  )}
                </div>
                <div className="col-md-6">
                  <InputAgenda
                    type="time"
                    label="Heure de début"
                    id="heure_ouverture"
                    {...register("heure_ouverture")}
                  />
                  {errors.heure_ouverture && (
                    <ErrorMessage message={errors.heure_ouverture?.message} />
                  )}
                </div>
                <div className="col-md-6">
                  <InputAgenda
                    type="time"
                    label="Heure de fin"
                    id="heure_fermeture"
                    {...register("heure_fermeture")}
                  />
                  {errors.heure_fermeture && (
                    <ErrorMessage message={errors.heure_fermeture?.message} />
                  )}
                </div>

                <div className="col-md-12 mb-3">
                  <div className="content-col-form-add-student">
                    <label className="form-label form-label-add-student">
                      {" "}
                      Sélectionner une entreprise
                    </label>
                    <Controller
                      name="entreprise"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti={false}
                            options={ListRh?.map((item) => ({
                              value: item?.id,
                              label:
                                item?.nom_entreprise ||
                                item?.prenom + " " + item?.nom,
                            }))}
                            classNamePrefix="react-select-custom"
                            onChange={onChangeEntreprise}
                            value={entreprise}
                          />
                        );
                      }}
                    />
                    {errors.entreprise && (
                      <ErrorMessage message={errors.entreprise?.message} />
                    )}
                  </div>
                </div>
                {!disponibilite && (
                  <div className="col-md-12 mb-3">
                    <div className="demo-app-sidebar-section ps-2">
                      <label className="fs-13">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleChecked}
                        />
                        Souhaitez-vous rendre cette disponibilité récursive pour
                        ce jour ?
                      </label>
                    </div>
                  </div>
                )}

                {!!isChecked && (
                  <>
                    <div className="col-md-12">
                      <div className="mb-3 text-start">
                        <label
                          htmlFor={"frequence"}
                          className="form-label form-label-modal-custom"
                          aria-labelledby={"frequence"}
                        >
                          Fréquence de la récurrence
                        </label>
                        <select
                          className="form-control form-control-modal-custom"
                          {...register("frequence")}
                          id="frequence"
                        >
                          <option value="">Fréquence</option>
                          <option value="semaine">Hebdomadaire</option>
                          <option value="mois">Mensuelle</option>
                          // <option value="annee">Annuelle</option>
                        </select>

                        {<FormError error={errors?.frequence?.message} />}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <Input
                        type="number"
                        label="Sur combien de mois"
                        id="nombre_de_mois"
                        {...register("nombre_de_mois")}
                      />
                      {errors.nombre_de_mois && (
                        <ErrorMessage
                          message={errors.nombre_de_mois?.message}
                        />
                      )}
                    </div>
                  </>
                )}

                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    {disponibilite ? (
                      <DeleteDisponibiliteBtn
                        slug={disponibilite?.slug}
                        modalid={modalId}
                      />
                    ) : (
                      <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    )}
                    <BtnSubmit
                      label={`${disponibilite ? "Modifier" : "Ajouter"}`}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GererDisponibiliteModal;

function DeleteDisponibiliteBtn({
  slug,
  modalid,
}: {
  slug: string;
  modalid: string;
}) {
  const onDelete = DeleteDispobilite(slug, modalid);

  return (
    <BtnSuprimer label={"Supprimer"} isLoading={false} onClick={onDelete} />
  );
}

{
  /* <div className="custom-agenda-select-heure-label">
                    Sélectionner une entreprise
                  </div>
                  <div className="checkbox-add-event custom-checkbox-container mt-4">
                    
                     {constantAgendas.map((item) => (
                      <div className="label-form-container">
                        <div className="container-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              {...register("horaires")}
                              value={item.label}
                            />
                            <span>{item.label}h</span>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div> */
}
// const constantAgendas = [
//   {
//     id: 1,
//     label: "08",
//   },
//   {
//     id: 2,
//     label: "09",
//   },
//   {
//     id: 3,
//     label: "10",
//     // defaultChecked: true,
//   },
//   {
//     id: 4,
//     label: "11",
//     // defaultChecked: true,
//   },
//   {
//     id: 5,
//     label: "12",
//   },
//   {
//     id: 6,
//     label: "13",
//   },
//   {
//     id: 7,
//     label: "14",
//   },
//   {
//     id: 8,
//     label: "15",
//   },
//   {
//     id: 9,
//     label: "16",
//     defaultChecked: true,
//   },
//   {
//     id: 10,
//     label: "17",
//   },
//   {
//     id: 11,
//     label: "18",
//     defaultChecked: true,
//   },
//   {
//     id: 12,
//     label: "19",
//   },
//   {
//     id: 13,
//     label: "20",
//   },
// ];
