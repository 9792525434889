import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { ApiBaseUrl } from "../../http"
import { prepareHeaders } from "../user/user.api"
import { IRdv } from "./rdv.type"
import { QueryUrl } from "../../Utils"
import { IUser, PaginationResults, TypeQuery } from "../user/user.type"

const RdvApi = createApi({
  reducerPath: "rdvApi",
  tagTypes: ["rdv", "rdvList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getRdvByPraticien: build.query<PaginationResults<IRdv>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`web/praticien/${slug}/rdv/`,query),
      providesTags: ["rdv"],
    }),
    validerRdvByPraticien: build.query<IRdv[], TypeQuery>({
      query: ({ slug }) => `rdv/${slug}/validation/`,
      providesTags: ["rdv"],
    }),
    getRdvByRhAction: build.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`entreprise/${slug}/rdv/`, query),
      providesTags: ["rdvList"],
    }),
    annulerRdvByPraticien: build.query<IRdv[], TypeQuery>({
      query: ({ slug }) => `rdv/${slug}/annulation/`,
      providesTags: ["rdv"],
    }),
    getRdvListQuery: build.query<IRdv[], TypeQuery>({
      query: ({ slug }) => `rdv/${slug}/validation/`,
      providesTags: ["rdv"],
    }),
  }),
});

export default RdvApi

export const {
  useGetRdvByPraticienQuery,
  useAnnulerRdvByPraticienQuery,
  useValiderRdvByPraticienQuery,
  useGetRdvByRhActionQuery,
} = RdvApi
