import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";

ChartJS.register(ArcElement, Tooltip, Legend);

function PerformanceGraph({
  stats,
  isLoading
}: {
  stats: { prise_en_charge?: number; non_prise_en_charge?: number }; isLoading: boolean
}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: ["Prise en charge", "Non prise en charge"],
    datasets: [
      {
        label: "Performance",
        data: [stats?.prise_en_charge, stats?.non_prise_en_charge],
        backgroundColor: ["#0DCFB8", "#F45B5B"],
        borderColor: ["#fff", "#fff"],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="chart-item-container">
      {!!isLoading && <Skeleton width={207} height={207} circle /> }
      {!isLoading && <Doughnut options={options} data={data} />}
      
    </div>
  );
}

export default PerformanceGraph;
