import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { ApiBaseUrl } from "../../http"
import { QueryUrl } from "../../Utils"
import { Diagnostic, DiagnosticFormData } from "../autoDiagnostique/autoDiagnostique.type"
import { ContenusFormdata, IContenus } from "../contenus/contenus.type"
import { prepareHeaders } from "../user/user.api"
import { PaginationResults, TypeQuery } from "../user/user.type"

export const MinuteEvalApi = createApi({
  reducerPath: "minuteEvalApi",
  tagTypes: ["minuteEval", "questionminuteval"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addMinuteEval: build.mutation<
      IContenus,
      { slug?: string; data: IContenus }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `minuteval/${slug}/` : `minuteval/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["minuteEval"],
    }),
    addOrEditMinuteEval: build.mutation<
      Diagnostic,
      {
        slug?: string;
        data: DiagnosticFormData | FormData;
      }
    >({
      invalidatesTags: ["minuteEval", "questionminuteval"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `update-minute-eval/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `create-minute-eval/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getMinuteEval: build.query<PaginationResults<IContenus>, TypeQuery>({
      query: () => `minuteval/`,
      providesTags: ["minuteEval"],
    }),
    deleteMinuteEval: build.mutation<ContenusFormdata, string>({
      query: (slug) => ({
        url: `questionminuteval/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["minuteEval", "questionminuteval"],
    }),
    getQuestionsMinEval: build.query<PaginationResults<Diagnostic>, TypeQuery>({
      query: (query) => QueryUrl(`questionminuteval/`,query),
      providesTags: ["questionminuteval"],
    }),
  }),
});

export const {
  useAddMinuteEvalMutation,
  useDeleteMinuteEvalMutation,
  useGetMinuteEvalQuery,
  useAddOrEditMinuteEvalMutation,
  useGetQuestionsMinEvalQuery
} = MinuteEvalApi
