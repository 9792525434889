import React from "react"
import "./DashboardRh.css"
import Icon1 from "../../../../assets/icons/icon.png"
import Icon2 from "../../../../assets/icons/icon2.png"
import Icon3 from "../../../../assets/icons/icon3.png"
import EvolutionGraphRh from "./Graphs/EvolutionGraphRh"
import ProgressGraphRh from "./Graphs/ProgressGraphRh"
import PerformanceGraphRh from "./Graphs/PerformanceGraphRh"
import { useAppSelector } from "../../../../redux/hooks"
import {
  useGetOrganisationByRhActionQuery,
  useGetSalariesByRhActionQuery,
  useGetDashboardRhWeekQuery,
  useGetDashboardRhMonthQuery,
  useGetDashboardRhYearQuery,
} from "../../../../utils/api/user/user.api"
import { useGetObjectifByRhActionQuery } from "../../../../utils/api/objectif/objectif.api"
import { useGetRdvByRhActionQuery } from "../../../../utils/api/rdv/rdv.api"

function DashboardRh() {
  const user = useAppSelector((s) => s?.user?.user)

  const { data: dashboardWeek } = useGetDashboardRhWeekQuery({
    slug: user?.slug,
  })

  const { data: dashboardMonth } = useGetDashboardRhMonthQuery({
    slug: user?.slug,
  })

  const { data: dashboardYear } = useGetDashboardRhYearQuery({
    slug: user?.slug,
  })

  console.log('dashWeek',dashboardWeek)
  console.log('dashMonth',dashboardMonth)
  console.log('dashYear',dashboardYear)


  return (
    <div className="dashbord-visiteur-component">
      <div className="row row-card-stat-visiteur mb-4">
        <div className="col-md-3 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon1}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">Salariés</p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#F4925B" }}
              >
                {dashboardYear?.salaries_count}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon2}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">Rendez-vous</p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#12CBD6" }}
              >
                {dashboardYear?.rdv_count}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon3}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">Objectifs et Actions</p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#795BF4" }}
              >
                {dashboardYear?.objectifs_count}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon1}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">Salariés engagés</p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#F4925B" }}
              >
                {dashboardYear?.total_salaries_engages}
              </p>
            </div>
          </div>
        </div>
      </div>
        
      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
              <EvolutionGraphRh 
                  donneesGraphWeek={dashboardWeek?.graph_auto_diagnostic}
                  donneesGraphMonth={dashboardMonth?.graph_auto_diagnostic}
                  donneesGraphYear={dashboardYear?.graph_auto_diagnostic}/>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-left-graph-admin mb-3 d-flex">
          <div className="content-col-graph-admin content-col-left-graph-admin w-100">
            <ProgressGraphRh 
                donneesGraphWeek={dashboardWeek?.graph_user_do_eval}
                donneesGraphMonth={dashboardMonth?.graph_user_do_eval}
                donneesGraphYear={dashboardYear?.graph_user_do_eval}/>
          </div>
        </div>
        <div className="col-md-6 col-left-graph-admin mb-3 d-flex">
          <div className="content-col-graph-admin content-col-left-graph-admin w-100">
            <div className="row mb-3">
              <div className="col-md-6 mb-2">
                <h2 className="chart-item-admin-title-admin mb-0">
                  Gss_Indicateurs de performances
                </h2>
              </div>
            </div>
            <div className="container-graph-donut flex-sb">
              <div className="content-graph-donut1 text-center">
                <p className="text-describe-graph-donut mb-4">
                  CES_Facilitation (suivi & prise en charge/Praticien)
                </p>
                <PerformanceGraphRh 
                  percentage_PC={dashboardYear?.percentage_prise_en_charge} 
                  percentage_NPC={dashboardYear?.percentage_non_prise_en_charge} />
                <div className="content-legend-donut-graph mt-4">
                  <div className="content-legend-donut-graph-item">
                    <div
                      style={{
                        background: "rgb(183, 203, 251)",
                        width: "16px",
                        height: "10px",
                        marginTop: "8px",
                      }}
                    />
                    <div className="text-legend-donut-graph-container">
                      <span className="text-legend-donut-graph-rh">{dashboardYear?.percentage_non_prise_en_charge}% </span>
                      <div className="text-legend-donut-graph-rh-2">Non prise en charge</div>
                    </div>
                  </div>
                  <div className="content-legend-donut-graph-item">
                    <div
                      style={{
                        background: "rgb(33, 89, 245)",
                        width: "16px",
                        height: "10px",
                        marginTop: "8px",
                      }}
                    ></div>
                    <div className="text-legend-donut-graph-container">
                      <span className="text-legend-donut-graph-rh">{dashboardYear?.percentage_prise_en_charge}% </span>
                      <div className="text-legend-donut-graph-rh-2">Prise en charge</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-graph-donut1 text-center">
                <p className="text-describe-graph-donut mb-4">
                  GSS_Satisfaction prise en charge entreprise
                </p>
                <PerformanceGraphRh 
                  percentage_PC={dashboardYear?.percentage_prise_en_charge} 
                  percentage_NPC={dashboardYear?.percentage_non_prise_en_charge}  />
                <div className="content-legend-donut-graph mt-4">
                  <div className="content-legend-donut-graph-item">
                    <div
                      style={{
                        background: "rgb(33, 89, 245)",
                        width: "16px",
                        height: "10px",
                        marginTop: "8px",
                      }}
                    />
                    <div className="text-legend-donut-graph-container">
                      <span className="text-legend-donut-graph-rh">40% </span>
                      <div className="text-legend-donut-graph-rh-2">Lorem</div>
                    </div>
                  </div>
                  <div className="content-legend-donut-graph-item">
                    <div
                      style={{
                        background: "rgb(183, 203, 251)",
                        width: "16px",
                        height: "10px",
                        marginTop: "8px",
                      }}
                    />
                    <div className="text-legend-donut-graph-container">
                      <span className="text-legend-donut-graph-rh">60% </span>
                      <div className="text-legend-donut-graph-rh-2">Lorem</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardRh
