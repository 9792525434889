import React from "react";
import PhoneInput from "react-phone-input-2";
import { SpecialiteType } from "../../../../../utils/api/auth/auth.type";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../../common/Button";
import ErrorMessage from "../../../../common/ErrorMessage";
import GoogleInput from "../../../../common/GoogleInput";
import { FormError, Input } from "../../../../common/Input";
import UseEditUserForm from "../useForm/UseEditUserForm";

const SpecialiteOption = [
  {
    value: SpecialiteType.ostéopathe,
    label: "Ostéopathe",
  },
  {
    value: SpecialiteType.psychologue,
    label: "Psychologue",
  },
  {
    value: SpecialiteType.kinésithérapeute,
    label: "Kinésithérapeute",
  },
  {
    value: SpecialiteType.sophrologue,
    label: "Sophrologue",
  },
  {
    value: SpecialiteType.diététicien,
    label: "Diététicien",
  },
  {
    value: SpecialiteType.ergothérapeute,
    label: "Ergothérapeute",
  },
  {
    value: SpecialiteType.ergonome,
    label: "Ergonome",
  },
  {
    value: SpecialiteType.coach_sportif,
    label: "Coach sportif",
  },
  {
    value: SpecialiteType.autre,
    label: "Autre",
  },
];

function ModifierInfosPersosPraticien({
  user,
  modalId,
}: {
  user?: any;
  modalId: string;
}) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    address,
    onChangeAddress,
    phone,
    handleChangePhone,
    specialites,
  } = UseEditUserForm(user, modalId);

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Modifier
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Prénom"
                    id="prenom"
                    placeholder="Prénom"
                    {...register("prenom")}
                    error={errors?.prenom}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors?.nom}
                  />
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="adress"
                      className="form-label form-label-modal-custom"
                    >
                      Adress
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom form-control-params-custom"
                      value={address}
                      onChange={onChangeAddress}
                    />
                    <FormError error={errors?.adresse?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="adress"
                      className="form-label form-label-modal-custom"
                    >
                      Téléphone
                    </label>
                    <PhoneInput
                      
                      containerClass="form-control auth-form-control mb-3 p-0"
                      inputClass="form-control form-control-modal-custom form-control-params-custom"
                      country={"fr"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any) => {
                        handleChangePhone(phone, country);
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <Input
                    type="email"
                    label="Email"
                    id="email"
                    placeholder="Email"
                    {...register("email")}
                    error={errors?.email}
                  />
                </div>

                <div className="col-md-6 auth-input-col">
                  {/* {!!specialites?.length && (
                    
                  )} */}
                  <div className="form-group auth-form-group auth-form-group-login">
                    <label className="form-label form-label-modal-custom">
                      Spécialité
                    </label>
                    <select
                      id="specialite"
                      className="form-control form-control-modal-custom"
                      {...register("specialite")}
                    >
                      <option value="">Spécialité</option>
                      {specialites?.map((option) => (
                        <option value={option?.id} key={option?.slug}>
                          {option?.nom}
                        </option>
                      ))}
                    </select>
                    {errors?.specialite && (
                      <ErrorMessage message={errors?.specialite?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Expérience"
                    id="experience"
                    min={0}
                    placeholder="Expérience"
                    {...register("experience")}
                    error={errors?.experience}
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="content"
                      className="form-label form-label-modal-custom"
                    >
                      Bio
                    </label>
                    <textarea
                      className="form-label custom-textarea"
                      rows={6}
                      {...register("bio")}
                    >
                      Ajouter
                    </textarea>
                    <FormError error={errors?.bio?.message} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    <BtnSubmit
                      label={user ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierInfosPersosPraticien;
