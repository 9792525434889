import React from "react"
import { NavLink } from "react-router-dom"
import Logo from "../../assets/appImages/logo.png"
import { BtnSubmit } from "../common/Button"
import ErrorMessage from "../common/ErrorMessage"
import UseForgetPasswordForm from "./useAuthForm/useForgotPassword"

function ForgotPassword() {
  const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm()
  return (
    <div className="auth-component">
      <div className="empty-header-login fixed-top">
        <div className="content-logo-empty-header-login">
          <NavLink to={"/"} className="no-link">
            <img
              src={Logo}
              alt="Logo"
              className="img-logo-empty-header-login"
              style={{ width: 217 }}
            />
          </NavLink>
        </div>
      </div>
      <div className="fixed-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-5 auth-col auth-left-side-col"></div>
              <div className="col-md-7 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="auth-form-container mt-5">
                    <h1 className="auth-form-title">Mot de passe oublié?</h1>
                    <h4 className="auth-form-message">
                      Veuillez entrer votre email Nous vous enverrons un lien
                      vers pour changer le mot de passe
                    </h4>
                    <div>
                      <form onSubmit={onSubmit}>
                        <div className="row auth-form-row">
                          <div className="col-md-12 auth-input-col">
                            <div className="form-group auth-form-group">
                              <label
                                htmlFor="email-or-username"
                                className="form-label form-label-login"
                              >
                                Addresse email
                              </label>
                              <input
                                type={`email`}
                                className="form-control auth-form-control"
                                id="email-or-username"
                                data-testid="emailId"
                                {...register("email")}
                              />
                              {errors?.email && (
                                <ErrorMessage
                                  message={errors?.email?.message}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-4">
                            <BtnSubmit isLoading={isLoading} label="Envoyer" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
