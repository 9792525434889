import React from 'react'
import { useStep } from 'react-hooks-helper'
import { useStepType } from '../../../utils/type'
import BreadCrumb from '../../shared/BreadCrumb'
import QuestionDiagnostique from './QuestionsSteps/QuestionDiagnostique'
import QuestionEvaluation from './QuestionsSteps/QuestionEvaluation'

const steps = [
    { id: "questions-diagnostics", Component: QuestionDiagnostique },
    { id: "questions-evaluations", Component: QuestionEvaluation },
  ]
  
  const tabs = [
    "Questions diagnostics",
    "Questions évaluations",
  ]

function Questions() {

    const {
        step,
        navigation: { go },
        index,
      }: useStepType = useStep({ initialStep: 0, steps })
      const { Component } = step

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Questions" title="Questions" />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Questions