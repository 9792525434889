import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { ApiBaseUrl } from "../../http"
import { QueryUrl } from "../../Utils"
import { Diagnostic, DiagnosticFormData } from "../autoDiagnostique/autoDiagnostique.type"
import { ContenusFormdata, IContenus } from "../contenus/contenus.type"
import { prepareHeaders } from "../user/user.api"
import { PaginationResults, TypeQuery } from "../user/user.type"

export const EvalBienEtreApi = createApi({
  reducerPath: "evalBienEtreApi",
  tagTypes: ["evalbienetre", "questionevaluations"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addEvalBienEtre: build.mutation<
      IContenus,
      { slug?: string; data: IContenus }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `evaluations/${slug}/` : `evaluations/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["evalbienetre"],
    }),
    addOrEditEvalBienEtre: build.mutation<
      Diagnostic,
      {
        slug?: string;
        data: DiagnosticFormData | FormData;
      }
    >({
      invalidatesTags: ["evalbienetre", "questionevaluations"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `update-evaluation/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `create-evaluation/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getEvalBienEtre: build.query<PaginationResults<IContenus>, TypeQuery>({
      query: () => `evaluations/`,
      providesTags: ["evalbienetre"],
    }),
    getQuestionsEvalBienEtre: build.query<
      PaginationResults<Diagnostic>,
      TypeQuery
    >({
      query: (query) => QueryUrl(`questionevaluations/`, query),
      providesTags: ["questionevaluations"],
    }),
    deleteEvalBienEtre: build.mutation<ContenusFormdata, string>({
      query: (slug) => ({
        url: `questionevaluations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["evalbienetre", "questionevaluations"],
    }),
  }),
});
export const {
  useAddEvalBienEtreMutation,
  useDeleteEvalBienEtreMutation,
  useGetEvalBienEtreQuery,
  useAddOrEditEvalBienEtreMutation,
  useGetQuestionsEvalBienEtreQuery
} = EvalBienEtreApi
