import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { IUser, PaginationResults, TypeQuery } from "../user/user.type";
import { EvenementFormData, IEvenement } from "./evenement.type";

export const EvenementApi = createApi({
  reducerPath: "evenementApi",
  tagTypes: [
    "evenement",
    "evenementPraticien",
    "evenementAdmin",
    "evenementEntreprise",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addEvenement: build.mutation<
      IEvenement,
      { slug?: string; data: EvenementFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `events/${slug}/` : `events/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: [
        "evenement",
        "evenementPraticien",
        "evenementAdmin",
        "evenementEntreprise",
      ],
    }),
    getEvenements: build.query<IEvenement[], TypeQuery>({
      query: (query) => QueryUrl(`events/`, query),
      providesTags: ["evenement"],
    }),
    getEvenementsPraticien: build.query<IEvenement[], TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`praticiens/${slug}/events/`, query),
      providesTags: ["evenementPraticien"],
    }),
    getEvenementsEntreprise: build.query<IUser[], TypeQuery>({
      query: (query) => QueryUrl(`events_entreprises/`, query),
      providesTags: ["evenement"],
    }),
    getEvenementsByPraticienEntreprise: build.query<IUser[], TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`eventbypraticien_entreprises/${slug}/`, query),
      providesTags: ["evenementEntreprise"],
    }),
    deleteEvenement: build.mutation<EvenementFormData, string>({
      query: (slug) => ({
        url: `events/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "evenement",
        "evenementEntreprise",
        "evenementAdmin",
        "evenementPraticien",
      ],
    }),
  }),
});

  export const {
    useAddEvenementMutation,
    useDeleteEvenementMutation,
    useGetEvenementsQuery,
    useGetEvenementsPraticienQuery,
    useGetEvenementsEntrepriseQuery,
    useGetEvenementsByPraticienEntrepriseQuery
  } = EvenementApi

