import React from "react";
import { CompteRendu } from "../../../../../../utils/api/compteRendu/compteRendu.type";
import { BtnAnnulerModal, BtnSubmit } from "../../../../../common/Button";
import { Input } from "../../../../../common/Input";
import ReactQuill from "react-quill";
import UseCrudCompteRenduForm from "../requestForm/UseCrudCompteRenduForm";
import ErrorMessage from "../../../../../common/ErrorMessage";

function AddCompteRenduModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: CompteRendu;
}) {
  const {
    register,
    onSubmit,
    isLoading,
    errors,
    handleReset,
    description,
    handleChange,
    setValue
  } = UseCrudCompteRenduForm(modalId, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-backdrop="static"
      tabIndex={-1}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              {item ? "Modifier" : "Ajouter"} un compte rendu
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handleReset()}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="date"
                    label="Date"
                    id="question"
                    placeholder="Sélectionner une date"
                    {...register("date")}
                    error={errors?.date?.message}
                  />
                </div>

                <div className="col-md-12">
                  <div className="mb-4">
                    <Input
                      type="file"
                      label="Joindre un fichier (word ou pdf)"
                      id="file"
                      accept="application/msword,application/pdf"
                    //   {...register("fichier")}
                      onChange={(e) => {
                        let file = e?.target?.files && e?.target?.files[0];
                        if (file) {
                            setValue('fichier',file)
                        }
                      }}
                      style={{ padding: "0.7rem 0.75rem" }}
                      error={errors?.fichier?.message || ""}
                    />
                  </div>
                </div>
                <div className="col-md-12 text-start mb-3">
                  <label
                    htmlFor="content"
                    className="form-label form-label-modal-custom"
                  >
                    Commentaire
                  </label>
                  <ReactQuill
                    className="editor-cgu custom-textarea"
                    value={description}
                    placeholder="Commentaire"
                    onChange={(value) => handleChange(value)}
                  />
                  {errors.commentaire && (
                    <ErrorMessage message={errors.commentaire?.message} />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal
                      label={"Annuler"}
                      onClick={() => handleReset()}
                    />
                    <BtnSubmit
                      label={`${item ? "Modifier" : "Ajouter"}`}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCompteRenduModal;
