import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEye } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import word from "../../../../../../assets/icons/word.png";
import pdf from "../../../../../../assets/icons/pdf.png";
import { useGetCompteRendusByRdvQuery } from "../../../../../../utils/api/compteRendu/compteRendu.api";
import { get_url_extension } from "../../../../../../utils/Utils";
import AddCompteRenduModal from "../modal/AddCompteRenduModal";
import ShowCompteRendu from "../modal/ShowCompteRendu";
import { DeleteCompteRendu } from "../requestForm/UseCrudCompteRenduForm";
import TableSkeleton from "../../../../../common/TableSkeleton";
import { AlertInfo } from "../../../../../common/Alert";
import { CustomPagination } from "../../../../../common/CustomPagination";

const DeleteBtn = ({ slug }: { slug: string }) => {
  const onDelete = DeleteCompteRendu(slug);

  return (
    <button
      className="btn btn-action-praticient-supp with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <FaTrashAlt />
    </button>
  );
};

function CompteRenduTable({ rdv }) {
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetCompteRendusByRdvQuery({
      slug: rdv?.slug,
      page,
      limit: perPage,
    });
  const actionFormatter = (cell: string, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <NavLink
            to={""}
            className="btn btn-action-praticient-voir with-tooltip no-link"
            data-tooltip-content="Voir"
            data-bs-toggle="modal"
            data-bs-target={`#showCompteRenduModal${cell}`}
          >
            <AiOutlineEye />
          </NavLink>
          <button
            className="btn btn-action-praticient-edit with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#editCompteRenduModal${cell}`}
          >
            <GrEdit />
          </button>
          <DeleteBtn slug={cell} />
        </div>
        <AddCompteRenduModal
          item={row}
          modalId={`editCompteRenduModal${cell}`}
        />
        <ShowCompteRendu item={row} modalId={`showCompteRenduModal${cell}`} />
      </>
    );
  };

  const documentFormatter = (cell: any, row: any) => {
    return (
      <div>
        <img
          src={get_url_extension(row?.fichier) !== "pdf" ? word : pdf}
          alt="user avatar"
          className="workcares-table-doc-img"
        />{" "}
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "NOM",
      formatter: (cell, row, rowIndex) => `Compte rendu ${rowIndex + 1}`,
    },
    {
      dataField: "date",
      text: "DATE",
      formatter: (cell) => moment(new Date(cell)).format("DD/MM/YYYY"),
    },
    {
      dataField: "document",
      text: "DOCUMENT",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => documentFormatter(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {!!isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default CompteRenduTable;
