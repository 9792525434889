import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { useGetObjectifByRhActionQuery } from "../../../../utils/api/objectif/objectif.api";
import {
  createMarkup,
  truncateString,
  useLocationState,
} from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";
import { AiFillEye } from "react-icons/ai";
import VoirObjectifModal from "../../OcbjectifsActions/Modals/VoirObjectifModal";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";

function ObjectisEntreprisetable() {
  const user = useLocationState<IUser>(undefined);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetObjectifByRhActionQuery({
      page: page,
      limit: perPage,
      slug: user?.slug,
    });
  // console.log("data ad", data);

  const contenuFormatter = (cell: any, row: any) => {
    return (
      <p
        className="text-description-service mb-0"
        dangerouslySetInnerHTML={createMarkup(
          truncateString(row?.contenu, 100)
        )}
      />
    );
  };

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Voir"
            data-bs-toggle="modal"
            data-bs-target={`#VoirObjectifModal${row?.slug}`}
          >
            <AiFillEye />
          </button>
        </div>

        <div
          className="modal fade"
          id={`VoirObjectifModal${row?.slug}`}
          aria-labelledby={`VoirObjectifModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <VoirObjectifModal
            modalId={`VoirObjectifModal${row?.slug}`}
            objectif={row}
          />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "titre",
      text: "OBJECTIFS",
    },
    {
      dataField: "contenu",
      text: "Contenu",
      formatter: (cell: any, row: any) => contenuFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Action",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ObjectisEntreprisetable;
