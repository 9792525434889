import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { AiFillEye } from "react-icons/ai"
import { MdModeEditOutline } from "react-icons/md"
import { useAppSelector } from "../../../../../redux/hooks"
import { useGetObjectifByRhActionQuery } from "../../../../../utils/api/objectif/objectif.api"
import { createMarkup, truncateString } from "../../../../../utils/Utils"
import VoirObjectifModal from "../../../../admin/OcbjectifsActions/Modals/VoirObjectifModal"
import { AlertInfo } from "../../../../common/Alert"
import { CustomPagination } from "../../../../common/CustomPagination"
import TableSkeleton from "../../../../common/TableSkeleton"
import AddObjectifAction from "../ObjectifActionModal/ObjectifsActionsModal"
import { DeleteObject } from "../useForm/UseCrudObjectifAction"

function ObjectifsActionsRhTables() {
  const { user } = useAppSelector((s) => s?.user)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { data = { results: [], count: 0 }, isLoading } =
  useGetObjectifByRhActionQuery({
      slug: user?.slug,
      page: page,
      limit: perPage,
    })

    const contenuFormatter = (cell: any, row: any) => {
      return(
        <p
          className="text-description-service mb-0"
          dangerouslySetInnerHTML={createMarkup(
            truncateString(row?.contenu, 70)
          )}
        />
      )
    }

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-admin with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#AddObjectifAction${row?.slug}`}
          >
            <MdModeEditOutline />
          </button>
          <button
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Voir"
            data-bs-toggle="modal"
            data-bs-target={`#VoirObjectifModal${row?.slug}`}
          >
            <AiFillEye />
          </button>
          <DeleteObject item={row} />
        </div>
        <div
          className="modal fade"
          id={`AddObjectifAction${row?.slug}`}
          aria-labelledby={`AddObjectifAction${row?.slug}Label`}
          aria-hidden="true"
        >
          <AddObjectifAction
            modalId={`AddObjectifAction${row?.slug}`}
            user={row}
          />
        </div>
        <div
          className="modal fade"
          id={`VoirObjectifModal${row?.slug}`}
          aria-labelledby={`VoirObjectifModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <VoirObjectifModal
            modalId={`VoirObjectifModal${row?.slug}`}
            objectif={row}
          />
        </div>
      </>
    )
  }

  const columns = [
    {
      dataField: "titre",
      text: "Objectifs",
    },
    {
      dataField: "contenu",
      text: "Actions",
      formatter: (cell: any, row: any) => contenuFormatter(cell, row),
    },
    {
      dataField: "action",
      text: "",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ]

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.filter(
              (user) => user?.slug
            )}
         
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage)
                setPage(page)
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  )
}

export default ObjectifsActionsRhTables
