import React from "react";
import { Specialite } from "../../../../utils/api/specialite/specialite.type";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input } from "../../../common/Input";
import useCrudThematique from "../useForm/UseCrudThematique";
import { Thematique } from "../../../../utils/api/thematique/thematique.type";

function AddOrEditThematiqueModal({
  modalId,
  contenu,
}: {
  modalId: string;
  contenu?: Thematique;
}) {
  const { onSubmit, errors, register, isLoading, reset } =
    useCrudThematique(modalId, contenu);
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header px-2 py-3">
          <h5 className="modal-title" >
            {!contenu ? "Ajouter" :  "Modifier"} une thématique
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal
              label=""
              onClick={() => {
                if (!contenu) {
                  reset();
                }
              }}
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  {!!contenu ?
                    <Input
                      type="text"
                      label="Libellé thématique"
                      id="libelle"
                      required
                      placeholder="theme"
                      {...register("libelle")}
                      error={errors?.libelle}
                    />
                    :
                    <Input
                      type="text"
                      label="Libellé thématique"
                      id="libelle"
                      required
                      placeholder="theme"
                      {...register("libelle")}
                      error={errors?.libelle}
                    />
                  }
                  
                </div> 

                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal
                      label={"Annuler"}
                      onClick={() => {
                        if (!contenu) {
                          reset();
                        }
                      }}
                    />
                    <BtnSubmit
                      label={contenu ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditThematiqueModal;
