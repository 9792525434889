import React from "react";
import { Outlet } from "react-router-dom";
import "./AdminLayout.css";
import { CgMenuGridO } from "react-icons/cg";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
const AdminLayout = () => {
  return (
    <div className="container-fluid body-background-dashboard">
    <div className="row">
      <input type="checkbox" name="nav" id="nav_button"></input>
      <label htmlFor="nav_button" className="on no-view-mobile">
        <CgMenuGridO className="icon-side-menu" />
      </label>
      <div className="nav-container custom-nav-container no-view-mobile">
        <SidebarAdmin />
      </div>
      <div className="container-header">
        <HeaderAdmin />
      </div>
      <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header h-90vh position-relative page-content-base-container">
        <div className="admin-page-container">
          <Outlet />
        </div>
      </div>
    </div>
  </div>
  );
};

export default AdminLayout;
