import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function PerformanceGraphRh({percentage_PC , percentage_NPC}:any) {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
      };
    
      const data = {
        labels: ["", ""],
        datasets: [
          {
            label: "Performance",
            data: [percentage_PC , percentage_NPC],
            backgroundColor: ["rgb(33, 89, 245)", "rgb(183, 203, 251)"],
            borderColor: ["#fff", "#fff"],
            borderWidth: 2,
          },
        ],
      };
    
      return (
        <div className="chart-item-container">
          <Doughnut options={options} data={data} />
        </div>
      );
}

export default PerformanceGraphRh