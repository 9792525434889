import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import TableSkeleton from '../../../../common/TableSkeleton';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AlertInfo } from '../../../../common/Alert';
import { CustomPagination } from '../../../../common/CustomPagination';
import { useGetQuestionsDiagnosticQuery } from '../../../../../utils/api/questions/question.api';

function QuestionsDiagnostiqueRhTable() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { data = [], isLoading } = useGetQuestionsDiagnosticQuery({});
  
    // console.log(
    //   "data diag",
    //   data?.filter((data) => data?.type === "text_field")
    // );
  
    const questionFormatter = (cell: any, row: any) => {
      return (
        <p className="text-question-custom mb-0">
          {" "}
          <span>{row?.contenu}</span>
        </p>
      );
    };
  
    const actionFormatter = (cell: any, row: any) => {
      return (
        <>
          <div className="table-actions-btn-container-action gap-2 d-flex">
            <NavLink
              to={`/rh/question-diagnostique/${row?.slug}`}
              state={row}
              className="btn btn-action-admin with-tooltip no-link"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
        </>
      );
    };
  
    const columns = [
      {
        dataField: "id",
        text: "Id",
        style: { textAlign: "left" },
        headerStyle: () => {
          return { width: "50px", whiteSpace: "normal" };
        },
      },
      {
        dataField: "contenu",
        text: "QUESTION",
        style: { textAlign: "left" },
        formatter: (cell: any, row: any) => questionFormatter(cell, row),
      },
      {
        dataField: "slug",
        text: "Verbatims",
        style: { textAlign: "center" },
        formatter: (cell: any, row: any) => actionFormatter(cell, row),
        headerStyle: () => {
          return { width: "200px", whiteSpace: "normal" };
        },
      },
    ];
  
    return (
      <>
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.filter((data) => data?.type === "text_field")}
              columns={columns}
              bordered={true}
              condensed={false}
              responsive={true}
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvéé" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={
                  data?.filter((data) => data?.type === "text_field")?.length
                }
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </div>
          </>
        )}
      </>
    );
}

export default QuestionsDiagnostiqueRhTable