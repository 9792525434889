import moment from 'moment'
import React, { useState } from 'react'
import { getAvatar, getName, truncateString } from '../../../utils/Utils'
import { AlertInfo } from '../../common/Alert'
import BreadCrumb from '../../shared/BreadCrumb'
import Skeleton from "react-loading-skeleton";
import { IContact } from '../../../utils/api/contact/contact.type'
import { useGetContactMessagesQuery } from '../../../utils/api/contact/contact.api'
import { DeleteContact } from './useForm/useContactForm'
import './ContactsAdmin.css'
import RepondreMessageModal from './Modals/RepondreMessageModal'
import Pagination from '../../common/Pagination'

function ContactsAdmin() {
    const [page, setPage] = useState(1);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetContactMessagesQuery({
      page: page,
      limit: 10,
    });
    console.log("message", data?.results)
  return (
    <div className="workcare-dash-admin-page-content-container">
        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((message) => <MessageItem message={message} />)
            ?.sort((a: any, b: any) => moment(b.created_at, "MM-DD-YYYY Z").valueOf() - moment(a.created_at, "MM-DD-YYYY Z").valueOf())
          ) : (
            <AlertInfo message="Pas de contacts" />
          ))}
          {!!isLoading && <MessageContactItemSkeleton />}
            <div className="flex-r custom-pagination-container">
            <Pagination
                page={page}
                total={data?.count || 0}
                perPage={10}
                onPageChange={(page: number) => setPage(page)}
            />
            </div>
    </div>
  )
}

export default ContactsAdmin

export function MessageItem({
    message,
    modalId,
  }: {
    message: IContact;
    modalId?: string;
  }) {
    modalId = modalId || `repondreMessageModal${message.id}`;
    const buttonProps = {
      "data-bs-toggle": "modal",
      "data-bs-target": `#${modalId}`,
    };
  
    return (
      <>
        <div className="message-contact-custom-position">
          <div
            className="message-item px-3"
            key={message.id}
            role="button"
            {...buttonProps}
          >
            <div className="avatar-messages-container pb-3">
              <img
                src={message?.user ? getAvatar(message?.user?.avatar) : `https://ui-avatars.com/api/?name=${(message?.user || message?.name) ? getName(message?.user ? message?.user : message?.name) : "NON RENSEIGNÉ"}`}
                alt="avatar"
                className="custom-avatar-message"
              />
            </div>
            <div className="messages-text w-100">
              <div className="message-infos flex-csb">
                <h3 className="author-message-name">{(message?.user || message?.name) ? getName(message?.user ? message?.user : message?.name) : "NON RENSEIGNÉ"}</h3>
                <h6 className="hour-publish-message">
                  {moment(message?.created_at).fromNow()}
                </h6>
              </div>
              <div className="message-content">
                <p>{truncateString(message?.message, 110)}</p>
              </div>
            </div>
          </div>
          <DeleteContact item={message} />
        </div>
        <RepondreMessageModal message={message} modalId={modalId} />
      </>
    );
  }

  export function MessageContactItemSkeleton() {
    return (
      <>
        {[...Array(10)]?.map((el, i) => (
          <div className="message-contact-custom-position d-flex" key={i}>
            <div className="message-item px-3 w-100">
              <div className="avatar-messages-container pb-3">
                <Skeleton className="custom-avatar-message" />
              </div>
              <div className="messages-text w-100">
                <div className="message-infos flex-csb">
                  <Skeleton className="author-message-name" width={`25%`} />
                  <Skeleton className="hour-publish-message" width={`15%`} />
                </div>
                <div className="message-content">
                  <Skeleton width={`90%`} />
                </div>
              </div>
            </div>
            <Skeleton
              className="with-tooltip btn-supprimer-contact"
              width={30}
              height={30}
            />
          </div>
        ))}
      </>
    );
  }