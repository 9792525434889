import React, { useEffect, useState } from "react";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
  BtnSuprimer,
} from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import { Input } from "../../../common/Input";
import { DeleteEvenement } from "../useForm/UseCrudActivite";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import UseCrudAtelelier from "../useForm/UseCrudAtelelier";

function AddOrUpdateAteliers({
  modalId,
  evenement,
}: {
  modalId: string;
  evenement?: any;
}) {
  const {
    register,
    errors,
    onSubmit,
    handleInputChange,
    isLoading,
    reset,
    options,
    control,
    Controller,
    onChangeEntreprise,
    entreprise,
    setDescription,
    setEntreprise,
  } = UseCrudAtelelier(modalId, evenement);

  const animatedComponents = makeAnimated();

  const [showLieuxOuNon, setShowLieuxOuNon] = useState(false);
  const [showLienOuNon, setShowLienOuNon] = useState(true);

  const toggleShowLieuxOuNon = () => {
    setShowLieuxOuNon((oldState) => {
      return !oldState;
    });
    setShowLienOuNon((oldState) => {
      return !oldState;
    });
  };

  useEffect(() => {
    if (evenement?.id) {
      if (evenement?.en_ligne === true && evenement?.lien !== "") {
        setShowLienOuNon(true);
        setShowLieuxOuNon(false);
      } else {
        setShowLienOuNon(false);
        setShowLieuxOuNon(true);
      }
    }
  }, [evenement]);

  const handleReset = () => {
    if (!evenement) {
      reset();
      setDescription("");
      setEntreprise(undefined);
    }
  };

  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {evenement ? "Modifier l'atelier" : "Ajouter un atelier"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" onClick={handleReset} />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="date"
                    label="Date"
                    id="date"
                    placeholder="Date"
                    {...register("date")}
                  />
                  {errors.date && (
                    <ErrorMessage message={errors.date?.message} />
                  )}
                </div>
                <div className="col-md-6">
                  <Input
                    type="time"
                    label="Heure"
                    id="heure"
                    placeholder="Heure"
                    {...register("heure")}
                  />
                  {errors.heure && (
                    <ErrorMessage message={errors.heure?.message} />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        checked={showLienOuNon}
                        {...register("en_ligne")}
                        onChange={toggleShowLieuxOuNon}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        En ligne
                      </label>
                    </div>
                  </div>
                </div>

                {showLienOuNon && (
                  <div className="col-md-12">
                    <Input
                      type="text"
                      label="Lien"
                      id="lien"
                      placeholder="Lien"
                      {...register("lien")}
                    />
                    {errors.lien && (
                      <ErrorMessage message={errors.lien?.message} />
                    )}
                  </div>
                )}

                {showLieuxOuNon && (
                  <div className="col-md-12">
                    <Input
                      type="text"
                      label="Lieu"
                      id="lieu"
                      placeholder="Lieu"
                      {...register("lieu")}
                    />
                    {errors.lieu && (
                      <ErrorMessage message={errors.lieu?.message} />
                    )}
                  </div>
                )}

                <div className="col-md-12 col-form-add-student mb-4">
                  <div className="content-col-form-add-student">
                    <label className="form-label form-label-add-student">
                      {" "}
                      Sélectionner une entreprise {options?.length}
                    </label>
                    <Controller
                      name="entreprise"
                      control={control}
                      
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            onInputChange={handleInputChange}
                            components={animatedComponents}
                            filterOption={() => true}
                            isMulti={false}
                            options={options}
                            classNamePrefix="react-select-custom"
                            onChange={onChangeEntreprise}
                            value={entreprise}
                          />
                        );
                      }}
                    />
                    {errors.entreprise && (
                      <ErrorMessage message={errors.entreprise?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Nom de l’animateur"
                    id="nom"
                    placeholder="Ajouter le nom de l’animateur"
                    {...register("animateur")}
                  />
                  {errors.animateur && (
                    <ErrorMessage message={errors.animateur?.message} />
                  )}
                </div>
                <div className="col-md-12 text-start mb-3">
                  <label
                    htmlFor="content"
                    className="form-label form-label-modal-custom"
                  >
                    Description
                  </label>
                  <textarea
                    className="form-control form-control-modal-custom"
                    id="description"
                    placeholder="Description"
                    {...register("description")}
                    rows={3}
                  ></textarea>
                  {errors.contenu && (
                    <ErrorMessage message={errors.contenu?.message} />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    {evenement ? (
                      <DeleteEvenementBtn
                        slug={evenement?.slug}
                        modalid={modalId}
                      />
                    ) : (
                      <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    )}
                    <BtnSubmit
                      label={evenement ? "Modifier" : "Créer"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateAteliers;

function DeleteEvenementBtn({
  slug,
  modalid,
}: {
  slug: string;
  modalid: string;
}) {
  const onDelete = DeleteEvenement(slug, modalid);

  return (
    <BtnSuprimer label={"Supprimer"} isLoading={false} onClick={onDelete} />
  );
}
