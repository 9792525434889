import React, { useState } from 'react'
import { createMarkup, useLocationState } from '../../../../utils/Utils';
import { useGetResponseQuestionEvaluationQuery } from '../../../../utils/api/questions/question.api';
import { useAppSelector } from '../../../../redux/hooks';
import { QuestionDiagnostic } from '../../../../utils/api/questions/question.type';
// import BreadCrumb from '../../../shared/BreadCrumb';
import Pagination from '../../../common/Pagination';
import { AlertInfo } from '../../../common/Alert';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

function DetailsQuestionsEvalRh() {
  const item = useLocationState<QuestionDiagnostic>(undefined);
  const user = useAppSelector((s) => s?.user?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetResponseQuestionEvaluationQuery({
      slug: item?.slug,
      slugRh: user?.slug,
      limit: perPage,
      page,
    });

  return (
    <>
      <div className="dashbord-visiteur-component">
        <div className="workcare-dash-admin-titre-btn-container mb-4">
          <div className="workcare-dash-admin-titre-container">
            {/* <BreadCrumb pageName="Réponses" title="Réponses" /> */}
            <div className="breadcrumb-component">
              <section className="breadcrumb-container container-fluid">
                <div className="breadcrumb-page-title-content-body flex-sb-mobile">
                  <div className="breadcrumb-page-container-title-content-body gap-2">
                    <h1 className="workcare-dash-admin-titre">Réponses</h1>
                    <div className="breadcrumb-row gap-2 d-flex align-items-center">
                      <div className="base-home-container d-flex align-items-center">
                        <div className="home-label-container">
                          <NavLink
                            to="/rh/questions"
                            className="home-breadcrumb-link"
                          >
                            {" "}
                            <span className="home-label">Questions</span>
                          </NavLink>
                        </div>
                        <div className="arrow-icon-container">
                          <MdKeyboardArrowRight className="arrow-icon" />
                        </div>
                      </div>
                      <div className="second-level-breadcrumb-container">
                        <span className="breadcrumb-second-level-label">
                          {" "}
                          Réponses{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="workcare-dash-admin-page-content-container">
          <div className="kanimmo-tabs-locataire-container">
            <div className="tabs-component-container">
              <div className="mb-5 reponse-titre-custom">{item?.contenu}</div>
              <p
                className="text-question-custom mb-5"
                dangerouslySetInnerHTML={createMarkup(item?.legende)}
              />
              {!isLoading &&
                (data?.results?.length && data?.results?.length > 0 ? (
                  data?.results?.map((item) =>
                    item?.response_free ? (
                      <div className="mb-3" key={item?.id}>
                        <div className="reponse-item-custom">
                          {item?.response_free}
                        </div>
                        <hr />
                      </div>
                    ) : (
                      item?.response?.map((itm) => (
                        <div className="mb-3" key={itm?.id}>
                          <div className="reponse-item-custom">
                            {itm?.contenu}
                          </div>
                          <hr />
                        </div>
                      ))
                    )
                  )
                ) : (
                  <AlertInfo message="Aucune element trouvé" />
                ))}

              <div className="flex-r">
                <Pagination
                  page={page}
                  total={data?.count}
                  perPage={perPage}
                  onPageChange={(page: number) => setPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsQuestionsEvalRh