import React from "react";
import { StringSchema } from "yup";
import { CompteRendu } from "../../../../../../utils/api/compteRendu/compteRendu.type";
import {
  createMarkup,
  getFileUrl,
  get_url_extension,
} from "../../../../../../utils/Utils";

function ShowCompteRendu({
  modalId,
  item,
}: {
  item: CompteRendu;
  modalId: string;
}) {
  return (
    <div
      className="modal fade "
      id={modalId}
      tabIndex={-1}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Voir le compte rendu</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="pb-5 pt-3 text-start " style={{ height: "800px" }}>
              <div>
                <p
                  className="text-paragraph"
                  dangerouslySetInnerHTML={createMarkup(
                    item?.commentaire || ""
                  )}
                />
              </div>
              {getFileUrl(item?.fichier) &&
                (get_url_extension(item?.fichier) === "pdf" ? (
                  <iframe
                    src={getFileUrl(item?.fichier)}
                    frameBorder={"0"}
                    width={`100%`}
                    height={`100%`}
                  ></iframe>
                ) : (
                  <iframe
                    src={
                      "https://view.officeapps.live.com/op/embed.aspx?src=" +
                      getFileUrl(item?.fichier)
                    }
                    frameBorder="0"
                    width={`100%`}
                    height={`100%`}
                  ></iframe>
                ))}{" "}
              {/* <object data={baseUrl + document?.fichier} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowCompteRendu;
