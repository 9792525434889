import React from "react"
import { NavLink } from "react-router-dom"
import Logo from "../../assets/appImages/logo.png"
import { BtnSubmit } from "../common/Button"
import ErrorMessage from "../common/ErrorMessage"
import { UseResetPasswordForm } from "./useAuthForm/useResetPassword"

const ResetPassword = () => {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    PasswordInput,
  } = UseResetPasswordForm()
  return (
    <div className="auth-component">
      <div className="empty-header-login fixed-top">
        <div className="content-logo-empty-header-login">
          <NavLink to={"/"} className="no-link">
            <img
              src={Logo}
              alt="Logo"
              className="img-logo-empty-header-login"
              style={{ width: 217 }}
            />
          </NavLink>
        </div>
      </div>
      <div className="fixed-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-5 auth-col auth-left-side-col"></div>
              <div className="col-md-7 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="auth-form-container">
                    <div className="mb-4">
                      <h1 className="auth-form-title">
                        Changer le mot de passe
                      </h1>
                      <h4 className="auth-form-message">
                        Crée un nouveau mot de passe
                      </h4>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="row auth-form-row">
                        <div className="col-md-12 auth-input-col mb-3">
                          <div className="auth-form-group">
                            <label
                              className="form-label form-label-login"
                              htmlFor="code"
                            >
                              Code reçu
                            </label>
                            <input
                              type={`text`}
                              className="form-control auth-form-control"
                              id="code"
                              placeholder="Entrez le code reçu par email"
                              {...register("code")}
                            />
                            {errors?.code && (
                              <ErrorMessage message={errors?.code?.message} />
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 auth-input-col mb-3">
                          <div className="auth-form-group">
                            <label
                              className="form-label form-label-login"
                              htmlFor="password"
                            >
                              Mot de passe
                            </label>
                            <input
                              type={`password`}
                              className="form-control auth-form-control"
                              id="password"
                              placeholder="Entrez un nouveau mot de passe"
                              onChange={passwordHandleChange}
                            />
                            {errors?.new_password && (
                              <ErrorMessage
                                message={errors?.new_password?.message}
                              />
                            )}
                          </div>
                        </div>
                        {PasswordInput && (
                          <div className="col-md-12">
                            <div className="input-group mb-2 password-validation-terms-row">
                              <div className="password-validation-terms-container">
                                <h4 className="password-validation-terms-title">
                                  Votre mot de passe doit contenir :{" "}
                                </h4>
                                <ul className="password-validation-terms-ul p-l-14">
                                  <li
                                    className={
                                      "password-rule-item " +
                                      (haveMinCharacter && "text-success")
                                    }
                                  >
                                    Au minimum 8 caractères
                                  </li>
                                  <li
                                    className={
                                      "password-rule-item " +
                                      (haveMinUppercase && "text-success")
                                    }
                                  >
                                    Au minimum 1 caractère en majuscule
                                  </li>
                                  <li
                                    className={
                                      "password-rule-item " +
                                      (haveMinLowercase && "text-success")
                                    }
                                  >
                                    Au minimum 1 caractère en minuscule
                                  </li>
                                  <li
                                    className={
                                      "password-rule-item " +
                                      (haveMinNumber && "text-success")
                                    }
                                  >
                                    Au minimum 1 nombre
                                  </li>
                                  <li
                                    className={
                                      "password-rule-item " +
                                      (haveMinSpecialCharacter &&
                                        "text-success")
                                    }
                                  >
                                    Au minimum 1 caractère spéciale
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12 auth-input-col">
                          <div className="auth-form-group">
                            <label
                              htmlFor="confirm-new-password"
                              aria-labelledby="confirm-password"
                              className="form-label form-label-login"
                            >
                              Confirmez le mot de passe
                            </label>
                            <input
                              type="password"
                              className="form-control auth-form-control"
                              id="confirm-new-password"
                              placeholder="Confirmez le mot de passe"
                              {...register("new_password_confirm")}
                            />
                            {errors?.new_password_confirm && (
                              <ErrorMessage
                                message={errors?.new_password_confirm?.message}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 offset-md-4 auth-submit-btn-container mt-4">
                          <BtnSubmit
                            label="réinitialiser"
                            isLoading={isLoading}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
