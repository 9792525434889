import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEye } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import word from "../../../../../../assets/icons/word.png";
import { GrEdit } from "react-icons/gr";
import { CustomPagination } from "../../../../../common/CustomPagination";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetRdvByPraticienQuery } from "../../../../../../utils/api/rdv/rdv.api";
import { IUser } from "../../../../../../utils/api/user/user.type";
import { AlertInfo } from "../../../../../common/Alert";
import TableSkeleton from "../../../../../common/TableSkeleton";

function RecentPatientTable({ query }: { query?: string }) {
  const { user } = useAppSelector((s) => s.user);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetRdvByPraticienQuery({
      limit: perPage,
      page,
      slug: user?.slug,
      q: query || "",
    });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={`/praticien/patient/${row?.slug}`}
          className="btn btn-action-praticient-voir with-tooltip no-link"
          data-tooltip-content="Voir"
          state={row}
        >
          <AiOutlineEye />
        </NavLink>
        {/* <NavLink
          to={"/"}
          className="btn btn-action-praticient-edit with-tooltip"
          data-tooltip-content="Modifier"
        >
          <GrEdit />
        </NavLink> */}
        {/* <button
          className="btn btn-action-praticient-supp with-tooltip"
          data-tooltip-content="Supprimer"
        >
          <FaTrashAlt />
        </button> */}
      </div>
    );
  };

  const documentFormatter = (cell: any, row: any) => {
    return (
      <div>
        <img src={word} alt="user avatar" className="workcares-table-doc-img" />
        {cell}
      </div>
    );
  };

  const columns = [
    {
      dataField: "salarie",
      text: "NOM",
      formatter: (cell: IUser) => cell?.prenom + " " + cell?.nom,
    },
    {
      dataField: "rh",
      text: "ENTREPRISE",
      formatter: (cell, row) =>
        row?.salarie?.rh?.nom + "" + row?.salarie?.rh?.prenom,
    },
    // {
    //   dataField: "praticien",
    //   text: "PRATICIEN",
    //   headerStyle: () => {
    //     return { width: "120px", whiteSpace: "normal" }
    //   },
    //   formatter: (cell, row) =>
    //     row?.praticien?.nom + " " + row?.praticien?.prenom,
    // },
    // {
    //   dataField: "document",
    //   text: "DOCUMENT",
    //   style: { textAlign: "center" },
    //   formatter: (cell: any, row: any) => documentFormatter(cell, row),
    //   headerStyle: () => {
    //     return { textAlign: "center" }
    //   },
    // },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default RecentPatientTable;
