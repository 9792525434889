import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { useGetObjectifActionQuery } from "../../../../utils/api/objectif/objectif.api";
import { createMarkup, truncateString } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import AddObjectifAction from "../../../TableauDeBord/RH/ObjectifsActions/ObjectifActionModal/ObjectifsActionsModal";
import { DeleteObject } from "../../../TableauDeBord/RH/ObjectifsActions/useForm/UseCrudObjectifAction";
import VoirObjectifModal from "../Modals/VoirObjectifModal";
import { ObjectifType } from "../../../../utils/api/objectif/objectif.types";
import { IUser } from "../../../../utils/api/user/user.type";

function ObjectifsActionsTables() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count:0 }, isLoading } = useGetObjectifActionQuery({
    page: page,
    limit: perPage,
  });
  console.log("data ad", data);

  const contenuFormatter = (cell: any, row: any) => {
    return (
      <p
        className="text-description-service mb-0"
        dangerouslySetInnerHTML={createMarkup(truncateString(row?.contenu, 70))}
      />
    );
  };

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-admin with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#AddObjectifAction${row?.slug}`}
          >
            <MdModeEditOutline />
          </button>
          <button
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Voir"
            data-bs-toggle="modal"
            data-bs-target={`#VoirObjectifModal${row?.slug}`}
          >
            <AiFillEye />
          </button>
          <DeleteObject item={row} />
        </div>
        <div
          className="modal fade"
          id={`AddObjectifAction${row?.slug}`}
          aria-labelledby={`AddObjectifAction${row?.slug}Label`}
          aria-hidden="true"
        >
          <AddObjectifAction
            modalId={`AddObjectifAction${row?.slug}`}
            user={row}
          />
        </div>
        <div
          className="modal fade"
          id={`VoirObjectifModal${row?.slug}`}
          aria-labelledby={`VoirObjectifModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <VoirObjectifModal
            modalId={`VoirObjectifModal${row?.slug}`}
            objectif={row}
          />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "titre",
      text: "OBJECTIFS",
    },
    {
      dataField: "rh",
      text: "ENTREPRISE",
       formatter: (cell: IUser) => cell?.nom_entreprise || cell?.prenom + " " + cell?.nom
    },
    {
      dataField: "contenu",
      text: "ACTIONS",
      formatter: (cell: string, row: ObjectifType) =>
        contenuFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Boutons d'action",
      style: { textAlign: "center" },
      formatter: (cell: string, row: ObjectifType) =>
        actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ObjectifsActionsTables;
