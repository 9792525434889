import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RxCross2 } from "react-icons/rx";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetRdvByPraticienQuery } from "../../../../../../utils/api/rdv/rdv.api";
import { getAvatar, getName } from "../../../../../../utils/Utils";
import { IRdv } from "../../../../../../utils/api/rdv/rdv.type";
import { AlertInfo } from "../../../../../common/Alert";
import moment from "moment";
import TableSkeleton from "../../../../../common/TableSkeleton";
import { GoCheck } from "react-icons/go";
import { AuthState, IUser } from "../../../../../../utils/api/user/user.type";
import { AppStorage } from "../../../../../../utils/storage";
import { ApiBaseUrl } from "../../../../../../utils/http";
import Swal from "sweetalert2";
import { Color } from "../../../../../../utils/theme";
import { CustomPagination } from "../../../../../common/CustomPagination";
import { AiOutlineEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";

function RdvTables({frequence,date}:{frequence:string; date: Date | string}) {
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetRdvByPraticienQuery({
      slug: user?.slug,
      limit: perPage,
      page,
      date: moment(date).format("DD-MM-YYYY"),
      frequence,
    });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={`/praticien/patient/${row?.slug}`}
          className="btn btn-action-praticient-voir with-tooltip no-link"
          data-tooltip-content="Voir"
          state={row}
        >
          <AiOutlineEye />
        </NavLink>
      </div>
    );
  };

  const nameAvatarFormatter = (cell: any, row: IRdv) => {
    return (
      <div>
        <img
          src={getAvatar(row?.salarie?.avatar)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {getName(row?.salarie)}
      </div>
    );
  };
  const statutFormatter = (cell: any, row: IRdv) => {
    return (
      <button
        className={`btn message-btn ${
          row.statut === "nouveau"
            ? "bg-status"
            : row.statut === "accepte"
            ? "bg-success"
            : "bg-danger"
        }`}
      >
        {row.statut === "nouveau"
          ? "Nouveau"
          : row.statut === "accepte"
          ? "Accepté"
          : "Refusé"}
      </button>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "NOM",
      formatter: (cell: any, row: any) => nameAvatarFormatter(cell, row),
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" }
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell: any, row: any) => <p className="email-rdv"> {row?.salarie?.email} </p>,
      headerStyle: () => {
        return { width: "300px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: any, row: any) => moment(cell).format("DD/MM/YYYY"),
      // headerStyle: () => {
      //   return { width: "65px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "heure",
      text: "Heure",
      headerStyle: () => {
        return { width: "70px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "salarie",
      text: "Entreprise",
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" }
      },
      formatter: (cell: any, row: any) =>
        cell?.rh?.nom_entreprise || "NON RENSEIGNÉ",
    },
    {
      dataField: "slug",
      text: "Action",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucun rendez-vous disponible." />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default RdvTables;

async function handleRdvAction(rdv: any, action: string) {
  const token = AppStorage.getItem<AuthState>("user")?.token;

  try {
    const response = await fetch(
      `${ApiBaseUrl}/api/rdv/${rdv.slug}/${action}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: `Rdv ${action === "validation" ? "confirmé" : "refusé"}!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => window.location.reload());
    } else {
      Swal.fire({
        icon: "error",
        title: `Erreur de statut ${response.status}`,
        showConfirmButton: true,
        confirmButtonColor: Color.themeColor,
        timer: 5000,
      });
    }

    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error(error);
  }
}

function RdvActionBtn({ rdv, action }: { rdv: any; action: string }) {
  const handleClick = () => {
    handleRdvAction(rdv, action);
  };

  return (
    <button
      className={`btn ${
        action === "validation"
          ? "btn-action-praticient-valid"
          : "btn-action-praticient-supp"
      } with-tooltip`}
      data-tooltip-content={action === "validation" ? "Valider" : "Annuler"}
      onClick={handleClick}
    >
      {action === "validation" ? <GoCheck /> : <RxCross2 />}
    </button>
  );
}
