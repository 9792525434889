import React from "react";
import { AiFillFileText, AiOutlineMenuUnfold, AiTwotoneSetting } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { FaFileAlt, FaUserAlt, FaUsers } from "react-icons/fa";
import { IoLogOut, IoPersonSharp, IoStatsChartSharp } from "react-icons/io5";
import { GoPerson } from "react-icons/go";
import { MdAccountBox } from "react-icons/md";
import { onlogout } from "../../../../redux/slice/User.slice";
import { useAppDispatch } from "../../../../redux/hooks";

const SidebarMobile = () => {
  const location = useLocation()
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(onlogout());
    window.location.pathname = "/";
  };
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              {location?.pathname?.startsWith("/admin") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/dashboard"
                    >
                      <IoStatsChartSharp />
                      <span className="item-nav-menu drop-header">
                        Suivi évolution santé
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/agenda"
                    >
                      <BiCalendar />
                      <span className="item-nav-menu drop-header">
                        Mon agenda{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/utilisateurs"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Utilisateurs
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/praticiens"
                    >
                      <GoPerson />
                      <span className="item-nav-menu drop-header">
                        Praticiens
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/entreprises"
                    >
                      <MdAccountBox />
                      <span className="item-nav-menu drop-header">
                        Entreprises
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/objectifs-et-actions"
                    >
                      <FaFileAlt />
                      <span className="item-nav-menu drop-header">
                        Objectifs et actions
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/contenus"
                    >
                      <FaFileAlt />
                      <span className="item-nav-menu drop-header">
                        Contenus
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/mon-compte"
                    >
                      <IoPersonSharp />
                      <span className="item-nav-menu drop-header">
                        Mon compte
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/parametres"
                    >
                      <AiTwotoneSetting />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {location?.pathname?.startsWith("/praticien") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/praticien/dashboard"
                    >
                      <IoStatsChartSharp />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/praticien/mon-agenda"
                    >
                      <BiCalendar />
                      <span className="item-nav-menu drop-header">
                        Mon-agenda
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/praticien/prise-en-charge"
                    >
                      <AiFillFileText />
                      <span className="item-nav-menu drop-header">
                        Prise en charge
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/praticien/mon-profil"
                    >
                      <FaUserAlt />
                      <span className="item-nav-menu drop-header">
                        Mon-profil
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {location?.pathname?.startsWith("/rh") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/rh/dashboard"
                    >
                      <IoStatsChartSharp />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/rh/objectifs-et-actions"
                    >
                      <FaFileAlt />
                      <span className="item-nav-menu drop-header">
                        Objectifs et actions
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/rh/organisations"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Organisations
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="container-btn-logout ps-4">
              <button className="btn btn-logout custom-btn-logout-sidebar-mobile"
              onClick={handleLogout}>
                <IoLogOut />
                <span className="ps-2">Déconnexion</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
