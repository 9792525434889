import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  import { Bar } from "react-chartjs-2";
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

function ProgressGraphRh({donneesGraphWeek, donneesGraphMonth , donneesGraphYear}:any) {
    const options = {
        responsive: true,
      };
    
      const labels = donneesGraphYear ? donneesGraphYear.map((x)=> x.name) : [] ;
    
      const data = {
        labels,
        datasets: [
          {
            label: "Semaine",
            data: donneesGraphWeek ? donneesGraphWeek.map((x)=> x.value) : [],
            backgroundColor: "#F85470",
          },
          {
            label: "Mois",
            data: donneesGraphMonth ? donneesGraphMonth.map((x)=> x.value) : [],
            backgroundColor: "#3AD0FF",
          },
          {
            label: "Année",
            data: donneesGraphYear ? donneesGraphYear.map((x)=> x.value) : [],
            backgroundColor: "#D190F9",
          },
        ],
      };
    
      return (
        <div className="chart-item-container">
          <div className="row mb-3">
            <div className="col-md-6 mb-2">
              <h2 className="chart-item-admin-title-admin mb-0">
                Gss_Progression du mieux-être
              </h2>
            </div>
          </div>
          <Bar options={options} data={data} />
        </div>
      );
}

export default ProgressGraphRh