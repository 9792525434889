import React from 'react'
import QuestionsEvalRhTable from '../QuestionsRhTables/QuestionsEvalRhTable'

function QuestionsEvaluationRh() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <QuestionsEvalRhTable />
    </div>
  )
}

export default QuestionsEvaluationRh