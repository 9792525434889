import { IUser } from "../user/user.type"

export enum EvenType {
    activite = "activite",
    atelier = "atelier",
}
export enum ActiviteType {
    douce = "douce",
    dynamique = "dynamique",
}

export interface IEvenement {
    id: number
    slug: string | any	
    date: string	
    heure: string 	
    description: string	
    animateur: string	
    type: EvenType	
    type_activite: ActiviteType	
    lien: string	
    lieu: string	
    en_ligne: string	
    activite: string	
    entreprise: IUser	
    praticien: IUser
  }
  
  export type EvenementFormData = Partial<{
    id: number
    slug: string | any	
    date: string	
    heure: string 	
    description: string	
    animateur: string	
    type: EvenType	
    type_activite: ActiviteType		
    lien: string	
    lieu: string	
    en_ligne: string	
    activite: string	
    entreprise: IUser	
    praticien: IUser
  }>