import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { useGetContenusByThemeQuery } from "../../../../utils/api/contenus/contenus.api";
import {
  createMarkup,
  showModal,
  truncateString,
} from "../../../../utils/Utils";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import AddOrUpdateCitationModal from "../Modals/AddOrUpdateCitationModal";
import VoirCitationModal from "../Modals/VoirCitationModal";
import { DeleteContenu } from "../useForm/UseCrudContenus";
import { Thematique } from "../../../../utils/api/thematique/thematique.type";
import { useNavigate, useParams } from "react-router-dom";

function ListeContenusTable() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { slug } = useParams();
  const { data = { results: [], count: 0 }, isLoading } =
    useGetContenusByThemeQuery({ limit: perPage, page, slug });

  useEffect(() => {
    if (!slug) {
      navigate(-1);
    }
  }, [slug]);

  const contenuFormatter = (cell: any, row: any) => {
    return (
      <p
        className="text-description-service mb-0"
        dangerouslySetInnerHTML={createMarkup(truncateString(row?.contenu, 70))}
      />
    );
  };

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="btn btn-action-admin with-tooltip"
          data-tooltip-content="Supprimer"
          onClick={() => showModal(`AjouterContenuModal${row?.slug}`)}
        >
          <MdModeEditOutline />
        </button>
        <button
          className="btn btn-action-admin with-tooltip no-link"
          data-tooltip-content="Voir"
          data-bs-toggle="modal"
          data-bs-target={`#VoirContenuModal${row?.slug}`}
        >
          <AiFillEye />
        </button>
        <DeleteContenu item={row} />
        <div
          className="modal fade"
          id={`AjouterContenuModal${row?.slug}`}
          aria-labelledby={`AjouterContenuModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <AddOrUpdateCitationModal
            modalId={`AjouterContenuModal${row?.slug}`}
            contenu={row}
          />
        </div>
        <div
          className="modal fade"
          id={`VoirContenuModal${row?.slug}`}
          aria-labelledby={`VoirContenuModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <VoirCitationModal
            modalId={`VoirContenuModal${row?.slug}`}
            contenu={row}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "theme",
      text: "Thèmes",
      formatter: (cell: Thematique) => cell?.libelle,
    },
    {
      dataField: "contenu",
      text: "CITATIONS",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "300px", whiteSpace: "normal" };
      },
      formatter: (cell: any, row: any) => contenuFormatter(cell, row),
    },
    {
      dataField: "created_at",
      text: "DATE",
      formatter: (cell: any, row: any) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListeContenusTable;
