import React, { useState } from "react";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import UseCrudPraticien from "../useForm/UseCrudPraticien";
import UseLinkPraticienRh from "../useForm/UseLinkPraticienRh";
import { getName } from "../../../../utils/Utils";

function AssignationRhEntrepriseModal({
  user,
  modalId,
}: {
  user?: any;
  modalId: string;
}) {
  const {
    onSubmit,
    errors,
    isLoading,
    control,
    ListeRh,
    onChangeEntreprise,
    entreprises
  } = UseLinkPraticienRh(modalId, user);

  const animatedComponents = makeAnimated();

  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5
            className="modal-title custom-praticien-name"
            id="AddTagModalLabel"
          >
            Assigner{" "}
            <span className="custom-praticien-name-span">{getName(user)}</span>{" "}
            à une entreprise
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12 col-form-add-student mb-4">
                  <div className="content-col-form-add-student">
                    <label className="form-label form-label-add-student">
                      {" "}
                      Sélectionner une entreprise
                    </label>
                    <Controller
                      name="entreprise"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={ListeRh?.map((item) => ({
                              value: item?.id,
                              label: item?.nom_entreprise,
                            }))}
                            classNamePrefix="react-select-custom"
                            onChange={onChangeEntreprise}
                            value={entreprises}
                          />
                        );
                      }}
                    />
                    {errors?.entreprise && (
                      <FormError
                          error={
                              errors?.entreprise?.message
                          }
                      />
                  )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    <BtnSubmit
                      label={"Terminer"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignationRhEntrepriseModal;