import React from 'react'
import QuestionsDiagnostiqueRhTable from '../QuestionsRhTables/QuestionsDiagnostiqueRhTable'

function QuestionsDiagnostiqueRh() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <QuestionsDiagnostiqueRhTable />
    </div>
  )
}

export default QuestionsDiagnostiqueRh