import React from "react"
import { BiUserPlus } from "react-icons/bi"
import BreadCrumb from "../../../shared/BreadCrumb"
import AddMembreModal from "./OrganisationsRhTables/Modals/AddMembreModal"
import OrganisationsRhTables from "./OrganisationsRhTables/OrganisationsRhTables"

function OrganisationsRh() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Organisation" title="Organisation" />
        </div>
        <button
          className="workcare-dash-admin-btn-ajout"
          data-bs-toggle="modal"
          data-bs-target="#AddMembreModal"
        >
          <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" /> Ajouter
        </button>
        <div
          className="modal fade"
          id="AddMembreModal"
          aria-labelledby="AddMembreModalLabel"
          aria-hidden="true"
        >
          <AddMembreModal modalId="AddMembreModal" />
        </div>
      </div>
      <div className="content-col-left-dashboard-visiteur">
        <div className="content-col-graph-admin">
          <div className="dashboard-praticien-table-recent-patient-container">
            <OrganisationsRhTables />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganisationsRh
