import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Specialite, SpecialiteFormData } from "./specialite.type";

export const SpecialiteApi = createApi({
  reducerPath: "specialiteApi",
  tagTypes: ["specialites", "specialitesByVisitor"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSpecialites: builder.query<PaginationResults<Specialite>, TypeQuery>({
      query: (query) => QueryUrl("specialites/", query),
      providesTags: ["specialites"],
    }),
    getSpecialitesByVisitor: builder.query<Specialite[], void>({
      query: () => `visitor/specialites`,
      providesTags: ["specialitesByVisitor"],
    }),
    addOrUpdateSpecialite: builder.mutation<
      Specialite,
      SpecialiteFormData 
    >({
      invalidatesTags: ["specialites", "specialitesByVisitor"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `specialites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `specialites/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteSpecialite: builder.mutation<Specialite, string>({
      query: (slug) => ({
        url: `specialites/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["specialites", "specialitesByVisitor"],
    }),
  }),
});

export const {
  useAddOrUpdateSpecialiteMutation,
  useGetSpecialitesByVisitorQuery,
  useDeleteSpecialiteMutation,
  useGetSpecialitesQuery,
} = SpecialiteApi;
