import React, { Dispatch, SetStateAction } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Bar } from "react-chartjs-2";
import { GrapheDiagnostic } from "../../../../utils/api/dashboard/dashboard.type";
import fr from "date-fns/locale/fr";
import Skeleton from "react-loading-skeleton";
registerLocale("fr", fr);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function EvolutionGraph({
  stats,
  date,
  setDate,
  frequence,
  setFrequence,
  isLoading
}: {
  stats?: GrapheDiagnostic[];
  date: string | Date;
  setDate: Dispatch<SetStateAction<Date | string>>;
  frequence: string;
  setFrequence: Dispatch<SetStateAction<string>>;
  isLoading: boolean
}) {
  const options = {
    responsive: true,
  };

  const labels = stats?.map((el) => el?.name) || [];

  const data = {
    labels,
    datasets: [
      {
        label: "Évolution inshight employés",
        data: stats?.map((el) => el?.value) || [],
        backgroundColor: stats?.map((el) => el?.value >= 3 ? "#0C98D4" : "#F85470") || [],
      },
    ],
  };

  return (
    <div className="chart-item-container">
      <div className="row mb-3 align-items-center">
        <div className="col-md-6 mb-2">
          <h2 className="chart-item-admin-title-admin mb-0">
            Évolution inshight employés
          </h2>
        </div>
        <div className="col-md-6 mb-2 d-flex calendars-container">
          <div className="w-auto">
            <select
              className="dashboard-select px-3 py-2"
              name="freq"
              id="freq"
              value={frequence}
              onChange={(e) => setFrequence(e?.target?.value)}
            >
              {/* <option value="">Fréquence</option> */}
              <option value="week">Hebdomadaire</option>
              <option value="month">Mensuelle</option>
              <option value="year">Annuelle</option>
            </select>
          </div>
          <div className="w-auto">
            {/* <select
              name="freq"
              className="dashboard-select px-3 py-2"
              id="freq"
            >
              <option value="">Date</option>
            </select> */}
            <DatePicker
              showIcon
              className="dashboard-select px-3 py-2"
              selected={date}
              onChange={(date) => setDate(date)}
              locale="fr"
              dateFormat={
                frequence === "week"
                  ? "dd/MM/yyyy"
                  : frequence === "month"
                  ? "MMMM/yyyy"
                  : "yyyy"
              }
              showMonthYearPicker={frequence === "month"}
              showYearPicker={frequence === "year"}
            />
          </div>
        </div>
      </div>
      {!!isLoading && <Skeleton height={457} />}

      {!isLoading && <Bar options={options} data={data} />}
    </div>
  );
}

export default EvolutionGraph;
