import React from 'react'
import ObjectisEntreprisetable from '../table/ObjectisEntreprisetable';

function ObjectifsEntreprise() {
  return (
    <div className="workcare-dash-admin-page-content-container">
      <ObjectisEntreprisetable />
    </div>
  );
}

export default ObjectifsEntreprise