import React, { useState } from "react"
import "./Login.css"
import Logo from "../../assets/appImages/logo.png"
import { NavLink } from "react-router-dom"
import { RiEyeLine } from "react-icons/ri"
import { AiFillEyeInvisible } from "react-icons/ai"
import useLoginForm from "./useAuthForm/useLoginForm"
import ErrorMessage from "../common/ErrorMessage"
import { BtnSubmit } from "../common/Button"

export const LoginForm = ({ onSubmit, errors, register, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword)
  }

  return (
    <div className="mt-4 content-auth-form">
      <form id="auth-form" onSubmit={onSubmit}>
        <div className="row auth-form-row">
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">Email ou Téléphone</label>
              <input
                type={`text`}
                className="form-control auth-form-control"
                id="email-or-username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                {...register("email")}
              />
              {errors?.email && (
                <ErrorMessage message={errors?.email?.message} />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end content-forget-password-link">
            <NavLink
              to={"/mot-de-passe-oublie"}
              className="forget-password-link"
            >
              Mot de passe oublié ?
            </NavLink>
          </div>
          <div className="col-md-12 auth-input-col mb-2">
            <div className="form-group auth-form-group auth-form-group-login position-relative">
              <label className="form-label-login">Mot de passe</label>
              <input
                type={`${showPassword ? "text" : "password"}`}
                className="form-control auth-form-control"
                id="password"
                data-testid="passwordId"
                aria-label="Password"
                aria-describedby="basic-addon1"
                {...register("password")}
              />
              <span
                className="span-eye-password-log"
                onClick={toggleShowPassword}
              >
                {!showPassword ? <RiEyeLine /> : <AiFillEyeInvisible />}
              </span>
            </div>
            {errors?.password && (
              <ErrorMessage message={errors?.password?.message} />
            )}
          </div>

          <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-4">
            <BtnSubmit
              // to="/admin/dashboard"
              className="btn auth-submit-btn-login trans-0-2"
              type="submit"
              label="Connexion"
              isLoading={isLoading}
            />
            {/* Connexion
            </BtnSubmit> */}
          </div>
        </div>
      </form>
    </div>
  )
}

const Login = () => {
  const { register, onSubmit, errors, isLoading } = useLoginForm()
  return (
    <div className="auth-component">
      <div className="empty-header-login fixed-top">
        <div className="content-logo-empty-header-login">
          <NavLink to={"/"} className="no-link">
            <img
              src={Logo}
              alt="Logo"
              className="img-logo-empty-header-login"
              style={{ width: 217 }}
            />
          </NavLink>
        </div>
      </div>
      <div className="fixed-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-5 auth-col auth-left-side-col"></div>
              <div className="col-md-7 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="auth-form-container">
                    <div className="content-text-redirect-auth mb-4">
                      <span className="info-connect-redirection">
                        Nouveau ?
                      </span>
                      <NavLink
                        to="/inscription"
                        className="redirect-text-auth ps-1"
                      >
                        Inscrivez-vous
                      </NavLink>
                    </div>
                    <h1 className="auth-form-title">Connexion</h1>
                    <LoginForm
                      register={register}
                      onSubmit={onSubmit}
                      errors={errors}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
