import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  DisponibiliteFormData,
  IDisponibilite,
} from "../../../../../utils/api/disponibilite/disponibilite.type";
import {
  useAddOrUpdateDisponibiliteMutation,
  useDeleteDisponibiliteMutation,
} from "../../../../../utils/api/disponibilite/disponibilite.api";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetRhQuery } from "../../../../../utils/api/user/user.api";
import moment from "moment";

function UseCrudDisponibilite(modalId: string, disponibilite?: IDisponibilite) {
  const validationSchema = yup.object().shape({
    date: yup.string().required().label("Date"),
    heure_ouverture: yup.string().required().label("Heure ouverture"),
    heure_fermeture: yup.string().required().label("Heure fermeture"),
    isRecursif: yup.boolean().nullable().default(false),
    frequence: yup.string().when("isRecursif", {
      is: true,
      then: yup.string().required().label("La fréquence").nullable(),
    }),
    nombre_de_mois: yup
      .number()
      .when("isRecursif", {
        is: true,
        then: yup
          .number()
          .required()
          .label("Le nombre de mois")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    jour: yup.string().label("Le jour").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<DisponibiliteFormData | any>({
    resolver: yupResolver(validationSchema),
  });
  const [isChecked, setIsChecked] = useState(false);
  const { user } = useAppSelector((s) => s.user);
  const [
    addOrUpdateDisponibilite,
    { isLoading, isSuccess, error, isError, data },
  ] = useAddOrUpdateDisponibiliteMutation();

  const { data: listRh } = useGetRhQuery({});

  const [entreprise, setEntreprise] = useState<
    { label?: string; value?: number } | undefined
  >();

  const onChangeEntreprise = (selected: any) => {
    console.log("selected", selected);
    setEntreprise(selected);
    setValue("entreprise", selected?.value);
  };

  const handleChecked = (e) => {
    setIsChecked(e?.target?.checked);
    setValue("isRecursif", e?.target?.checked);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Disponibilite ${
          disponibilite ? "modifié" : "ajouté"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!disponibilite) {
          reset();
          setEntreprise(undefined);
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: DisponibiliteFormData) => {
    data["praticien"] = user?.id;
    if (data?.isRecursif) {
      data["jour"] = moment(data?.date).format("dddd");
    } else {
      data["jour"] = "";
      data["frequence"] = "";
    }

    // console.log("data sub", data);
    await addOrUpdateDisponibilite({ slug: disponibilite?.slug, data: data });
  };

  useEffect(() => {
    if (disponibilite?.id) {
      // console.log("here");
      let fields: (keyof IDisponibilite)[] = [
        "slug",
        "date",
        "heure_ouverture",
        "heure_fermeture",
        "entreprise",
        "praticien",
        "frequence",
        "jour",
        "nombre_de_mois",
      ];

      for (let field of fields) {
        register(field);
        if (disponibilite[field]) {
          setValue(field, disponibilite[field]);
        }

        if (field == "praticien") {
          setValue("praticien", disponibilite?.praticien?.id);
        }

        if (field === "entreprise") {
          setValue("entreprise", disponibilite?.entreprise?.id);
          const getEntreprise = listRh?.results?.find(
            (item) => item?.id === disponibilite?.entreprise?.id
          );
          const entreprises = {
            label:
              getEntreprise?.nom_entreprise ||
              (getEntreprise?.prenom + " " + getEntreprise?.nom),
            value: getEntreprise?.id,
          };
          setEntreprise(entreprises);
        }
      }

      // if (disponibilite?.frequence) {
      //   setIsChecked(true);
      // }
    }
  }, [disponibilite]);

  const handleReset = () => {
    if (!disponibilite) {
      reset();
      setEntreprise(undefined);
      setIsChecked(false);
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    ListRh: listRh?.results,
    control,
    Controller,
    onChangeEntreprise,
    entreprise,
    setEntreprise,
    handleReset,
    handleChecked,
    isChecked,
  };
}

export function DeleteDispobilite(slug: string, modalid: string) {
  const [deleteData] = useDeleteDisponibiliteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce crénau ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `crénau supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          onHide(modalid);
        });
      }
    });
  };
  return onDelete;
}

export default UseCrudDisponibilite;
