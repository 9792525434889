import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Thematique, ThematiqueFormData } from "./thematique.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ThematiqueApi = createApi({
  reducerPath: "ThematiqueApi",
  tagTypes: ["themes", "thematique", "theme"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addThematique: build.mutation<
      Thematique,
      { slug?: string; data: Thematique }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `themes/${slug}/` : `themes/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["thematique"],
    }),
    getThematique: build.query<PaginationResults<Thematique>, TypeQuery>({
      query: (query) => QueryUrl("themes/", query),
      providesTags: ["thematique"],
    }),
    deleteThematique: build.mutation<ThematiqueFormData, string>({
      query: (slug) => ({
        url: `themes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["themes", "thematique"],
    }),
    addOrEditThematique: build.mutation<
      Thematique,
      {
        slug?: string;
        data: ThematiqueFormData | FormData;
      }
    >({
      invalidatesTags: ["themes", "thematique"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `themes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `themes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getThematiqueBySlug: build.query<Thematique, string>({
      query: (slug) => `themes/${slug}`,
      providesTags: ["theme"],
    }),
  }),
});

export const {
  useAddOrEditThematiqueMutation,
  useDeleteThematiqueMutation,
  useGetThematiqueQuery,
  useAddThematiqueMutation,
  useLazyGetThematiqueBySlugQuery,
} = ThematiqueApi;

export function useThematiqueFromLocation(): [
  Thematique,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Thematique | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] =
    useLazyGetThematiqueBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Thematique, isLoading, slug as string, findById];
}
