import $ from "jquery";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiBaseUrl } from "./http";
import Avatar1 from "../assets/appImages/avatar1.png";
import Avatar from "../assets/appImages/image-default.png";
import Avatars from "../assets/appImages/Avatars.png";
import defaultImage from "../assets/appImages/NoImage.png";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const phoneUtil = PhoneNumberUtil.getInstance();

export function validateNumber(phone?: string, country?: string) {
  if (!phone || !country) return true;
  try {
    
    
    const number = phoneUtil.parse("+" + phone, country);
    let code = number.getCountryCode()
    let val = phone?.split(`${code}`)[1];
    
    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }

    if (code === 33 && val?.length >= 9) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}

export function validatePhone(yup: typeof Yup, country: string) {
  return yup
    .string()
    .test("passwords-match", "Numéro téléphone invalide", (val) =>
      validateNumber(val, country)
    )
    .nullable();
}

export function createMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}
export function readMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  let content = text ? text.replace(/<.+?>/g, "") : text;
  return {
    __html: `${numberSlice ? content.slice(0, numberSlice) : content}${
      showDots === true ? "..." : ""
    }`,
  };
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export function isRH(user: any) {
  if (!user) return false;
  return ["rh"].includes(user.user_type);
}

export function isOrganisateur(user: any) {
  if (!user) return false;
  return ["rh"].includes(user.user_type) && ["rh"].includes(user.sous_type);
}

export function isPraticien(user: any) {
  if (!user) return false;
  return ["praticien"].includes(user.user_type);
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export function useModalActions(modalId) {
  const [modal, setModal] = useState<any>(null);
  useEffect(() => {
    var myModal = new Modal(document.getElementById(modalId), {});
    if (document.readyState === "complete") {
      setModal(myModal);
    }
    document.onreadystatechange = function () {
      setModal(myModal);
    };
  }, []);

  const show = () => {
    $(".modal-backdrop").css({ display: "block" });
    modal?.show();
  };
  const hide = () => {
    modal?.hide();
    setTimeout(() => {
      $(".modal-backdrop").css({ display: "none" });
      $("body").css({ overflow: "scroll" });
    }, 500);
  };

  return { show, hide };
}

export const showModal = (id: string) => {
  // console.log("id", id);
  const element = document.getElementById(id);

  const myModal = new Modal(element);
  myModal.show();
};

export const closeModal = (id: string) => {
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "scroll");
  myModal?.hide();
  $("body").css("overflow", "auto");
};

export const onHide = (modalId: string) => {
  document.getElementById(modalId)?.classList.remove("show");
  $(".modal-backdrop").remove();
  $(`#${modalId}`).hide();
  $(`#${modalId}`).click();
  $(`body`).css({ overflowY: "scroll" });
  $(`#${modalId}`).click();

  setTimeout(() => {
    $(`body`).css({ overflow: "scroll" });
  }, 1000);
};

export const onHideSideBar = () => {
  $(".text-reset").click();
};

export function getName(item: any) {
  if (item) {
    return item?.prenom + " " + item?.nom;
  }
  return null;
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}

export const getImage = (img: string | any) => {
  if (img && !img.includes("default.png") && !img.includes("static/media/")) {
    if (img.indexOf("http") === 0) return img;
    return ApiBaseUrl + img;
  }
  return defaultImage;
};

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return ApiBaseUrl + avatar;
  }
  return Avatars;
};

export function getAvatarFromUser(user: any) {
  const av1 = getAvatar(user.avatar);
  if (av1 === Avatar) return getAvatar(user.profil.photo);
  return av1;
}

export const getImageProfil = (avatar: string | any) => {
  if (avatar && !avatar.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return ApiBaseUrl + avatar;
  }
  return Avatar1;
};

export const getFileUrl = (file: string | any) => {
  if (file && !file.includes("default.png")) {
    if (file.indexOf("http") === 0) return file;
    return ApiBaseUrl + file;
  }
  return null;
};

export function createUrl(image: File) {
  return URL.createObjectURL(image);
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}
export function truncateStringReverse(str, num) {
  if (str.length <= num) {
    return str;
  }
  return "..." + str.slice(-num);
}

export const get_url_extension = (uri: string) => {
  if (!uri) return "";
  return uri?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
};
