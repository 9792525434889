export interface ICondition {
    id: number
    slug: string,
    text: string,
    type: string
    titre: string
}

export type ConditionFormdata = {
    id: number
    slug: string,
    text: string,
    type: string
    titre: string
}

export enum ConditionType {
    technical = 'technical',
    policy = 'policy',
    cgu = 'cgu',
    ml = 'ml',
    aide = 'aide',
}