import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { CompteRendu, CompteRenduFormData } from "./compteRendu.type";

export const CompteRenduApi = createApi({
  reducerPath: "compteRenduApi",
  tagTypes: ["compteRendus", "compteRendusByRdv"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCompteRendus: builder.query<PaginationResults<CompteRendu>, TypeQuery>({
      query: (query) => QueryUrl("compterendus/", query),
      providesTags: ["compteRendus"],
    }),
    getCompteRendusByRdv: builder.query<
      PaginationResults<CompteRendu>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`rdv/${slug}/compterendus`, query),
      providesTags: ["compteRendusByRdv"],
    }),
    addOrUpdateCompteRendu: builder.mutation<
      CompteRendu,
      { slug?: string; data: CompteRenduFormData | FormData }
    >({
      invalidatesTags: ["compteRendus", "compteRendusByRdv"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `compterendus/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `compterendus/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteCompteRendu: builder.mutation<CompteRendu, string>({
      query: (slug) => ({
        url: `compterendus/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["compteRendus", "compteRendusByRdv"],
    }),
  }),
});

export const {
  useAddOrUpdateCompteRenduMutation,
  useGetCompteRendusByRdvQuery,
  useDeleteCompteRenduMutation,
  useGetCompteRendusQuery,
} = CompteRenduApi;
