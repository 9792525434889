import React from "react";
import ListeContenusTable from "../ListeContenusTables/ListeContenusTable";
import BreadCrumb from "../../../shared/BreadCrumb";
import { AiOutlinePlusSquare } from "react-icons/ai";
import AddOrUpdateCitationModal from "../Modals/AddOrUpdateCitationModal";
import { showModal } from "../../../../utils/Utils";

function MinutesCares() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb
            title="Minutes cares"
            pageName="Thématiques"
            secondPath={`/admin/contenus`}
          />
        </div>
        <button
          className="workcare-dash-admin-btn-ajout-deux"
          // data-bs-toggle="modal"
          // data-bs-target="#AddOrUpdateCitationModal"
          onClick={() => showModal("AddOrUpdateCitationModal")}
        >
          <AiOutlinePlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
          Ajouter
        </button>

        <div
          className="modal fade"
          id="AddOrUpdateCitationModal"
          aria-labelledby="AddOrUpdateCitationModalLabel"
          aria-hidden="true"
        >
          <AddOrUpdateCitationModal modalId="AddOrUpdateCitationModal" />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="kanimmo-tabs-locataire-container">
          <div className="tabs-component-container">
            {/* <Component /> */}{" "}
            <div className="workcare-dash-admin-page-content-container">
              <ListeContenusTable />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MinutesCares;
