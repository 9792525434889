import React, { useState } from "react"
import RecentPatientTable from "../Dashboard/Tables/RecentPatientTables/RecentPatientTable"
import "./PriseEnchargeListe.css"

function PriseEnChargeListe() {
  const [query, setQuery] = useState("")
 

  return (
    <div className="dashbord-visiteur-component mb-4">
      <div className="col-md-12 col-left-dashboard-visiteur mb-3">
        <div className="content-col-left-dashboard-visiteur">
          <div className="content-col-graph-admin">
            <h4 className="dashboard-praticien-titre-rdv">Prise en charge</h4>
            <div className="dashboard-praticien-filter-container">
              <form className="row g-3 locataire-requete-filtre-form">
                <div className="col-auto">
                  <label
                    htmlFor="filtreLogement"
                    className="locataire-requete-filtre-label"
                  >
                    Filtrer
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="text"
                    className="form-control locataire-requete-filtre-input"
                    id="filtreLogement"
                    placeholder="Chercher"
                    onChange={(e) => setQuery(e?.target?.value)}
                  />
                </div>
              </form>
            </div>
            <div className="dashboard-praticien-table-recent-patient-container my-4">
              <RecentPatientTable
                query={query}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PriseEnChargeListe
