import React from 'react'
import QuestionDiagnostiqueTable from '../QuestionsTable/QuestionDiagnostiqueTable'

function QuestionDiagnostique() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <QuestionDiagnostiqueTable />
    </div>
  )
}

export default QuestionDiagnostique