import React from "react";
import ReactQuill from "react-quill";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../../common/Button";
import { SelectInput } from "../../../../common/SelectInput";

function AddOrUpdateSécurite({
  modalId,
  condition,
}: {
  modalId: string;
  condition?: any;
}) {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {condition ? "Modifier" : "Ajouter"} sécurité
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <ReactQuill
                      className="editor-cgu custom-editor-politique-confidentialite"
                      // value={description}
                      // onChange={handleChange}
                    />
                    {/* {errors.text && (
                                            <ErrorMessage
                                                message={
                                                    errors.text?.message
                                                }
                                            />
                                        )} */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    <BtnSubmit
                      label={condition ? "Modifier" : "Ajouter"}
                      isLoading={
                        // isLoading
                        false
                      }
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateSécurite;
