import React from 'react'
import SpecialitesTable from '../Parametrestables/SpecialitesTable';

function SpecialitesTab() {
  return (
    <div className="workcare-dash-admin-page-content-container">
      <SpecialitesTable />
    </div>
  );
}

export default SpecialitesTab