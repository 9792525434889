import React, { useState } from "react";
import "./HeaderAdmin.css";
import { NavLink, useNavigate } from "react-router-dom";
import { RiArrowDropDownFill } from "react-icons/ri";
import avatar from "../../../../assets/appImages/image.png";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import { FiPlus, FiSearch } from "react-icons/fi";
import Logo from "../../../../assets/appImages/logo.png";
import { MdOutlineNotificationsActive } from "react-icons/md";
import "../SidebarAdmin/SidebarAdmin.css";
import { onlogout } from "../../../../redux/slice/User.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createMarkup, getAvatar, isPraticien } from "../../../../utils/Utils";
import {
  useGetNotificationByUserQuery,
  useUpdateReadNotificationMutation,
} from "../../../../utils/api/notification/notification.api";
import { INotification } from "../../../../utils/api/notification/notification.type";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import moment from "moment";
import { IUser } from "../../../../utils/api/user/user.type";

const HeaderAdmin: React.FC = () => {
  var [showNotifications, setShowNotifications] = React.useState(false);
  const [item, setItem] = useState<INotification>();
  const [updateNotif] = useUpdateReadNotificationMutation();

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };
  const { user } = useAppSelector((s) => s?.user);
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(onlogout());
    window.location.pathname = "/";
  };

  const { data = { results: [], count: 0 }, isLoading } =
    useGetNotificationByUserQuery({ slug: user?.slug });

  const navigate = useNavigate();

  var onUpdateReadNotification = (notif: INotification) => {
    setShowNotifications(false);
    setItem(notif);
    if (notif?.type !== "proratat") {
      updateNotif({ slug: notif.slug, read: true });
      navigate(
        isPraticien(user)
          ? `/praticien/notifications`
          : `/admin/dashboard`,
        { state: notif?.data }
      );
    }
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="content-logo-header-admin">
            <img src={Logo} alt="Logo" className="img-logo-header-admin" />
          </div>
          <div className="header-others-items">
            {/* <div className="search-form-container">
              <div className="search-form-content">
                <label>
                  <FiSearch />
                </label>
                <input
                  name="search"
                  className="form-control search-input"
                  placeholder="Rechercher"
                />
              </div>
            </div>
            <div className="add-item-container linkable">
              <div className="add-item-content">
                <FiPlus
                  className="header-add-item-icon"
                  style={{ cursor: "pointer" }}
                  // onClick={(e) =>
                  //     onShowAndHideHeaderAction(e)
                  // }
                />
              </div>
            </div> */}
            <div className="container-menu-header-admin gap-3">
              <div
                className="notification-item-container linkable position-relative"
                onClick={() => onShowAndHideNotification()}
              >
                <div className="notification-item-content">
                  <MdOutlineNotificationsActive className="notif-icon" />
                  {data?.results?.filter((x) => !x.read)?.length == 0 ? null : (
                    <span className="notification-counter">{data?.results?.filter((x) => !x.read)?.length}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header dropdown-toggle btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <img
                        src={getAvatar(user?.avatar)}
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      <RiArrowDropDownFill />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      to="#"
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                    >
                      {`${user?.prenom} ${user?.nom}`}
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <button
                      type="button"
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                      onClick={handleLogout}
                    >
                      Déconnexion
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="hiddenable-nav-mobile-container no-view-desktop">
              <div className="hiddenable-nav-mobile-content">
                <div className="container-nav-mobile">
                  <SidebarMobile />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <ul className="header-hiddenable-notification-ul">
              <li className="header-hiddenable-notification-li">
                Il n'y a aucune notification .
              </li>
            </ul>
          </div>
        )}

        {showNotifications && (
          <div
            className={
              data?.results?.filter((el) => el?.read !== true)?.length
                ? "header-hiddenable-notification-section"
                : "header-hiddenable-notification-sect"
            }
          >
            <ul
              className={
                data?.results?.filter((el) => el?.read !== true)?.length
                  ? "header-hiddenable-notification-ul"
                  : "header-hiddenable-notification-ol"
              }
            >
              {data?.results?.filter((x) => x.read !== true)?.length == 0 ? (
                <li
                  key={item?.slug}
                  className="header-hiddenable-notification-li"
                  style={{ fontSize: 16 }}
                >
                  Il n'y a aucune notification .
                </li>
              ) : 
              (
                data?.results
                  ?.filter((x) => x.read !== true)?.slice(0,10)
                  ?.map((item) => (
                    <div className="mb-2">
                      <li
                        key={item?.slug}
                        className="header-hiddenable-notification-li"
                        onClick={() => onUpdateReadNotification(item)}
                      >
                        <NavLink
                          to={getRoute(item, user)}
                          className="notification-item-link d-flex no-link"
                          state={
                            item?.type !== "nouveau"
                              ? item?.type === "message"
                                ? ""
                                : item
                              : item?.data
                          }
                        >
                          <div className="content-text-notif ps-2">
                            <p
                              className="text-notif mb-1"
                              dangerouslySetInnerHTML={createMarkup(
                                item?.content
                              )}
                            />
                            <p className="text-date-notif mb-0">
                              {moment(item?.created_at).format(
                                "DD/MM/yy - HH:mm"
                              )}
                            </p>
                          </div>
                        </NavLink>
                      </li>
                      <NavLink to={`${user?.user_type === "praticien" ? "/praticien/notifications" : "/admin/notifications"}`} className="btn-notif-voir-plus">Afficher toutes les notifications</NavLink>
                    </div>
                  ))
                  )
              }
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;

function getRoute(item: INotification, user: IUser) {
  let route = "/";

  if (item?.type === "nouveau") {
    // if (isAgence(user)) {
    //   route = `/agence/liste-locataire/${item?.data?.slug}`;
    // }
    // if (isLocataire(user)) {
    //   route = `/locataire/administratif`;
    // }
  }

  return route;
}
