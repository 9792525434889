import React from "react";
import { Specialite } from "../../../../utils/api/specialite/specialite.type";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { Input } from "../../../common/Input";
import UseCrudSpecialiteForm from "../useForm/UseCrudSpecialiteForm";

function Add0rEditSpecialiteModal({
  modalId,
  specialite,
}: {
  modalId: string;
  specialite?: Specialite;
}) {
  const { onSubmit, errors, register, isLoading, reset } =
    UseCrudSpecialiteForm(modalId, specialite);
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header px-2 py-3">
          <h5 className="modal-title" >
            {!specialite ? "Ajouter" :  "Modifier"} une spécialité
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal
              label=""
              onClick={() => {
                if (!specialite) {
                  reset();
                }
              }}
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    required
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors?.nom}
                  />
                </div>

                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal
                      label={"Annuler"}
                      onClick={() => {
                        if (!specialite) {
                          reset();
                        }
                      }}
                    />
                    <BtnSubmit
                      label={specialite ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add0rEditSpecialiteModal;
