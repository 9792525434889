import React, { useEffect } from "react";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import ReactQuill from "react-quill";
import { ConditionType } from "../../../../utils/api/condition/condition.type";
import UseCrudCondition from "../useForm/UseCrudCondition";
import ErrorMessage from "../../../common/ErrorMessage";
import { SelectInput } from "../../../common/SelectInput";

const typeConditionOption = [
  {
    value: ConditionType.cgu,
    label: "Conditions générales d’utilisation",
  },
  {
    value: ConditionType.policy,
    label: "Poliitique de confidentialité",
  },
  {
    value: ConditionType.technical,
    label: "Technique",
  },
  {
    value: ConditionType.aide,
    label: "Aide",
  },
  {
    value: ConditionType.ml,
    label: "Mention légale",
  },
];

function AddOrUpdatePolitique({
  condition,
  modalId,
  type,
}: {
  condition?: any;
  modalId: string;
  type: "ml" | "policy";
}) {
  const {
    register,
    errors,
    onSubmit,
    setValue,
    isLoading,
    handleReset,
    description,
    setDescription,
    handleChange,
  } = UseCrudCondition(modalId, type, condition);

  useEffect(() => {
    if (condition) {
      setValue("type", condition?.type);
      setValue("titre", condition?.titre);
      setValue("slug", condition?.slug);
      setValue("text", condition?.text);
      setDescription(condition?.text || "");
    }
  }, [condition]);

  let title = typeConditionOption?.find((el) => el?.value === type)?.label;

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content text-start">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {condition ? "Modification" : "Ajout"} {title}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" onClick={() => handleReset()} />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="mb-4">
                    <SelectInput
                      label="Choisissez le type de condition"
                      required
                      options={typeConditionOption}
                      {...register("type")}
                      // error={errors?.type}
                    />
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="nombre_stock"
                      className="form-label form-label-modal-custom"
                      aria-labelledby={"text"}
                    >
                      Contenu
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <ReactQuill
                      className="editor-cgu custom-editor-politique-confidentialite"
                      value={description}
                      onChange={handleChange}
                    />
                    {errors.text && (
                      <ErrorMessage message={errors.text?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal
                      label={"Annuler"}
                      isLoading={false}
                      onClick={() => handleReset()}
                    />
                    <BtnSubmit
                      label={condition ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdatePolitique;
