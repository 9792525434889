import React from 'react'
import { RiFileDownloadLine } from 'react-icons/ri'
import { IRdv } from '../../../../../../utils/api/rdv/rdv.type'
import DocumentTable from '../DiagnostiqueTables/DocumentTable'

function Document({rdv}:{rdv:IRdv}) {
  return (
    <div className='dashboard-praticien-diagnostique-container'>
      {/* <div className='dashboard-praticien-diagnostique-btn-add-document-container'>
        <button className='btn dashboard-praticien-diagnostique-btn-add-document'><RiFileDownloadLine className='dashboard-praticien-diagnostique-btn-add-document-icon' /> Ajouter un document</button>
      </div> */}
      <div className='dashboard-praticien-table-recent-patient-container my-4'>
        <DocumentTable rdv={rdv} />
      </div>
    </div>
  )
}

export default Document