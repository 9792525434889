import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { useGetSpecialitesQuery } from "../../../../utils/api/specialite/specialite.api";
import { Specialite } from "../../../../utils/api/specialite/specialite.type";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import Add0rEditSpecialiteModal from "../Modals/Add0rEditSpecialiteModal";
import { UseDeleteSpecialite } from "../useForm/UseCrudSpecialiteForm";
import { showModal } from "../../../../utils/Utils";

function DeleteBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteSpecialite(slug);
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content={`Supprimer`}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}
function SpecialitesTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetSpecialitesQuery({ limit: perPage, page });

  const actionFormatter = (cell: string, row: Specialite) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Modifier"
            // data-bs-toggle="modal"
            // data-bs-target={`#editSpecialiteModal${cell}`}
            onClick={() => showModal(`editSpecialiteModal${cell}`)}
          >
            <MdModeEditOutline />
          </button>

          <DeleteBtn slug={cell} />
        </div>
        <div
          className="modal fade"
          id={`editSpecialiteModal${cell}`}
          aria-labelledby={`editSpecialiteModal${cell}Label`}
          aria-hidden="true"
        >
          <Add0rEditSpecialiteModal
            specialite={row}
            modalId={`editSpecialiteModal${cell}`}
          />
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      formatter: (cell: number) => cell,
    },
    {
      dataField: "nom",
      text: "Nom",
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Specialite) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SpecialitesTable;
