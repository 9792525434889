import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../utils/api/user/user.type";
import { useLocationState } from "../../../utils/Utils";
import InfosEntreprise from "./EntrepriseTabs/InfosEntreprise";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import ObjectifsEntreprise from "./EntrepriseTabs/ObjectifsEntreprise";

const steps = [
  { id: "infos", Component: InfosEntreprise },
  { id: "objectif", Component: ObjectifsEntreprise },
];
const tabs = ["Informations", "Objectifs & Actions"];
function EntrepriseDetails() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const navigate = useNavigate();
  const user = useLocationState<IUser>(undefined);

  useEffect(() => {
    if (!user) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <div className="breadcrumb-component">
            <section className="breadcrumb-container container-fluid">
              <div className="breadcrumb-page-title-content-body flex-sb-mobile">
                <div className="breadcrumb-page-container-title-content-body gap-2">
                  <h1 className="workcare-dash-admin-titre">
                    Détails entreprise
                  </h1>
                  <div className="breadcrumb-row gap-2 d-flex align-items-center">
                    <div className="base-home-container d-flex align-items-center">
                      <div className="home-label-container">
                        <NavLink
                          to="/admin/entreprises"
                          className="home-breadcrumb-link"
                        >
                          {" "}
                          <span className="home-label">Entreprises</span>
                        </NavLink>
                      </div>
                      <div className="arrow-icon-container">
                        <MdKeyboardArrowRight className="arrow-icon" />
                      </div>
                    </div>
                    <div className="second-level-breadcrumb-container">
                      <span className="breadcrumb-second-level-label">
                        Détails entreprise
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntrepriseDetails;
