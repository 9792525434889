import React from "react"
import { AiOutlinePlusSquare } from "react-icons/ai"
import BreadCrumb from "../../../shared/BreadCrumb"
import AddObjectifAction from "./ObjectifActionModal/ObjectifsActionsModal"
import ObjectifsActionsRhTables from "./ObjectifsActionsRhTables/ObjectifsActionsRhTables"

function ObjectisActionsRh() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb
            pageName="Objectifs et actions"
            title="Objectifs & actions"
          />
        </div>
        <button
          className="workcare-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target="#AddObjectifAction"
        >
          <AiOutlinePlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
          Ajouter
        </button>
        <div
          className="modal fade"
          id="AddObjectifAction"
          aria-labelledby="AddObjectifActionLabel"
          aria-hidden="true"
        >
          <AddObjectifAction modalId="AddObjectifAction" />
        </div>
      </div>
      <div className="content-col-left-dashboard-visiteur">
        <div className="content-col-graph-admin">
          <div className="dashboard-praticien-table-recent-patient-container">
            <ObjectifsActionsRhTables />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectisActionsRh
