import React, { useEffect, useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import "./Disponibilite.css";
import moment from "moment";
import {
  useGetDisponibiliteByPraticienQuery,
} from "../../../../utils/api/disponibilite/disponibilite.api";
import { useAppSelector } from "../../../../redux/hooks";

const DisponibiliteAgenda = () => {
  const [date, setDate] = useState(moment().startOf("week"));
  const [dateFin, setDateFin] = useState(moment().endOf("week"));

  const handleDate = (n) => {
    const newDate = moment(date).add(n, "days");
    setDate(newDate.startOf("week"));
    setDateFin(newDate.endOf("week"));
  };
  const { user } = useAppSelector((s) => s?.user);
  const { data: disponibilites, isLoading } =
    useGetDisponibiliteByPraticienQuery({
      slug: user?.slug,
    });

  const [horaires, setHoraires] = useState(getHoraire(date));

  useEffect(() => {
    setHoraires(getHoraire(date));
  }, [date.format("L")]);

  console.log("dispo", disponibilites);


  function handleSearch(date, heure) {
    let is_include = disponibilites?.filter((item) => item.date === date)?.[0]?.date?.includes(date);
    console.log("is_include", is_include);
  }

  console.log("disponibilites", disponibilites);

  return (
    <div id="carouselExampleControlsNoTouching" className="">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="checkbox-add-event-container">
            <div className="mb-4 custom-agenda-controler-container">
              <div className="">
                <button
                  onClick={() => handleDate(-7)}
                  className="btn crud-submit-btn-beneficiaire p-3"
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
              </div>
              <div className="pt-3 custom-text-legend-container">
                <p className="legend text-center">
                  Semaine du{" "}
                  <span className="text-legend-bold">
                    {date.format("DD MMM")}
                  </span>{" "}
                  au{" "}
                  <span className="text-legend-bold">
                    {dateFin.format("DD MMMM")}
                  </span>
                </p>
              </div>
              <div className=" text-md-right">
                <button
                  className="btn crud-submit-btn-beneficiaire p-3"
                  onClick={() => handleDate(7)}
                >
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
            <div className="checkbox-add-event mt-4">
              {horaires?.map((item) => {
                const date = item.date.format("dddd");
                return (
                  <div className="label-form-container" key={date}>
                    <p className="label-form-add-event mb-2">{date}</p>
                    <div className="container-checkbox">
                      {item?.horaire?.map((itm) => {
                        const heure = itm.format("HH");
                        return (
                          <label key={heure}>
                            <input
                              type="checkbox"
                              defaultChecked={itm.defaultChecked}
                              onClick={() => handleSearch(item.date.format("yyyy-MM-DD"), heure)}
                            />
                            <span>{heure}h</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisponibiliteAgenda;

const getHoraire = (current_date) => {
  const date_debut = moment(current_date).startOf("week");

  const horaire: any = [];
  for (let i = 0; i < 7; i++) {
    const date = moment(date_debut.toDate().getTime()).add(i, "days");
    const heures: any = [];
    for (let i = 8; i <= 19; i++) {
      heures.push(moment(date.toDate().getTime()).set("hours", i));
    }
    horaire.push({
      date: date,
      horaire: heures,
    });
  }
  return horaire;
};
