import React from "react"
import "./SidebarAdmin.css"
import { AiFillFileText, AiTwotoneSetting } from "react-icons/ai"
import { FaFileAlt, FaQuestion, FaUserAlt, FaUsers } from "react-icons/fa"
import { BiCalendar } from "react-icons/bi"
import { NavLink, useLocation } from "react-router-dom"
import { GoPerson } from "react-icons/go"
import { IoPersonSharp, IoStatsChartSharp } from "react-icons/io5"
import VersionChecker from "../../../../versionChecker/VersionChecker"
import { MdAccountBox } from "react-icons/md"

const SidebarAdmin = () => {
  let location = useLocation()

  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <ul className="nav nav-items-custom flex-column">
            {/* Routes admin */}

            {location?.pathname?.startsWith("/admin") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Suivi  évolution santé"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoStatsChartSharp />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Suivi évolution santé
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/agenda"
                    data-tooltip-content="Mon agenda"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiCalendar />
                    </span>
                    <span className="hiddenable-navlink-label">Mon agenda</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/utilisateurs"
                    data-tooltip-content="Utilisateurs"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUsers />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Utilisateurs
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/praticiens"
                    data-tooltip-content="Praticiens"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <GoPerson />
                    </span>
                    <span className="hiddenable-navlink-label">Praticiens</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/entreprises"
                    data-tooltip-content="Entreprises"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdAccountBox />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Entreprises
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/objectifs-et-actions"
                    data-tooltip-content="Objectifs et actions"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaFileAlt />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Objectifs et actions
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom pb-3">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/contenus"
                    data-tooltip-content="Contenus"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaFileAlt />
                    </span>
                    <span className="hiddenable-navlink-label">Contenus</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom border-bottom-theme pb-3">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/questions"
                    data-tooltip-content="Questions"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaQuestion />
                    </span>
                    <span className="hiddenable-navlink-label">Questions</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom mt-3">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/mon-compte"
                    data-tooltip-content="Mon compte"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoPersonSharp />
                    </span>
                    <span className="hiddenable-navlink-label">Mon compte</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom border-bottom-theme">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {/* Routes admin */}

            {/* Route Praticien */}
            {location?.pathname?.startsWith("/praticien") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/praticien/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoStatsChartSharp />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/praticien/mon-agenda"
                    data-tooltip-content="Mon agenda"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiCalendar />
                    </span>
                    <span className="hiddenable-navlink-label">Mon agenda</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/praticien/prise-en-charge"
                    data-tooltip-content="Prise en charge"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiFillFileText />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Prise en charge
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom border-bottom-theme">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/praticien/mon-profil"
                    data-tooltip-content="Mon profil"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserAlt />
                    </span>
                    <span className="hiddenable-navlink-label">Mon profil</span>
                  </NavLink>
                </li>
              </>
            )}
            {/* Route Praticien */}

            {/* Route Rh */}
            {location?.pathname?.startsWith("/rh") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/rh/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <IoStatsChartSharp />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/rh/objectifs-et-actions"
                    data-tooltip-content="Objectifs et actions"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaFileAlt />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Objectifs et actions
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/rh/organisations"
                    data-tooltip-content="Organisations"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUsers />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Organisations
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom pb-3">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/rh/questions"
                    data-tooltip-content="Questions"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaQuestion />
                    </span>
                    <span className="hiddenable-navlink-label">Questions</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom border-bottom-theme">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/rh/mon-profil"
                    data-tooltip-content="Mon profil"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserAlt />
                    </span>
                    <span className="hiddenable-navlink-label">Mon profil</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom border-bottom-theme">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/rh/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiTwotoneSetting />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {/* Route Rh */}
            <li className="nav-item nav-item-vertical-custom">
              <div className="version-view-container">
                {"v" + VersionChecker()}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin
