import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { ApiBaseUrl } from "../../http"
import { AppStorage } from "../../storage"
import { QueryUrl } from "../../Utils"
import { LoginResult } from "../auth/auth.type"
import {
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
  UserFormData,
} from "../user/user.type"
import { ObjectifFormData, ObjectifType } from "./objectif.types"

export const prepareHeaders = (headers: Headers, { getState }: any) => {
  const token =
    (getState() as { user: LoginResult }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token
  if (token) {
    headers.set("Authorization", `Bearer ${token}`)
  }
  return headers
}

export const ObjectifApi = createApi({
  reducerPath: "objectifApi",
  tagTypes: ["objectif", "objectifList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addObjecftifAction: builder.mutation<
      ObjectifType,
      { slug?: string; data: ObjectifFormData | FormData }
    >({
      invalidatesTags: ["objectif", "objectifList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `objectifs/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `objectifs/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getObjectifAction: builder.query<
      PaginationResults<ObjectifType>,
      TypeQuery
    >({
      query: (query) => QueryUrl(`objectifs/`, query),
      providesTags: ["objectifList"],
    }),
    getObjectifByRhAction: builder.query<
      PaginationResults<ObjectifType>,
      TypeQuery
    >({
      query: ({ slug, ...query }) => QueryUrl(`rh/${slug}/objectifs/`, query),
      providesTags: ["objectifList"],
    }),

    deleteObjectifAction: builder.mutation<ObjectifFormData, string>({
      query: (slug) => ({
        url: `objectifs/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["objectif", "objectifList"],
    }),
  }),
});

export const {
  useAddObjecftifActionMutation,
  useGetObjectifActionQuery,
  useDeleteObjectifActionMutation,
  useGetObjectifByRhActionQuery
} = ObjectifApi
