import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { IQuestionDiagnostic, QuestionDiagnostic } from "./question.type";

export const QuestionDiagnostiqueApi = createApi({
  reducerPath: "questionDiagnostiqueApi",
  tagTypes: ["autoDiagnostique", "questiondiagnostics"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getResponseQuestionDiagnostique: build.query<
      PaginationResults<IQuestionDiagnostic>,
      TypeQuery
    >({
      query: ({ slug, slugRh, ...query }) =>
        QueryUrl(
          slugRh
            ? `rh/${slugRh}/questions_diagnostics/${slug}/responses/`
            : `questions_diagnostics/${slug}/responses/`,
          query
        ),
      providesTags: ["autoDiagnostique"],
    }),
    getQuestionsDiagnostic: build.query<QuestionDiagnostic[], TypeQuery>({
      query: (query) => QueryUrl(`verbatim/questions_diagnostics/`, query),
      providesTags: ["questiondiagnostics"],
    }),
    getQuestionsEvaluation: build.query<QuestionDiagnostic[], TypeQuery>({
      query: (query) => QueryUrl(`verbatim/questions_evaluations/`, query),
      providesTags: ["questiondiagnostics"],
    }),
    getResponseQuestionEvaluation: build.query<
      PaginationResults<IQuestionDiagnostic>,
      TypeQuery
    >({
      query: ({ slug, slugRh, ...query }) =>
        QueryUrl(
          slugRh
            ? `rh/${slugRh}/questions_evaluations/${slug}/responses/`
            : `questions_evaluations/${slug}/responses/`,
          query
        ),
      providesTags: ["autoDiagnostique"],
    }),
  }),
});

export const {
  useGetQuestionsDiagnosticQuery,
  useGetResponseQuestionDiagnostiqueQuery,
  useGetQuestionsEvaluationQuery,
  useGetResponseQuestionEvaluationQuery,
} = QuestionDiagnostiqueApi;
