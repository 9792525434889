/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { BtnAnnulerModal, BtnSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import { SelectInput } from "../../../common/SelectInput";
import ReactQuill from "react-quill";
import ErrorMessage from "../../../common/ErrorMessage";
import { Diagnostic } from "../../../../utils/api/autoDiagnostique/autoDiagnostique.type";
import {
  useDeleteResponseAutoDiagnosticMutation,
  useDeleteResponseEvaluationMutation,
} from "../../../../utils/api/autoDiagnostique/autoDiagnostique";
import { FaTrash } from "react-icons/fa";

export const TypeReponseOption = [
  {
    value: "choice",
    label: "Choix multiples",
  },
  {
    value: "checkbox",
    label: "Choix possible",
  },
  {
    value: "text_field",
    label: "Champ libre",
  },
  {
    value: "message",
    label: "Message de succès",
  },
  {
    value: "select",
    label: "Sensation",
  },
  {
    value: "evaluation",
    label: "Echelle de 1 à 10",
  },
];
function FormContent({
  onSubmit,
  isLoading,
  errors,
  register,
  description,
  item,
  handleChange,
  onSelectType,
  type,
  from,
  preview,
  handelImageChange,
  setInputFields,
  inputFields,
  theme,
  fields,
  append,
  remove,
}: {
  onSubmit: any;
  isLoading: any;
  errors: any;
  register: any;
  description: any;
  item?: Diagnostic;
  handleChange: any;
  onSelectType: any;
  type: string;
  from?: string;
  handelImageChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  preview?: any;
  inputFields?: any;
  setInputFields?: any;
  theme?: string;
  fields: any[];
  append: any;
  remove: any;
}) {
  let deleteItem;

  if (theme === "autodiagnostique") {
    [deleteItem] = useDeleteResponseAutoDiagnosticMutation();
  } else {
    [deleteItem] = useDeleteResponseEvaluationMutation();
  }

  useEffect(() => {
    if (item && item?.responses?.length) {
      const currentResponsesCount = inputFields.length;
      const requiredResponsesCount = item.responses.length;

      if (currentResponsesCount < requiredResponsesCount) {
        const additionalResponses = Array(
          requiredResponsesCount - currentResponsesCount
        ).fill({ response: "" });
        setInputFields([...inputFields, ...additionalResponses]);
      }
    }
  }, [item]);

  const addFields = () => {
    let newfield = {
      response: "",
    };
    append(newfield)
    setInputFields([...inputFields, newfield]);
  };

  const removeField = (index, slug) => {
    remove(index)
    if (slug) {
      deleteItem(slug)
        .then(() => {
          const updatedFields = [...inputFields];
          updatedFields.splice(index, 1);
          setInputFields(updatedFields);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
    
  };

  return (
    <form onSubmit={onSubmit} className="text-start">
      <div className="row">
        <div className="col-md-9">
          <Input
            type="text"
            label="Question"
            id="question"
            placeholder="Question"
            {...register("contenu")}
            error={errors?.contenu?.message}
          />
        </div>
        <div className="col-md-3">
          <Input
            type="number"
            label="Ordre"
            id="ordre"
            min={1}
            {...register("ordre")}
            error={errors?.ordre?.message}
          />
        </div>
        <div className="col-md-12">
          <div className="mb-4">
            <SelectInput
              label="Type"
              options={TypeReponseOption}
              className="form-control-modal-custom"
              //   {...register("type")}
              value={type}
              onChange={(e) => onSelectType(e)}
              error={errors?.type?.message}
            />
          </div>
        </div>
        {!!from && handelImageChange && (
          <div className="col-md-12 text-start mb-3">
            <Input
              type="file"
              label="Image"
              id={`imageddd`}
              onChange={(e) => handelImageChange(e)}
              accept="image/*"
              name="background_image"
              error={errors?.background_image?.message || ""}
            />
            {preview && (
              <div className="img-preview-container">
                <img src={preview} className="type-img-preview" />
              </div>
            )}
            {/* {errors.contenu && (
              <ErrorMessage message={errors.contenu?.message} />
            )} */}
          </div>
        )}
        <div className="col-md-12 text-start mb-3">
          <label
            htmlFor="content"
            className="form-label form-label-modal-custom"
          >
            Legend
          </label>
          <ReactQuill
            className="editor-cgu custom-textarea"
            value={description}
            onChange={(value) => handleChange(value)}
          />
          {errors.legend && <ErrorMessage message={errors.legend?.message} />}
        </div>
        {(type === "choice" ||
          type === "checkbox" ||
          type === "evaluation") && (
          <>
            {fields?.map((el, i) => (
              <div className="col-md-12" key={el?.id}>
                <div className="mb-4 d-flex align-items-center gap-2 w-100">
                  <Input
                    type="text"
                    label={`Réponse ${i + 1}`}
                    id="reponse"
                    placeholder="Ajouter une réponse"
                    {...register(`responses.${i}.contenu`)}
                    error={
                      (errors?.responses?.length &&
                        errors?.responses[i]?.contenu &&
                        errors?.responses[i]?.contenu?.message) ||
                      ""
                    }
                  />
                  {i > 0 && (
                    <button
                      type="button"
                      className="btn btn-action-admin with-tooltip"
                      data-tooltip-content="Supprimer"
                      onClick={(e: any) => {
                        e.preventDefault();
                        const slug = item?.responses[i]?.slug;
                        removeField(i, slug);
                        // document
                        //   .getElementById("editAutoDiagnosticModal")
                        //   ?.dispatchEvent(new Event("click"));
                        // window.location.pathname = "/admin/contenus";
                      }}
                    >
                      <FaTrash />
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <div className="mb-4">
                <button
                  className="btn custom-btn-add-more"
                  type="submit"
                  onClick={(e: any) => {
                    e.preventDefault();
                    addFields();
                  }}
                >
                  <BsPlusSquare className="workcare-dash-admin-btn-add-more-icone" />{" "}
                  Ajouter une réponse
                </button>
              </div>
            </div>
          </>
        )}
        <div className="col-md-12">
          <div className="mb-3 flex-r gap-3">
            <BtnAnnulerModal label={"Annuler"} isLoading={false} />
            <BtnSubmit
              label={`${item ? "Modifier" : "Terminer"}`}
              isLoading={isLoading}
              style={{ padding: "5px 10px", fontSize: 14 }}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default FormContent;
