import React from 'react'
import { BtnSubmit } from '../../common/Button'
import { InputIcon } from '../../common/Input'
import Avatars from '../../../assets/appImages/Avatars.png'
import { Authorisations } from '../../../utils/api/constant'
import { useNavigate } from 'react-router-dom'
import { getAvatar, getName, useLocationState } from '../../../utils/Utils'
import UseCrudAdmin from './useForm/UseCrudAdmin'
import { IAdmin } from '../../../utils/api/admin/admin.type'
import { BiArrowBack } from 'react-icons/bi'

function DetailsAdministrateur() {
  const navigate = useNavigate();
  const user = useLocationState<IAdmin>(undefined);
  const { register, onSubmit, errors, isLoading } = UseCrudAdmin("", undefined, user);
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="workcare-dash-admin-titre-container">
            <h4 className="workcare-dash-admin-titre-deux">
            <BiArrowBack onClick={() => navigate(-1)} className="workcares-icon-back" />
            Détails de l’’administrateur
            </h4>
          </div>
          <div className="workcares-dash-admin-profil-main-page-container ps-0 py-3">
            <div className="container-profil-user-admin py-4 text-center position-relative">
              <div className="container-img-profil-user mb-4">
                <img
                  src={getAvatar(user?.avatar)}
                  alt="Profil"
                  className="img-profil-user-admin"
                />
              </div>
              <div className="content-text-name-user">
                <h2 className="name-user-admin">{getName(user)}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <form action="" onSubmit={onSubmit}>
                <div className="row">
                  <p className="custom-titre-details-parametre">
                    Informations personnelles
                  </p>
                  <div className="row d-flex align-items-center  mb-5">
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Prénom"
                        id="prenom"
                        placeholder="Prénom"
                        {...register("prenom")}
                        error={errors?.prenom}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Nom"
                        id="nom"
                        placeholder="Nom"
                        {...register("nom")}
                        error={errors?.nom}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputIcon
                        type="email"
                        label="Adresse mail"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Adresse"
                        id="adresse"
                        placeholder="Adresse"
                        {...register("email")}
                        error={errors?.email}
                      />
                    </div>
                    {/* <div className="col-md-6">
                      <InputIcon
                        type="password"
                        label="Mot de passe"
                        id="password"
                        placeholder="********"
                      />
                    </div> */}
                    <div className="col-md-6">
                      <InputIcon
                        type="text"
                        label="Numéro de téléphone"
                        id="phone"
                        placeholder="Téléphone"
                        {...register("telephone")}
                        error={errors?.telephone}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="custom-titre-details-parametre">
                            Permissions
                          </p>
                          <div className="container-checkbox-input">
                            <div className="form-checkbox-input checkbox-input">
                              <div className="custom-permission-container">
                                {Authorisations?.map((item) => (
                                  <label key={item?.field}>
                                    <input
                                      type="checkbox"
                                      {...register(item?.field)}
                                      id={`checkbox-${item?.field}`}
                                    />
                                    <span>{item?.label}</span>
                                  </label>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                <div className="btn-container flex-r mt-4">
                  <BtnSubmit label="ENREGISTRER" isLoading={isLoading} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsAdministrateur