import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsTrashFill } from "react-icons/bs";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddContactMutation, useDeleteContactMutation } from "../../../../utils/api/contact/contact.api";
import { IContact, TContactForm } from "../../../../utils/api/contact/contact.type";
import { useDelete } from "../../../../utils/helpers";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

const useContactForm = () => {
	const validationSchema = yup.object().shape({
		name: yup.string().label("Prenom"),
		email: yup.string().email().required().label("Email"),
		message: yup.string().required().label("Le message"),
		phoen: yup.string().label("Téléphone"),
		// phone: yup.string().required().label("Téléphone"),
		// user_type: yup.string().required().label("Qui est vous"),

	});
	// const { user: authUser } = useAppSelector((s) => s.user?.user);

	const [sendMessage, { isLoading, isError, isSuccess, error }] = useAddContactMutation();
	 const [phone, setPhone] = useState<string>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TContactForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succés!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				reset();
				setPhone("221")
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	const onsubmit = (data: TContactForm) => {
		// console.log(data);
		sendMessage(data);
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		// authUser,
		phone,
		setPhone
	};
};

export function DeleteContact({ item }: { item: IContact }) {
    const [deleteItem, { isSuccess, isError, error }] =
      useDeleteContactMutation();
    const onDelete = useDelete<IContact>({
      item,
      deleteItem,
      isSuccess,
      isError,
      error,
      question: "ce message",
      successMessage: "Message supprimé",
    });
    return (
      <button
      className="btn with-tooltip btn-action-icon-delete btn-supprimer-contact"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <BsTrashFill />
    </button>
    );
  }

export default useContactForm;
