import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { INotification } from "../notification/notification.type";
import { prepareHeaders } from "../user/user.api";
import { NotificationFormData } from "./notification.type";
import {
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
} from "../user/user.type";

export const NotificationApi = createApi({
  tagTypes: ["user_notifications"],
  reducerPath: "NotificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    updateReadNotification: builder.mutation<
      INotification,
      NotificationFormData
    >({
      query: ({ slug, ...data }) => ({
        url: `notifications/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user_notifications"],
    }),
    getNotificationByUser: builder.query<
      PaginationResults<INotification>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`user/${slug}/notifications/`, query),
      providesTags: ["user_notifications"],
    }),
  }),
});

export const {
  useGetNotificationByUserQuery,
  useUpdateReadNotificationMutation,
} = NotificationApi;
