import React from "react"
import { BiArrowBack } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import BitmapAvatar from "../../../assets/appImages/BitmapAvatar.png"
import { useAppSelector } from "../../../redux/hooks"
import { IUser } from "../../../utils/api/user/user.type"
import { getAvatar, getName, useLocationState } from "../../../utils/Utils"
import ModifierInfosPersoModal from "../MonCompte/Modals/ModifierInfosPersoModal"

function DetailsPraticien() {
  const user = useLocationState<IUser>(undefined)
  const navigate = useNavigate()
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="workcare-dash-admin-titre-container">
            <h4 className="workcare-dash-admin-titre-deux">
              <BiArrowBack
                onClick={() => navigate(-1)}
                className="workcares-icon-back"
              />
              Détails du praticien
            </h4>
          </div>
          <div className="workcares-dash-admin-profil-main-page-container ps-0 py-3">
            <div className="container-profil-user-admin py-4 text-center position-relative">
              <div className="container-img-profil-user mb-4">
                <img
                  src={getAvatar(user?.avatar)}
                  alt="Profil"
                  className="custom-img-profil-user-admin"
                />
              </div>
              <div className="content-text-name-user">
                <h2 className="custom-name-user-admin">{getName(user)}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire">Bio</h5>
              <div className="workcare-dash-admin-section-bio-para-container">
                <p className="workcare-dash-admin-section-bio-para">
                  {user?.bio !== undefined &&
                  user?.bio !== null &&
                  user?.bio !== "null"
                    ? user?.bio
                    : "NON RENSEIGNÉ"}
                </p>
                {/* <button className="btn workcare-dash-admin-section-bio-btn-edit">Edit</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <div>
                <div className="workcare-dash-admin-section-edit-profil-container">
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Prénom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.prenom !== undefined &&
                        user?.prenom !== null &&
                        user?.prenom !== "null"
                          ? user?.prenom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Nom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.nom !== undefined &&
                        user?.nom !== null &&
                        user?.nom !== "null"
                          ? user?.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Adresse
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.adresse !== undefined &&
                        user?.adresse !== null &&
                        user?.adresse !== "null"
                          ? user?.adresse
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Adresse mail
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.email !== undefined &&
                        user?.email !== null &&
                        user?.email !== "null"
                          ? user?.email
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Spécialité
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.specialite
                          ? user?.specialite?.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Expérience
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.experience !== undefined &&
                        user?.experience !== null &&
                        user?.experience !== "null"
                          ? user?.experience
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* <div className='workcare-dash-admin-section-edit-profil-item'>
                        <div className='workcare-dash-admin-section-edit-profil-prenom'>
                            <div className='workcare-dash-admin-section-edit-profil-prenom-item'>Spécialité</div>
                        </div>
                        <div className='workcare-dash-admin-section-edit-profil-btn-container'>
                            <div className='workcare-dash-admin-section-edit-profil-btn-item'>Ostéopathie viscérale, Ostéopathie du sport</div>
                            <button 
                            className="btn workcare-dash-admin-section-bio-btn-edit"
                            data-bs-toggle="modal"
                            data-bs-target="#changeInfosPerso"
                            >Edit</button>
                        </div>
                    </div>
                    <hr /> */}
                </div>
                <div
                  className="modal fade"
                  id="changeInfosPerso"
                  aria-labelledby="changeInfosPersoLabel"
                  aria-hidden="true"
                >
                  <ModifierInfosPersoModal
                    modaleId="changeInfosPerso"
                    hide={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsPraticien
