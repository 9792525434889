import React from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import BreadCrumb from "../../shared/BreadCrumb";
import AddObjectifAction from "../../TableauDeBord/RH/ObjectifsActions/ObjectifActionModal/ObjectifsActionsModal";
import "./ListeObjectis.css";
import ObjectifsActionsTables from "./ObjectifsActionsTables/ObjectifsActionsTables";

function ListeObjectifs() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb
            pageName="Objectifs et actions"
            title="Objectifs & actions"
          />
        </div>
        <button 
          className="workcare-dash-admin-btn-ajout-deux"
          data-bs-toggle="modal"
          data-bs-target="#AddObjectifAction"
        >
          <AiOutlinePlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
          Ajouter
        </button>
        <div
          className="modal fade"
          id="AddObjectifAction"
          aria-labelledby="AddObjectifActionLabel"
          aria-hidden="true"
        >
          <AddObjectifAction modalId="AddObjectifAction" />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <ObjectifsActionsTables />
      </div>
    </div>
  );
}

export default ListeObjectifs;
