import React from "react";
import { createMarkup } from "../../../../utils/Utils";
import { BtnCloseModal } from "../../../common/Button";
import { ObjectifType } from "../../../../utils/api/objectif/objectif.types";

function VoirObjectifModal({
  objectif,
  modalId,
}: {
  objectif?: ObjectifType;
  modalId: string;
}) {
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {"Détails Objectif & Action "}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <div className="row">
              <div className="col-md-12">
                {!!objectif?.rh?.id && (
                  <div className="detail-contenu-infos-container mb-3">
                    <div className="detail-contenu-infos-name">Entreprise:</div>
                    <div className="detail-contenu-infos-value">
                      {objectif?.rh?.nom_entreprise ||
                        objectif?.rh?.prenom + " " + objectif?.rh?.nom}
                    </div>
                  </div>
                )}
                <div className="detail-contenu-infos-container mb-3">
                  <div className="detail-contenu-infos-name">Objectif:</div>
                  <div className="detail-contenu-infos-value">
                    {objectif?.titre}
                  </div>
                </div>

                <div className="detail-contenu-infos-container mb-3 gap-2 row text-start">
                  <div className="detail-contenu-infos-name col-md-12">
                    Action:
                  </div>
                  <div
                    className="detail-contenu-infos-value"
                    dangerouslySetInnerHTML={createMarkup(objectif?.contenu)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoirObjectifModal;
