import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { DeleteProfilData } from '../../../../utils/api/auth/auth.type'
import { useDeleteProfilMutation } from "../../../../utils/api/user/user.api";
import { Color } from '../../../../utils/theme'
import { cleannerError, onHide } from '../../../../utils/Utils'
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { onlogout } from "../../../../redux/slice/User.slice";


function UseDeleteProfil(slug: string) {
	const dispatch = useAppDispatch();
	const validationSchema = yup.object().shape({
		password: yup.string().required().label("Mot de passe"),
	});
    
    const {
        register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
      } = useForm<DeleteProfilData>({
        resolver: yupResolver(validationSchema),
      })
      const [deleteProfil , { isLoading, isSuccess, error, isError, data }] =
      useDeleteProfilMutation();

	const passwordHandleReset = () => {
		reset();
	  };
    
      useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Votre profil est supprimé avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
                reset();
				onHide("deleteProfileModale");
				dispatch(onlogout());
			});
		}
		const err = error as any;
		if (isError) {
			console.log('err',error)
			Swal.fire({
				icon: "error",
				title: err?.data[0]
					? err?.data[0]
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: DeleteProfilData) => {

		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer votre profil  ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				deleteProfil({ slug: slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log('result',result)
			if(!result?.isConfirmed)
			{
				onHide("deleteProfileModale");
			}
		});
		
	};
      
      return {
        register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		passwordHandleReset
      }
}

export default UseDeleteProfil
