import { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../utils/api/auth/auth.api";
import { Color } from "../../../utils/theme";
import { cleannerError, validatePhone } from "../../../utils/Utils";
import { useGetSpecialitesByVisitorQuery } from "../../../utils/api/specialite/specialite.api";

export function validatePassword(Yup: any) {
  return Yup.string()
    .required()
    .matches(/[a-z]+/, "Au moins une minuscule.")
    .matches(/[A-Z]+/, "Au moins une majuscule.")
    .matches(/[!@#$%^&*(),;-_+*/.?":{}|<>]+/, "Au moins un caractère spécial")
    .matches(/\d+/, "Au moins un chiffre.")
    .min(8)
    .label("Mot de passe");
}

export type RegisterStepPropsType = {
  navigation: any;
};

function useRegisterForm() {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prénom"),
    civilite: yup.string().label("Genre"),
    email: yup.string().email().required().label("Email"),
    telephone: validatePhone(yup, code).required(),
    user_type: yup.string().required().label("Type d'utilisateur"),
    username: yup.string().label("UserName"),
    nom_entreprise: yup.string().label("Nom entreprise"),
    password: validatePassword(yup),
    avatar: yup.mixed().nullable().label("Photo"),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passe ne correspond pas"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const [registerUser, { isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();
  const { data: specialites = [] } = useGetSpecialitesByVisitorQuery();


  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Inscription reussie!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/", { replace: true });
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : "Une erreur est survenue lors de l'inscription",
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const [previewAvatar, setPreviewAvatar] = useState("");

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, item);
    } else {
      setValue(`telephone`, "");
    }
  };
  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("avatar", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewAvatar(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  const onSubmit = (data: any) => {
    if (data?.email) {
      data["email"] = (data?.email).toLowerCase();
    }
    if (data?.username) {
      data["username"] = (data?.username).toLowerCase();
    }
    if (data?.user_type === "rh") {
      if (!data?.nom_entreprise ) {
        return (
          setError("nom_entreprise", {
            message: "Le nom de l'entreprise est obligatoire",
          })
        )
      }
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    console.log("data submitted", data);
    registerUser(fd);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handelAvatarChange,
    previewAvatar,
    phone,
    handleChangePhone,
    specialites,
  };
}

export default useRegisterForm;
