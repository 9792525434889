import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { useGetNotificationByUserQuery } from "../../utils/api/notification/notification.api";
import { createMarkup } from "../../utils/Utils";
import { AlertInfo } from "../common/Alert";
import { CustomPagination } from "../common/CustomPagination";
import TableSkeleton from "../common/TableSkeleton";

function ListeNotificationTable() {
  const { user } = useAppSelector((s) => s?.user);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetNotificationByUserQuery({
      slug: user?.slug,
      page: page,
      limit: perPage,
    });
  console.log("notif", data);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={""}
          className="btn btn-action-admin with-tooltip no-link"
          data-tooltip-content="Voir"
          state={row}
        >
          <AiFillEye />
        </NavLink>
      </div>
    );
  };
  const notificationFormatter = (cell: any, row: any) => {
    return (
      <p
        className="text-notif mb-1"
        dangerouslySetInnerHTML={createMarkup(row?.content)}
      />
    );
  };
  const dateFormatter = (cell: any, row: any) => {
    return (
      <p className="text-date-notif mb-0">
        {moment(row?.created_at).format("DD/MM/yy - HH:mm")}
      </p>
    );
  };

  const columns = [
    {
      dataField: "content",
      text: "Notifications",
      formatter: (cell: any, row: any) => notificationFormatter(cell, row),
    },
    {
      dataField: "created_at",
      text: "Date de création",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },

    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndicator={<AlertInfo message="Pas de notifications" />}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListeNotificationTable;
