import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  EvenementFormData,
  EvenType,
  IEvenement,
} from "../../../../utils/api/evenement/evenement.type";
import {
  useAddEvenementMutation,
  useDeleteEvenementMutation,
} from "../../../../utils/api/evenement/evenement.api";
import { cleannerError, onHide } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useGetRhQuery } from "../../../../utils/api/user/user.api";
import { useAppSelector } from "../../../../redux/hooks";
import moment from "moment";

function UseCrudActivite(modalId: string, evenement?: IEvenement) {
  const validationSchema = yup.object().shape({
    date: yup.string().required().label("Date"),
    heure: yup.string().required().label("Heure"),
    description: yup.string().nullable().label("Description"),
    animateur: yup.string().nullable().label("Animateur"),
    type: yup.string().nullable().label("Type"),
    type_activite: yup.string().nullable().label("Type activite"),
    lien: yup.string().nullable().label("Lien"),
    lieu: yup.string().nullable().label("Lieu"),
    en_ligne: yup.boolean().nullable().label("En ligne"),
    activite: yup.string().nullable().label("Activite"),
    praticien: yup.string().nullable().label("Praticien"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<EvenementFormData | any>({
    resolver: yupResolver(validationSchema),
  });

  const [options, setOptions] = useState<any>([]);
  const [AddOrUpdateEvenement, { isLoading, isSuccess, error, isError, data }] =
    useAddEvenementMutation();
  const [word, setWord] = useState("");
  const { user } = useAppSelector((s) => s.user);
  const { data: listRh = { results: [] } } = useGetRhQuery({ word });

  const [description, setDescription] = React.useState<any>("");
  const [entreprise, setEntreprise] = useState<
    { label?: string; value?: number } | undefined
  >();

  useEffect(() => {
    if (listRh?.results?.length) {
      setOptions(
        listRh?.results?.map((el) => {
          return {
            label: el?.nom_entreprise || el?.prenom + " " + el?.nom,
            value: el?.id,
          };
        })
      );
    } else {
      setOptions([])
    }
  }, [listRh]);

  const handleInputChange = (val) => {
    setWord(val);
  };
  const onChangeEntreprise = (selected: any) => {
    setEntreprise(selected);
    setValue("entreprise", selected?.value);
  };

  const handleChange = (value: any) => {
    setDescription(value);
    setValue("description", value);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Evènement ${evenement ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!evenement) {
          reset();
          setDescription("");
          setEntreprise(undefined);
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: EvenementFormData) => {
    data["type"] = EvenType?.activite;
    // data["praticien"] = user?.user_type === "praticien" ? user?.id : null;
    console.log("data sub", data);
    AddOrUpdateEvenement({ slug: evenement?.slug, data });
  };

  useEffect(() => {
    if (evenement?.id) {
      console.log("here");
      let fields: (keyof IEvenement)[] = [
        "slug",
        "date",
        "heure",
        "description",
        "animateur",
        "type",
        "type_activite",
        "lien",
        "lieu",
        "en_ligne",
        "activite",
        "entreprise",
        "praticien",
      ];

      for (let field of fields) {
        register(field);
        setValue(field, evenement[field]);

        if (field == "praticien") {
          setValue("praticien", evenement?.praticien?.id);
        }
        if (field == "type") {
          setValue("type", evenement?.type);
        }

        if (field === "entreprise") {
          setValue("entreprise", evenement?.entreprise?.id);
          const getEntreprise = listRh?.results?.find(
            (item) => item?.id === evenement?.entreprise?.id
          );
          const entreprises = {
            label:
              getEntreprise?.nom_entreprise ||
              getEntreprise?.prenom + " " + getEntreprise?.nom,
            value: getEntreprise?.id,
          };
          setEntreprise(entreprises);
        }
      }
    }
  }, [evenement]);
  //  console.log('error', errors)
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    control,
    Controller,
    onChangeEntreprise,
    entreprise,
    setEntreprise,
    description,
    setDescription,
    handleChange,
    options,
    handleInputChange
  };
}

export function DeleteEvenement(slug: string, modalid: string) {
  const [deleteData] = useDeleteEvenementMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet évènement ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Evenement supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          onHide(modalid);
        });
      }
    });
  };
  return onDelete;
}

export default UseCrudActivite;
