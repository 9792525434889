import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IUser, PaginationResults, TypeQuery } from "../user/user.type";
import { IAdmin, IAdminFormData } from "./admin.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../Utils";

export const AdminApi = createApi({
	reducerPath: "adminApi",
	tagTypes: ["admin", "adminList", "userList", "admin_dashboard", "admin_recette", "admin_proprietaire_data"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		meAdmin: builder.query<IAdmin, void>({
			query: () => "meadmin/",
		}),
		adminsList: builder.query<PaginationResults<IAdmin>, TypeQuery>({
			query: (query) => QueryUrl("user/admins/", query),
			providesTags: ["adminList"],
		}),
		addOrUpdateAdmin: builder.mutation<
			IAdmin,
			{
				slug?: string;
				user_type?: "admin" | "superadmin";
				data: IAdminFormData | FormData;
			}
		>({
			invalidatesTags: ["admin", "adminList"],
			query: ({ slug, user_type, data }) => {
				if (slug) {
					if (user_type && user_type === "admin") {
						return {
							url: `user/admins/${slug}/`,
							method: "PUT",
							body: data,
						};
					} else {
						return {
							url: `users/${slug}/`,
							method: "PUT",
							body: data,
						};
					}
				}
				return {
					url: `user/admins/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editAdmin: builder.mutation<
			IAdmin,
			{
				slug?: string;
				user_type?: "admin" | "superadmin";
				data: IAdminFormData | FormData | any;
			}
		>({
			invalidatesTags: ["admin", "adminList"],
			query: ({ slug, data }) => {
				return {
					url: `user/admins/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteAdmin: builder.mutation<IAdmin, string>({
			query: (slug) => ({
				url: `user/admins/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["admin", "adminList"],
		}),
		getUserByAdmin: builder.query<IUser[], TypeQuery>({
			query: (query) => QueryUrl("admin/users/", query),
			providesTags: ["userList"],
		}),
	}),
});

export const {
    useAddOrUpdateAdminMutation,
	useAdminsListQuery,
	useEditAdminMutation,
	useLazyAdminsListQuery,
	useLazyMeAdminQuery,
	useMeAdminQuery,
	useDeleteAdminMutation,
	useGetUserByAdminQuery,
} = AdminApi