import React from 'react'
import QuestionEvaluationTable from '../QuestionsTable/QuestionEvaluationTable'

function QuestionEvaluation() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <QuestionEvaluationTable />
    </div>
  )
}

export default QuestionEvaluation