import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import "./Agenda.css";
import Calendar from "./Calendar";

function Agenda() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Agenda" title="Agenda" />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container p-0">
        <Calendar />
      </div>
    </div>
  );
}

export default Agenda;
