import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetDisponibiliteByPraticienQuery } from "../../../../utils/api/disponibilite/disponibilite.api";
import {
  useGetEvenementsByPraticienEntrepriseQuery,
  useGetEvenementsEntrepriseQuery,
  useGetEvenementsQuery,
} from "../../../../utils/api/evenement/evenement.api";
import { getName } from "../../../../utils/Utils";

function UseCalendar() {
  const user = useAppSelector((s) => s?.user?.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const entreprise = searchParams.get("entreprise");
  const categorie = searchParams.get("categorie");
  const word = searchParams.get("q");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      // console.log("q", e.currentTarget.value);
      setSearchParams({
        q: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const handleFilterByEntreprise = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    console.log("entreprise", e.currentTarget.value);
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (e.currentTarget.checked) {
      updatedSearchParams.set("entreprise", e.currentTarget.value);
    } else {
      updatedSearchParams.delete("entreprise");
    }
    setSearchParams(updatedSearchParams);
  };

  const handleFilterByCategorie = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (e.currentTarget.checked) {
      updatedSearchParams.set("categorie", e.currentTarget.value);
    } else {
      updatedSearchParams.delete("categorie");
    }
    setSearchParams(updatedSearchParams);
  };

  const { data, isLoading } = useGetEvenementsQuery({
    entreprise: entreprise,
    categorie: categorie,
  });
  const { data: Entreprise } = useGetEvenementsEntrepriseQuery({
    q: word,
  });
  const { data: EntreprisePraticien } =
    useGetEvenementsByPraticienEntrepriseQuery({
      slug: user?.slug,
      q: word,
    });
  const { data: dispoPraticien } = useGetDisponibiliteByPraticienQuery({
    slug: user?.slug,
    entreprise: entreprise,
  });

  const [events, setEvents] = useState<any>([]);
  const [evenement, setEvenement] = useState<any>({});

  const formatEvent = useMemo(() => {
    return (event) => {
      // let duration: any = 0;
      // if (user?.user_type === "praticien" && event?.frequence) {
      // }
      // duration = moment.duration(
      //   moment(`${event.date} ${event.heure_fermeture}`).diff(
      //     moment(`${event.date} ${event.heure_ouverture}`)
      //   )
      // );
      // var hours = duration?.asHours();
     
      // if (event?.frequence) {
      //   return {
      //     title:
      //       user?.user_type === "praticien"
      //         ? "Ent." + " " + getName(event.entreprise)
      //         : event.type,
      //     color:
      //       event.type == "activite"
      //         ? "rgba(240, 183, 67, 0.12)"
      //         : event.type == "atelier"
      //         ? "rgba(40, 199, 111, 0.12)"
      //         : "rgba(171, 199, 251, 0.2)",
      //     textColor:
      //       event.type == "activite"
      //         ? "#F0B743"
      //         : event.type == "atelier"
      //         ? "#4BD58A"
      //         : "#0E0E0E",
      //     extendedProps: event,
      //     rrule: {
      //       dtstart: moment(
      //         user?.user_type === "praticien"
      //           ? `${event.date} ${event.heure_ouverture}`
      //           : `${event.date} ${event.heure}`,
      //         "YYYY-MM-DD HH:mm"
      //       ).format("YYYY-MM-DDTHH:mm:ss"),
      //       freq: event?.frequence === "semaine" ? "weekly" : event?.frequence === "mois" ? "monthly" : "yearly",
      //     },
      //     duration: hours > 0 ? `${hours}:00` : '00:00',
      //   };
      // }
      return {
        title:
          user?.user_type === "praticien"
            ? "Ent." + " " + getName(event.entreprise)
            : event.type,
        start: moment(
          user?.user_type === "praticien"
            ? `${event.date} ${event.heure_ouverture}`
            : `${event.date} ${event.heure}`,
          "YYYY-MM-DD HH:mm"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(
          user?.user_type === "praticien"
            ? `${event.date} ${event.heure_fermeture}`
            : null,
          "YYYY-MM-DD HH:mm"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        color:
          event.type == "activite"
            ? "rgba(240, 183, 67, 0.12)"
            : event.type == "atelier"
            ? "rgba(40, 199, 111, 0.12)"
            : "rgba(171, 199, 251, 0.2)",
        textColor:
          event.type == "activite"
            ? "#F0B743"
            : event.type == "atelier"
            ? "#4BD58A"
            : "#0E0E0E",
        extendedProps: event,
      };
    };
  }, [data]);

  useEffect(() => {
    setEvents(
      user?.user_type === "praticien"
        ? dispoPraticien?.map(formatEvent)
        : data?.map(formatEvent)
    );
  }, [user, data, dispoPraticien, searchParams]);

  return {
    user,
    events,
    handleFilterByName,
    handleFilterByEntreprise,
    handleFilterByCategorie,
    Entreprise,
    entreprise,
    categorie,
    EntreprisePraticien:
      user?.user_type === "praticien" ? EntreprisePraticien : Entreprise,
    evenement,
    setEvenement,
  };
}

export default UseCalendar;
