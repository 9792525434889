import React from "react";
import Calendrier from "../../../admin/Agenda/Calendar";
import BreadCrumb from "../../../shared/BreadCrumb";

function AgendaPraticien() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Agenda" title="Agenda" />
        </div>

        {/* <button
          className="btn agenda-praticien-btn-ajout-disponibilite"
          data-bs-toggle="modal"
          data-bs-target="#GererDisponibiliteModal"
        >
          Ajouter une disponibilité
        </button> */}

        {/* <div
          className="modal fade"
          id="GererDisponibiliteModal"
          aria-labelledby="GererDisponibiliteModalLabel"
          aria-hidden="true"
        >
          <GererDisponibiliteModal modalId="GererDisponibiliteModal" />
        </div> */}
      </div>
      <div className="workcare-dash-admin-page-content-container p-0">
        <Calendrier />
      </div>
    </div>
  );
}

export default AgendaPraticien;
