import React from 'react'
import EvalBienEtreTable from '../ListeContenusTables/EvalBienEtreTable'

function EvaluationBienEtre() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <EvalBienEtreTable />
    </div>
  )
}

export default EvaluationBienEtre