import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IUser,
  UserFormData,
  UserType,
} from "../../../../utils/api/user/user.type";
import {
  useAddOrEditUserMutation,
  useGetRhQuery,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { cleannerError, onHide } from "../../../../utils/Utils";

function UseLinkPraticienRh(
  modalId: string,
  user?: IUser,
  isFromDetail?: boolean
) {
  const validationSchema = yup.object().shape({
    entreprise: yup.array().of(yup.number()).label("Entreprise").required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditUserMutation();

  const navigate = useNavigate();

  const { data: listRh } = useGetRhQuery({});
  const [entreprises, setEntreprises] = useState<
    { label: string; value: number }[] | undefined
  >();
  const onChangeEntreprise = (selected: any) => {
    console.log("selected", selected);
    setEntreprises(selected);
    setValue(
      "entreprise",
      selected?.map((item: any) => item?.value)
    );
  };


  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Entreprises assignées avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        isFromDetail && navigate(`/admin/utilisateurs`);
        onHide(modalId);
        if (!user) {
          reset();
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
    // }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      if (user?.entreprise?.length) {
        setValue(
          "entreprise",
          user?.entreprise?.map((item: any) => item?.id)
        );

        setEntreprises(
          user?.entreprise?.map((item) => {
            return {
              label: item?.nom_entreprise,
              value: item?.id,
            };
          })
        );
      }
    }
  }, [user]);

  const handleReset = () => {
    if (!user) {
      reset();
    }
  };

  const onSubmit = (data: UserFormData) => {
    const { slug, avatar,specialite, ...userData } = user || {};
    const mergedData = { ...userData, ...data };
    // mergedData.specialite = userData?.specialite?.id
    // console.log("data sub", data);
    addOrUpdateUser({ slug: user?.slug, data: mergedData });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    user,
    reset,
    handleReset,
    control,
    ListeRh: listRh?.results,
    onChangeEntreprise,
    entreprises,
  };
}

export default UseLinkPraticienRh;
