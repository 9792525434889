import React, { useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { Authorisations } from "../../../../utils/api/constant";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import UseCrudAdmin from "../useForm/UseCrudAdmin";

function AddOrUpdateAdministrateur({
  condition,
  modalId,
  hide
}: {
  condition?: any;
  modalId: string;
  hide?: boolean;
}) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    phone,
    handleChangePhone,
  } = UseCrudAdmin(modalId, hide);

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter un admin
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Prénom"
                    id="prenom"
                    required
                    placeholder="Prénom"
                    {...register("prenom")}
                    error={errors?.prenom}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    required
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors?.nom}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Adresse email"
                    id="email"
                    placeholder="Email"
                    required
                    {...register("email")}
                    error={errors?.email}
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="phone"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="phone"
                    >
                      Numéro de téléphone
                      <span
                        ref={ref}
                        className="text-danger"
                        style={{ fontSize: 24 }}
                      >
                        *
                      </span>
                    </label>
                    <PhoneInput
                      
                      containerClass="form-control form-control-modal-custom mb-3 p-0"
                      inputClass="form-control form-control-modal-custom custom-padding"
                      country={"fr"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any,e,val) => {
                        console.log(val)
                        handleChangePhone(phone, country);
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="mb-3">
                    <p className="custom-titre-details-parametre">
                      Permissions
                    </p>
                    <div className="container-checkbox-input">
                      <div className="form-checkbox-input checkbox-input">
                        <div className="custom-permission-container">
                          {Authorisations?.map((item) => (
                            <label key={item?.field}>
                              <input
                                type="checkbox"
                                {...register(item?.field)}
                                id={`checkbox-${item?.field}`}
                              />
                              <span>{item?.label}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    <BtnSubmit
                      label={condition ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateAdministrateur;
