import React from 'react'
import AutoDiagnostiqueTable from '../ListeContenusTables/AutoDiagnostiqueTable'

function AutoDiagnostique() {
  return (
    <div className="workcare-dash-admin-page-content-container">
        <AutoDiagnostiqueTable />
    </div>
  )
}

export default AutoDiagnostique