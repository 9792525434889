import React from "react";
import { BiUserPlus } from "react-icons/bi";
import BreadCrumb from "../../shared/BreadCrumb";
import "./ListeUtilisateurs.css";
import AddOrUpdateUtilisateurModal from "./Modals/AddOrUpdateUtilisateurModal";
import ListeUtilisateursTable from "./UtilisateursTables/ListeUtilisateursTable";

function ListeUtilisateurs() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Utilisateurs" title="Utilisateurs" />
        </div>
        <button
          className="workcare-dash-admin-btn-ajout"
          data-bs-toggle="modal"
          data-bs-target="#AddOrUpdateUtulisateur"
        >
          <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" /> Ajouter
        </button>

        <div
          className="modal fade"
          id="AddOrUpdateUtulisateur"
          aria-labelledby="AddOrUpdateUtulisateurLabel"
          aria-hidden="true"
        >
          <AddOrUpdateUtilisateurModal modalId="AddOrUpdateUtulisateur" />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <ListeUtilisateursTable />
      </div>
    </div>
  );
}

export default ListeUtilisateurs;
