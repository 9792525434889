import React, { useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import { AiFillEye } from "react-icons/ai";
import { useGetQuestionsDiagnosticQuery } from "../../../../utils/api/questions/question.api";
import { NavLink } from "react-router-dom";
import { AlertInfo } from "../../../common/Alert";

function QuestionDiagnostiqueTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = [], isLoading } = useGetQuestionsDiagnosticQuery({});

  // console.log("questions", data)
  // console.log(
  //   "data diag",
  //   data?.filter((data) => data?.type === "text_field")
  // );

  const questionFormatter = (cell: any, row: any) => {
    return (
      <p className="text-question-custom mb-0">
        {" "}
        <span>{row?.contenu}</span>
      </p>
    );
  };

  const typeFormatter = (cell: any, row: any) => {
    if (cell === "choice") return "Choix multiple";
    if (cell === "select") return "Sensation";
    if (cell === "text_field") return "Champ libre";
    if (cell === "checkbox") return "Choix unique";
    if (cell === "message") return "Message de succès";
    if (cell === "evaluation") return " Echelle de 1 à 10";
  };

  const reponseFormatter = (cell: any, row: any) => {
    return (
      <p className="text-question-custom mb-0">
        {" "}
        {!!row?.responses?.length && row?.responses[0]?.contenu + "..."}
      </p>
    );
  };

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <NavLink
            to={`/admin/question-diagnostique/${row?.slug}`}
            state={row}
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Voir"
          >
            <AiFillEye />
          </NavLink>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "ordre",
      text: "Ordre",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    // {
    //   dataField: "type",
    //   formatter: (cell: any, row: any) => typeFormatter(cell, row),
    //   text: "TYPE",
    // },
    {
      dataField: "contenu",
      text: "QUESTION",
      style: { textAlign: "left" },
      // headerStyle: () => {
      //   return { width: "200px", whiteSpace: "normal" };
      // },
      formatter: (cell: any, row: any) => questionFormatter(cell, row),
    },
    // {
    //   dataField: "legende",
    //   text: "LEGENDE",
    //   headerStyle: () => {
    //     return { whiteSpace: "normal" };
    //   },
    //   formatter: (cell) => (
    //     <p
    //       className="text-question-custom"
    //       dangerouslySetInnerHTML={createMarkup(cell)}
    //     />
    //   ),
    // },
    {
      dataField: "slug",
      text: "Verbatims",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.filter((data) => data?.type === "text_field")}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            pagination={paginationFactory()}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          {/* <div className="custom-pagination-container">
            <CustomPagination
              nbPages={
                data?.filter((data) => data?.type === "text_field")?.length
              }
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div> */}
        </>
      )}
    </>
  );
}

export default QuestionDiagnostiqueTable;
