import React, { useState } from "react"
import "./Diagnostique.css"
import { useStep } from "react-hooks-helper"
import { useStepType } from "../../../../../utils/type"
import InfosPersonnelles from "./DiagnostiqueTabs/InfosPersonnelles"
import AutoDiagnostique from "./DiagnostiqueTabs/AutoDiagnostique"
import CompteRendu from "./DiagnostiqueTabs/CompteRendu"
import Document from "./DiagnostiqueTabs/Document"
import { BiArrowBack } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../../../redux/hooks"
import { getAvatar, useLocationState } from "../../../../../utils/Utils"
import { IUser } from "../../../../../utils/api/user/user.type"
import { MdOutlineAddBox } from "react-icons/md"
import AddCompteRenduModal from "./modal/AddCompteRenduModal"

const steps = [
  { id: "informations-personnelles", Component: InfosPersonnelles },
  { id: "auto-diagnostique", Component: AutoDiagnostique },
  { id: "compte-rendu", Component: CompteRendu },
  { id: "document", Component: Document },
]

const tabs = [
  "Informations Personnelles",
  "Auto-diagnostics",
  "Compte rendu",
  "Document",
]

function Diagnostique() {
  const rdv = useLocationState<any>(undefined)
  const [avatar, setAvatar] = useState<any>(null)
  // console.log("infos patient", rdv)

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps })
  const { Component } = step
  const props = {
    rdv
  }
  const navigate = useNavigate()
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container mb-1">
        <div
          className="workcares-icon-back-container mb-3"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack className="workcares-icon-back" />
          <span>Retour</span>
        </div>
        <div className="d-flex justify-content-between">
          <div className="workcare-dash-praticien-diagnostique-container">
            <div className="workcare-dash-praticien-diagnostique-avatar-container">
              <img
                src={getAvatar(rdv?.salarie?.avatar)}
                alt="avatar user"
                className="workcare-dash-praticien-diagnostique-avatar"
              />
            </div>
            <div className="workcare-dash-praticien-diagnostique-user-name-container">
              <h5 className="workcare-dash-praticien-diagnostique-user-name">
                {rdv?.salarie?.prenom !== undefined &&
                rdv?.salarie?.prenom !== null &&
                rdv?.salarie?.prenom !== "null"
                  ? rdv?.salarie?.prenom
                  : "NON RENSEIGNÉ"}{" "}
                {rdv?.salarie?.nom !== undefined &&
                rdv?.salarie?.nom !== null &&
                rdv?.salarie?.nom !== "null"
                  ? rdv?.salarie?.nom
                  : "NON RENSEIGNÉ"}{" "}
              </h5>
              <div className="workcare-dash-praticien-diagnostique-user-entreprise">
                {rdv?.salarie?.rh?.nom_entreprise !== undefined &&
                rdv?.salarie?.rh?.nom_entreprise !== null &&
                rdv?.salarie?.rh?.nom_entreprise !== "null"
                  ? rdv?.salarie?.rh?.nom_entreprise
                  : "NON RENSEIGNÉ"}{" "}
              </div>
            </div>
          </div>
          {index === 2 && (
            <div>
              <button
                className="btn btn-ajout-compte-rendu"
                data-bs-toggle="modal"
                data-bs-target="#addCompteRenduModal"
              >
                <MdOutlineAddBox />
                Ajouter un compte rendu
              </button>
            </div>
          )}

          <AddCompteRenduModal modalId="addCompteRenduModal" />
        </div>
      </div>

      <div className="workcare-dash-admin-page-content-container mb-1">
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-praticien">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${
                  index === key && "tab-active-item-praticien"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Diagnostique
