import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { ContenusFormdata, IContenus } from "../contenus/contenus.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Diagnostic, DiagnosticFormData, IDiagnostique } from "./autoDiagnostique.type";

export const AutoDiagnostiqueApi = createApi({
  reducerPath: "autoDiagnostiqueApi",
  tagTypes: ["autoDiagnostique", "questiondiagnostics", "deleteResponseDiagnostic"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addAutoDiagnostic: build.mutation<
      IContenus,
      { slug?: string; data: IContenus }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `diagnostics/${slug}/` : `diagnostics/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["autoDiagnostique"],
    }),
    getAutoDiagnostique: build.query<PaginationResults<IContenus>, TypeQuery>({
      query: () => `diagnostics/`,
      providesTags: ["autoDiagnostique"],
    }),
    getSalarieResponseAutoDiagnostique: build.query<IDiagnostique[], TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`salarie/${slug}/doresponsediagnostics/`, query),
      providesTags: ["autoDiagnostique"],
    }),
    deleteAutoDiagnostic: build.mutation<ContenusFormdata, string>({
      query: (slug) => ({
        url: `questiondiagnostics/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["autoDiagnostique", "questiondiagnostics"],
    }),
    deleteResponseAutoDiagnostic: build.mutation<ContenusFormdata, string>({
      query: (slug) => ({
        url: `responsequestiondiagnostics/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["deleteResponseDiagnostic"],
    }),
    deleteResponseEvaluation: build.mutation<ContenusFormdata, string>({
      query: (slug) => ({
        url: `responsequestionevaluations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["deleteResponseDiagnostic"],
    }),
    getQuestionsAutoDiagnostic: build.query<
      PaginationResults<Diagnostic>,
      TypeQuery
    >({
      query: (query) => QueryUrl(`questiondiagnostics/`, query),
      providesTags: ["questiondiagnostics"],
    }),
    addOrEditAutoDiagnostic: build.mutation<
      Diagnostic,
      {
        slug?: string;
        data: DiagnosticFormData | FormData;
      }
    >({
      invalidatesTags: ["autoDiagnostique", "questiondiagnostics"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `update-diagnostic/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `create-diagnostic/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useAddAutoDiagnosticMutation,
  useDeleteAutoDiagnosticMutation,
  useGetAutoDiagnostiqueQuery,
  useAddOrEditAutoDiagnosticMutation,
  useGetQuestionsAutoDiagnosticQuery,
  useGetSalarieResponseAutoDiagnostiqueQuery,
  useDeleteResponseAutoDiagnosticMutation,
  useDeleteResponseEvaluationMutation
} = AutoDiagnostiqueApi;
