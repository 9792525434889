import React, { useEffect } from "react";
import "./Parametre.css";
import { useStep } from "react-hooks-helper";
import { BiUserPlus } from "react-icons/bi";
import { useStepType } from "../../../utils/type";
import BreadCrumb from "../../shared/BreadCrumb";
import Administrateurs from "./ParametresTabs/Administrateurs";
import PolitiqueConfidentialite from "./ParametresTabs/PolitiqueConfidentialite";
import AddOrUpdatePolitique from "./Modals/AddOrUpdatePolitique";
import AddOrUpdateAdministrateur from "./Modals/AddOrUpdateAdministrateur";
import ContactsAdmin from "../Contacts/ContactsAdmin";
import SpecialitesTab from "./ParametresTabs/SpecialitesTab";
import { BsPlusSquare } from "react-icons/bs";
import Add0rEditSpecialiteModal from "./Modals/Add0rEditSpecialiteModal";
import Thematique from "./ParametresTabs/Thematique";
import AddOrEditThematiqueModal from "./Modals/AddOrEditThematiqueModal";
import { showModal, useLocationState } from "../../../utils/Utils";
import MentionLegale from "./ParametresTabs/MentionLegale";
import { useGetConditionsQuery } from "../../../utils/api/condition/condition.api";

const steps = [
  { id: "administrateurs", Component: Administrateurs },
  { id: "politique-confidentialite", Component: PolitiqueConfidentialite },
  { id: "mention-legale", Component: MentionLegale },
  { id: "contact", Component: ContactsAdmin },
  { id: "specialite", Component: SpecialitesTab },
  // { id: "thematique", Component: Thematique },
];

const tabs = [
  "Administrateurs",
  "Politique de confidentialité",
  "Mention légale",
  "Messages contacts",
  "Spécialités",
  // "Thématiques",
];

function Parametre() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const { data = { results: [] } } = useGetConditionsQuery({});

  const itemState = useLocationState<string>(undefined);

  useEffect(() => {
    !!itemState ? go(itemState) : "";
  }, [itemState]);

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Paramètres" title="Paramètres" />
        </div>
        {index === 0 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout"
              data-bs-toggle="modal"
              data-bs-target="#AddOrUpdateAdministrateur"
            >
              <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button>

            <div
              className="modal fade"
              id="AddOrUpdateAdministrateur"
              aria-labelledby="AddOrUpdateAdministrateurLabel"
              aria-hidden="true"
            >
              <AddOrUpdateAdministrateur
                modalId="AddOrUpdateAdministrateur"
                hide={true}
              />
            </div>
          </>
        )}
        {index === 1 &&
          !data?.results?.filter((el) => el?.type === "policy")?.length && (
            <>
              <button
                className="workcare-dash-admin-btn-ajout"
                // data-bs-toggle="modal"
                // data-bs-target="#AddOrUpdatePolitique"
                onClick={() => showModal("AddOrUpdatePolitique")}
              >
                <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" />{" "}
                Ajouter
              </button>
            </>
          )}
        {index === 2 &&
          !data?.results?.filter((el) => el?.type === "ml")?.length && (
            <button
              className="workcare-dash-admin-btn-ajout"
              // data-bs-toggle="modal"
              // data-bs-target="#AddOrUpdateMentionLegale"
              onClick={() => showModal("AddOrUpdateMentionLegale")}
            >
              <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button>
          )}
        {index === 4 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout"
              // data-bs-toggle="modal"
              // data-bs-target="#Add0rEditSpecialiteModal"
              onClick={() => showModal("Add0rEditSpecialiteModal")}
            >
              <BsPlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter une spécialité
            </button>

            <div
              className="modal fade"
              id="Add0rEditSpecialiteModal"
              aria-labelledby="Add0rEditSpecialiteModalLabel"
              aria-hidden="true"
            >
              <Add0rEditSpecialiteModal modalId="Add0rEditSpecialiteModal" />
            </div>
          </>
        )}
        {index === 5 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout"
              // data-bs-toggle="modal"
              // data-bs-target="#Add0rEditThematiqueModal"
              onClick={() => showModal("Add0rEditThematiqueModal")}
            >
              <BsPlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter une thématique
            </button>

            <div
              className="modal fade"
              id="Add0rEditThematiqueModal"
              aria-labelledby="Add0rEditThematiqueModalLabel"
              aria-hidden="true"
            >
              <AddOrEditThematiqueModal modalId="Add0rEditThematiqueModal" />
            </div>
          </>
        )}
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Parametre;
