import React, { ChangeEvent, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage, onHide } from "../../../../utils/Utils";
import { useDelete } from "../../../../utils/helpers";
import { FaTrash } from "react-icons/fa";
import {
  useAddOrEditAutoDiagnosticMutation,
  useDeleteAutoDiagnosticMutation,
} from "../../../../utils/api/autoDiagnostique/autoDiagnostique";
import {
  Diagnostic,
  DiagnosticFormData,
} from "../../../../utils/api/autoDiagnostique/autoDiagnostique.type";

function UseCrudAutoDiagnostique(
  modalId: string,
  inputFields: any,
  contenu?: Diagnostic
) {
  const validationSchema = yup.object().shape({
    type: yup.string().required().label("Le type"),
    contenu: yup.string().required().label("La question"),
    background_image: yup.mixed().label("image").nullable(),
    ordre: yup
      .number()
      .required()
      .label("L'ordre")
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    legende: yup.string().label("La légende").nullable(),
    responses: yup
      .array()
      .when("type", {
        is: (val) => val === "choice" || val === "checkbox",
        then: yup
          .array()
          .of(
            yup.object().shape({
              contenu: yup.string().required().nullable().label("La réponse"),
              id: yup
                .number()
                .nullable()
                .label("L'id")
                .transform((value) => (isNaN(value) ? undefined : value)),
            })
          )
          .label("La réponse")
          .required()
          .nullable(),
      })
      .nullable(),
  });
  // type === "choice" || type === "checkbox";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    control,
  } = useForm<DiagnosticFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: { responses: [{ contenu: "" }] },
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "responses",
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditAutoDiagnosticMutation();
  const [type, setType] = useState("");

  const [description, setDescription] = React.useState<any>("");
  const [previewImage, setPreviewImage] = useState(
    contenu?.background_image ? getImage(contenu?.background_image) : null
  );
  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("background_image", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      register("background_image");
      setValue("background_image", file);
    }
  }, []);

  const onSelectType = (e) => {
    setType(e?.target?.value);
    setValue("type", e?.target?.value);
  };
  const handleChange = (value: any) => {
    setValue("legende", value);
    setDescription(value);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Contenu ${contenu ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!contenu) {
          reset();
          setDescription("");
          setType("");
          setPreviewImage(null);
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    // console.log("err",errors)
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: DiagnosticFormData) => {
    console.log("DATA SUBMITED", data);

    // if (data?.type === "evaluation") {
    //   data.responses = ["1","2","3","4","5","6","7","8","9","10"]
    // }

    if (
      !contenu &&
      data?.responses?.length &&
      (data?.type === "choice" ||
        data?.type === "checkbox" ||
        data?.type === "evaluation")
    ) {
      data.responses = data?.responses?.map((el) => el?.contenu);

      // data.responses = inputFields.map((el) => ({ contenu: el.response }));
    } else if (
      data?.type !== "choice" &&
      data?.type !== "checkbox" &&
      data?.type !== "evaluation"
    ) {
      data.responses = []
    }
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "background_image" && value) {
        fd.append(key, value);
      } else if (key === "responses") {
        fd.append("responses", JSON.stringify(value));
      } else {
        fd.append(key, value);
      }
    }

    await sendData({ slug: contenu?.slug, data: fd });
  };

  function fillResponsesFields(contenu) {
    if (contenu?.responses?.length) {
      for (let i = 0; i < contenu.responses.length; i++) {
        const element = contenu.responses[i];
        setValue(`responses.${i}.id`, element.id);
        setValue(`responses.${i}.contenu`, element.contenu);
      }
    }
  }

  useEffect(() => {
    if (contenu?.id) {
      let fieldsToRegister: (keyof DiagnosticFormData)[] = [
        "contenu",
        "legende",
        "ordre",
        "slug",
        "type",
      ];

      for (let field of fieldsToRegister) {
        register(field);
        setValue(field, contenu[field]);
      }

      // fillResponsesFields(contenu);
      if (contenu?.responses?.length) {
        replace(
          contenu?.responses?.map((el) => {
            return {
              contenu: el?.contenu,
              id: el?.id,
            };
          })
        );
      }
      setType(contenu.type);
      if (contenu.legende) {
        setDescription(contenu.legende);
      }
    }
  }, [contenu]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    description,
    setDescription,
    handleChange,
    type,
    onSelectType,
    handelImageChange,
    previewImage,
    setPreviewImage,
    fields,
    append,
    remove,
  };
}

export function DeleteAutoDiagnostique({ item }: { item: Diagnostic }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteAutoDiagnosticMutation();
  const onDelete = useDelete<Diagnostic>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce contenu",
    successMessage: "Contenu supprimé",
  });
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}
export default UseCrudAutoDiagnostique;
