import React from "react";
import { BiUserPlus } from "react-icons/bi";
import BreadCrumb from "../../shared/BreadCrumb";
import EntrepriseTable from "./table/EntrepriseTable";


function Entreprises() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Entreprises" title="Entreprises" />
        </div>
       
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <EntrepriseTable />
      </div>
    </div>
  );
}

export default Entreprises;
