import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { AiFillEye } from "react-icons/ai"
import { MdModeEditOutline } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { useGetSalariesQuery } from "../../../../utils/api/user/user.api"
import { getName } from "../../../../utils/Utils"
import { AlertInfo } from "../../../common/Alert"
import { CustomPagination } from "../../../common/CustomPagination"
import TableSkeleton from "../../../common/TableSkeleton"
import AddOrUpdateUtilisateurModal from "../Modals/AddOrUpdateUtilisateurModal"
import { DeleteUser } from "../useForm/UseCrudUtilisateur"

function ListeUtilisateursTable() {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { data, isLoading } = useGetSalariesQuery({
    page: page,
    limit: perPage,
  })
  console.log("data", data)

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-admin with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#AddOrUpdateUtulisateur${row?.slug}`}
          >
            <MdModeEditOutline />
          </button>

          <NavLink
            to={`/admin/utilisateur/${row?.slug}`}
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Supprimer"
            state={row}
          >
            <AiFillEye />
          </NavLink>
          <DeleteUser item={row} />
        </div>
        <div
          className="modal fade"
          id={`AddOrUpdateUtulisateur${row?.slug}`}
          aria-labelledby={`AddOrUpdateUtulisateur${row?.slug}Label`}
          aria-hidden="true"
        >
          <AddOrUpdateUtilisateurModal
            modalId={`AddOrUpdateUtulisateur${row?.slug}`}
            user={row}
          />
        </div>
      </>
    )
  }

  const columns = [
    {
      dataField: "nom",
      text: "PRÉNOM ET NOM",
      formatter: (cell: any, row: any) => getName(row),
    },
    {
      dataField: "email",
      text: "Adresse mail",
    },
    {
      dataField: "telephone",
      text: "TÉLÉPHONE",
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ]

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage)
                setPage(page)
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  )
}

export default ListeUtilisateursTable
