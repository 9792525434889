import React from "react";
import { getAvatar, getName, useLocationState } from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";

function InfosEntreprise() {
  const user = useLocationState<IUser>(undefined);
  return (
    <>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="workcares-dash-admin-profil-main-page-container ps-0 py-3">
            <div className="container-profil-user-admin py-4 text-center position-relative">
              <div className="container-img-profil-user mb-4">
                <img
                  src={getAvatar(user?.avatar)}
                  alt="Profil"
                  className="custom-img-profil-user-admin"
                />
              </div>
              <div className="content-text-name-user">
                <h2 className="custom-name-user-admin">
                  {user?.nom_entreprise || getName(user)}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire">Bio</h5>
              <div className="workcare-dash-admin-section-bio-para-container">
                <p className="workcare-dash-admin-section-bio-para">
                  {user?.bio !== undefined &&
                  user?.bio !== null &&
                  user?.bio !== "null"
                    ? user?.bio
                    : "NON RENSEIGNÉ"}
                </p>
                {/* <button className="btn workcare-dash-admin-section-bio-btn-edit">Edit</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <div>
                <div className="workcare-dash-admin-section-edit-profil-container">
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Nom entreprise
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.nom_entreprise
                          ? user?.nom_entreprise
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Prénom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.prenom !== undefined &&
                        user?.prenom !== null &&
                        user?.prenom !== "null"
                          ? user?.prenom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Nom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.nom !== undefined &&
                        user?.nom !== null &&
                        user?.nom !== "null"
                          ? user?.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Adresse
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.adresse ? user?.adresse : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Adresse mail
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.email ? user?.email : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Téléphone
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.telephone ? user?.telephone : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Nombre de salariés
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.nb_salaries}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="changeInfosPerso"
                  aria-labelledby="changeInfosPersoLabel"
                  aria-hidden="true"
                >
                  {/* <ModifierInfosPersoModal
                    modaleId="changeInfosPerso"
                    hide={true}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfosEntreprise;
