/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import { FiSearch } from "react-icons/fi";
import AddOrUpdateActivites from "./Modals/AddOrUpdateActivites";
import AddOrUpdateAteliers from "./Modals/AddOrUpdateAteliers";
import GererDisponibiliteModal from "../../TableauDeBord/Praticien/MonAgenda/Modals/GererDisponibiliteModal";
import { getName } from "../../../utils/Utils";
import frLocale from '@fullcalendar/core/locales/fr'
import UseCalendar from "./useForm/UseCalendar";

export default function Calendar() {
  const {
    user,
    events,
    handleFilterByName,
    handleFilterByEntreprise,
    Entreprise,
    entreprise,
    categorie,
    handleFilterByCategorie,
    EntreprisePraticien,
    evenement,
    setEvenement
  } = UseCalendar();
  return (
    <div className="demo-app">
      {renderSidebar()}
      <div className="demo-app-main">
        {/* <div id="calendar" /> */}
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            rrulePlugin,
          ]}
          headerToolbar={{
            left: "prev,next title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          editable={false}
          selectable={true}
          selectMirror={false}
          dayMaxEvents={false}
          weekends={true}
          events={events || []}
          eventContent={renderEventContent}
          locales={[frLocale]}
        />
      </div>
    </div>
  );

  function renderSidebar() {
    return (
      <div className="demo-app-sidebar">
        {user?.user_type === "admin" ||
          (user?.user_type === "superadmin" && (
            <>
              <div className="demo-app-sidebar-section-btn-container">
                <button
                  className="btn demo-app-sidebar-section-workcares-btn-add custom-btn-add-activite"
                  data-bs-toggle="modal"
                  data-bs-target="#AddOrUpdateEvenement"
                >
                  Ajouter une activité
                </button>
              </div>
              <div className="demo-app-sidebar-section-btn-container">
                <button
                  className="btn demo-app-sidebar-section-workcares-btn-add custom-btn-add-atelier"
                  data-bs-toggle="modal"
                  data-bs-target="#AddOrUpdateAtelier"
                >
                  Ajouter un atelier
                </button>
              </div>
              <div className="workcares-agenda-filter-container my-3 ps-2">
                <div className="workcares-agenda-filter-label">Entreprises</div>
              </div>
              <div className="search-form-container mb-3">
                <div className="search-form-content-agenda">
                  <label>
                    <FiSearch />
                  </label>
                  <input
                    name="search"
                    className="form-control search-input-agenda"
                    placeholder="Rechercher"
                    onChange={handleFilterByName}
                  />
                </div>
              </div>
              {Entreprise?.slice(0, 6)?.map((item) => (
                <div className="demo-app-sidebar-section ps-2" key={item?.id}>
                  <label>
                    <input
                      type="checkbox"
                      value={item?.slug}
                      checked={entreprise === item?.slug}
                      onChange={handleFilterByEntreprise}
                    ></input>
                    {item?.nom_entreprise || item?.prenom + " " + item?.nom}
                  </label>
                </div>
              ))}

              <div className="demo-app-sidebar-divider mb-5"></div>

              <div className="workcares-agenda-filter-container my-3 ps-2">
                <div className="workcares-agenda-filter-label">Catégories</div>
              </div>
              <div className="demo-app-sidebar-section ps-2">
                <label>
                  <input
                    type="checkbox"
                    value="activite"
                    checked={categorie === "activite"}
                    onChange={handleFilterByCategorie}
                  ></input>
                  Activités
                </label>
              </div>
              <div className="demo-app-sidebar-section ps-2">
                <label>
                  <input
                    type="checkbox"
                    value="atelier"
                    checked={categorie === "atelier"}
                    onChange={handleFilterByCategorie}
                  ></input>
                  Atelier
                </label>
              </div>
              <div className="demo-app-sidebar-divider"></div>
            </>
          ))}
        {user?.user_type === "praticien" && (
          <>
            <div className="demo-app-sidebar-section-btn-container">
              <button
                className="btn demo-app-sidebar-section-workcares-btn-add"
                data-bs-toggle="modal"
                data-bs-target="#GererDisponibiliteModal"
              >
                Ajouter une disponibilité
              </button>
            </div>

            <div className="workcares-agenda-filter-container my-3 ps-2">
              <div className="workcares-agenda-filter-label">Entreprises</div>
            </div>
            <form className="search-form-container mb-3">
              <div className="search-form-content-agenda">
                <label>
                  <FiSearch />
                </label>
                <input
                  name="search"
                  className="form-control search-input-agenda"
                  placeholder="Rechercher"
                  onChange={handleFilterByName}
                />
              </div>
            </form>
            {EntreprisePraticien?.slice(0, 10)?.map((item) => (
              <div className="demo-app-sidebar-section ps-2" key={item?.id}>
                <label>
                  <input
                    type="checkbox"
                    value={item?.slug}
                    checked={entreprise === item?.slug}
                    onChange={handleFilterByEntreprise}
                  ></input>
                  {item?.nom_entreprise || item?.prenom + " " + item?.nom}
                </label>
              </div>
            ))}
            <div className="demo-app-sidebar-divider mb-5"></div>
          </>
        )}
        <div
          className="modal fade"
          id="AddOrUpdateEvenement"
          aria-labelledby="AddOrUpdateEvenementLabel"
          aria-hidden="true"
        >
          <AddOrUpdateActivites modalId="AddOrUpdateEvenement" />
        </div>
        <div
          className="modal fade"
          id="AddOrUpdateAtelier"
          aria-labelledby="AddOrUpdateAtelierLabel"
          aria-hidden="true"
        >
          <AddOrUpdateAteliers modalId="AddOrUpdateAtelier" />
        </div>
        <div
          className="modal fade"
          id="GererDisponibiliteModal"
          aria-labelledby="GererDisponibiliteModalLabel"
          aria-hidden="true"
        >
          <GererDisponibiliteModal modalId="GererDisponibiliteModal" />
        </div>

        <div
          className="modal fade"
          id={`AddOrUpdateActivite${evenement?.id}`}
          aria-labelledby={`AddOrUpdateActivite${evenement?.id}Label`}
          aria-hidden="true"
        >
          <AddOrUpdateActivites
            modalId={`AddOrUpdateActivite${evenement?.id}`}
            evenement={evenement}
          />
        </div>

        <div
          className="modal fade"
          id={`AddOrUpdateAtelier${evenement?.id}`}
          aria-labelledby={`AddOrUpdateAtelier${evenement?.id}Label`}
          aria-hidden="true"
        >
          <AddOrUpdateAteliers
            modalId={`AddOrUpdateAtelier${evenement?.id}`}
            evenement={evenement}
          />
        </div>
        <div
          className="modal fade"
          id={`GererDisponibiliteModal${evenement?.id}`}
          aria-labelledby={`GererDisponibiliteModal${evenement?.id}Label`}
          aria-hidden="true"
        >
          <GererDisponibiliteModal
            modalId={`GererDisponibiliteModal${evenement?.id}`}
            disponibilite={evenement}
          />
        </div>
      </div>
    );
  }

  function renderEventContent(eventInfo) {
    const event = eventInfo.event._def.extendedProps;
    // console.log(
    //   "eventInfo",
    //   event?.entreprise?.nom_entreprise,
    //   event?.entreprise);

    return (
      <div
        className="custom-render-event-content"
        style={{
          backgroundColor: `${eventInfo?.backgroundColor}`,
          color: `${eventInfo?.textColor}`,
          padding: "1px 10px",
          borderRadius: "10px",
          width: "100%",
        }}
        data-bs-toggle="modal"
        data-bs-target={
          event?.type === "activite"
            ? `#AddOrUpdateActivite${event?.id}`
            : event?.type === "atelier"
            ? `#AddOrUpdateAtelier${event?.id}`
            : `#GererDisponibiliteModal${evenement?.id}`
        }
        onClick={() => setEvenement(event)}
      >
        {user?.user_type === "praticien" ? (
          <>
            <b>{event?.heure_ouverture + "-" + event?.heure_fermeture}</b>
            <i>
              {"Ent." + " " + (event?.entreprise?.nom_entreprise ||
                event?.entreprise?.prenom + " " + event?.entreprise?.nom)}
            </i>
          </>
        ) : (
          <b>{eventInfo?.timeText}</b>
        )}
        {user?.user_type !== "praticien" && (
          <>
            <i>{eventInfo.event.title}</i>
            <b>{"Ent." + " " + (event.entreprise.nom_entreprise ? event.entreprise.nom_entreprise : event.entreprise.prenom + " " + event.entreprise.nom)}</b>
            <i>{event.description}</i>
          </>
        )}
      </div>
    );
  }
}
