import { CiviliteType, IUser, UserType } from "../user/user.type";

export enum SpecialiteType {
	ostéopathe = 'ostéopathe',
	psychologue = 'psychologue',
	kinésithérapeute = 'kinésithérapeute',
	sophrologue = 'sophrologue',
	diététicien = 'diététicien_nutritionniste',
	ergothérapeute = 'ergothérapeute',
	ergonome = 'ergonome',
	coach_sportif = 'coach_sportif',
	autre = 'autre'
}

export type RegisterFormData = {
	nom: string;
	prenom: string;
	date_naissance: string;
	user_type: UserType;
	civilite: CiviliteType;
	telephone: string;
	email: string;
	password: string;
	confirmPassword: string;
	role: string;
	username: string;
	avatar: string;
	nom_entreprise: string,
    specialite: string,
	nb_salaries: string,
	nb_mutuelles: string,
};

export type EditUserFormData = RegisterFormData & { slug: string };

export type LoginFormData = {
	email: string;
	password: string;
	username: string
};

export type SocialRegisterFormData = {
	email: string;
	prenom: string;
	nom: string;
};

export type LoginResult = { data: IUser; token: string | null };

export type ForgetPasswordFormData = {
	email: string;
};

export type ResetPasswordFormData = {
	email: string;
	new_password_confirm: string;
	code: string;
	new_password: string;
};

export type ChangePasswordData = {
	old_password: string;
	new_password: string;
	confirm_password: string;
};

export type DeleteProfilData = {
	password: string;
};