import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../utils/api/auth/auth.api";
import {
  ForgetPasswordFormData,
  ResetPasswordFormData,
} from "../../../utils/api/auth/auth.type";
import { AppLocalStorage, AppStorage } from "../../../utils/storage";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/Utils";
import { validatePassword } from "./useRegisterForm";
export function UseResetPasswordForm() {
  const [resetPassword, { error, isSuccess, isLoading }] =
    useResetUserPasswordMutation();

  const state = useLocation().state as ForgetPasswordFormData;

  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required().label("Email"),
        code: yup
          .number()
          .required()
          .label("Code")
          .typeError("Code est un champ obligatoire"),
        new_password: validatePassword(yup),
        new_password_confirm: yup
          .string()
          .oneOf(
            [yup.ref("new_password"), null],
            "Les mots de passe ne correspond pas"
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const [PasswordInput, setPasswordInput] = useState();

  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPasswordInput(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("new_password", e.target.value);
  };

  useEffect(() => {
    if (state?.email) {
      setValue("email", state.email);
    } else {
      setTimeout(() => navigate("/forget-password"), 1);
    }
  }, [state?.email]);

  useEffect(() => {
    if (error) {
      const err = error as any;
      const { message = "Une erreur a survenue" } = err.data || {};
      setError("code", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      AppLocalStorage.removeItem("countDown");
      AppStorage.setItem("nbErr", 0);
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Mot de passe modifié avec succès!",
      });
      navigate("/");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);
  return {
    register,
    errors,
    onSubmit: handleSubmit(resetPassword),
    clearErrors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    PasswordInput
  };
}
