import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { useGetQuestionsEvalBienEtreQuery } from "../../../../utils/api/evalBienEtre/EvalBienEtre";
import { createMarkup } from "../../../../utils/Utils";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import CrudEvalution from "../Modals/CrudEvalution";
import ShowQuestionModal from "../Modals/ShowQuestionModal";
import { DeleteEval } from "../useForm/UseCrudEvalBienEtre";

function EvalBienEtreTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetQuestionsEvalBienEtreQuery({ limit: perPage, page });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-action gap-2 d-flex">
          <button
            className="btn btn-action-admin with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#editMinuteEvalModal${row?.slug}`}
          >
            <MdModeEditOutline />
          </button>
          <button
            className="btn btn-action-admin with-tooltip no-link"
            data-tooltip-content="Voir"
            data-bs-toggle="modal"
            data-bs-target={`#showMinuteEvalModal${row?.slug}`}
          >
            <AiFillEye />
          </button>
          <DeleteEval item={row} />
        </div>
        <div
          className="modal fade"
          id={`editMinuteEvalModal${row?.slug}`}
          aria-labelledby={`editMinuteEvalModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <CrudEvalution modalId={`editMinuteEvalModal${row?.slug}`} contenu={row} />
        </div>
        <div
          className="modal fade"
          id={`showMinuteEvalModal${row?.slug}`}
          aria-labelledby={`showMinuteEvalModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <ShowQuestionModal item={row} />
        </div>
      </>
    );
  };

  const typeFormatter = (cell: any, row: any) => {
    if (cell === "choice") return "Choix multiple";
    if (cell === "select") return "Sensation";
    if (cell === "text_field") return "Champ libre";
    if (cell === "checkbox") return "Choix unique";
    if (cell === "message") return "Message de succès";
    if (cell === "evaluation") return " Echelle de 1 à 10";
  };

  const questionFormatter = (cell: any, row: any) => {
    return (
      <p className="text-question-custom mb-0">
        {" "}
        <span>{row?.contenu}</span>
      </p>
    );
  };

  const reponseFormatter = (cell: any, row: any) => {
    return (
      <p className="text-question-custom mb-0">
        {" "}
        {!!row?.responses?.length && row?.responses[0]?.contenu + "..."}
      </p>
    );
  };

  const columns = [
    {
      dataField: "ordre",
      text: "Ordre",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "type",
      formatter: (cell: any, row: any) => typeFormatter(cell, row),
      text: "TYPE",
    },
    {
      dataField: "contenu",
      text: "QUESTION",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
      formatter: (cell: any, row: any) => questionFormatter(cell, row),
    },
    {
      dataField: "legende",
      text: "LEGENDE",
      headerStyle: () => {
        return { whiteSpace: "normal" };
      },
      formatter: (cell) => (
        <p
          className="text-question-custom"
          dangerouslySetInnerHTML={createMarkup(cell)}
        />
      ),
    },
    {
      dataField: "reponses",
      text: "REPONSE",
      headerStyle: () => {
        return { whiteSpace: "normal" };
      },
      formatter: (cell: any, row: any) => reponseFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EvalBienEtreTable;
