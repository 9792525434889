import React from "react";
import { BtnAnnulerModal, BtnCloseModal, BtnSubmit } from "../../../../common/Button";
import { FormError, InputPassword } from "../../../../common/Input";
import UseDeleteAccountForm from "../useForm/UseDeleteAccountForm";

function ConfirmDeleteAccountModal() {
  const { register, errors, onSubmit, isLoading, handleReset } =
    UseDeleteAccountForm();
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content text-start">
        <div className="modal-header px-2">
          <div className="py-1">
            <p className="certification-compte-texte fw-500">
              Veuillez saisir votre mot de passe pour confirmer ?
            </p>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-4">
                  <InputPassword
                    label="Mot de passe"
                    id="password"
                    placeholder="Mot de passe"
                    {...register("password")}
                    error={errors?.password}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-r gap-3 p-3">
            <BtnAnnulerModal label="Annuler" customClass="btn-annuler-suppression" onClick={() => handleReset()} />
            <BtnSubmit label="Confirmer" isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfirmDeleteAccountModal;
