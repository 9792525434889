import React from 'react'
import AdministrateursTable from '../Parametrestables/AdministrateursTable'

function Administrateurs() {
  return (
    <div className="workcare-dash-admin-page-content-container">
      <AdministrateursTable />
    </div>
  )
}

export default Administrateurs