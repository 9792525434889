import React from "react";
import ReactQuill from "react-quill";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../../common/Button";
import { FormError, Input } from "../../../../common/Input";
import UseCrudObjectifAction from "../useForm/UseCrudObjectifAction";
import ErrorMessage from "../../../../common/ErrorMessage";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function AddObjectifAction({ user, modalId }: { user?: any; modalId: string }) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handleReset,
    description,
    handleChange,
    isAdmin,
    option,
    options,
    handleInputChange,
    onChangeEntreprise,
    Controller,
    control
  } = UseCrudObjectifAction(modalId, user);
  const animatedComponents = makeAnimated();
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter Objectif & Action
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" onClick={handleReset} />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                {!!isAdmin && (
                  <div className="col-md-12 col-form-add-student mb-4">
                    <div className="content-col-form-add-student">
                      <label className="form-label form-label-add-student">
                        {" "}
                        Sélectionner une entreprise
                      </label>
                      <Controller
                        name="rh"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              onInputChange={handleInputChange}
                              components={animatedComponents}
                              placeholder="Selectionner ou chercher"
                              isMulti={false}
                              filterOption={() => true}
                              options={options || []}
                              classNamePrefix="react-select-custom"
                              onChange={onChangeEntreprise}
                              value={option}
                            />
                          );
                        }}
                      />
                      {errors.rh && (
                        <ErrorMessage message={errors.rh?.message} />
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Objectif"
                    id="Titre"
                    placeholder="Objectif"
                    {...register("titre")}
                    error={errors?.titre}
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3 text-start">
                    <label className="form-label form-label-modal-custom">
                      Action
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    {/* <textarea
                      cols={200}
                      rows={200}
                      className="form-control form-control-modal-custom"
                      id="Contenu"
                      placeholder="Contenu"
                      {...register("contenu")}
                    /> */}
                    <ReactQuill
                      className="editor-cgu custom-textarea"
                      value={description}
                      onChange={(value) => handleChange(value)}
                    />
                    {<FormError error={errors?.contenu} />}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal
                      label={"Annuler"}
                      isLoading={false}
                      onClick={handleReset}
                    />
                    <BtnSubmit
                      label={user ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddObjectifAction;
