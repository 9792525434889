import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { GrapheDiagnostic } from "../../../../utils/api/dashboard/dashboard.type";
import Skeleton from "react-loading-skeleton";

function VerbatimGraph({
  stats,
  isLoading,
}: {
  stats?: GrapheDiagnostic[];
  isLoading: boolean;
}) {
  const options = {
    responsive: true,
  };

  const labels = stats?.map((el) => el?.name) || [];

  const data = {
    labels,
    datasets: [
      {
        label: "Verbatim",
        data: stats?.map((el) => el?.value) || [],
        backgroundColor:
          stats?.map((el) => (el?.value >= 3 ? "#F85470" : "#0C98D4")) || [],
      },
    ],
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-3">
        <div className="col-md-12 mb-2">
          <h2 className="chart-item-admin-title-admin mb-0">Verbatim</h2>
        </div>
      </div>
      {!!isLoading && (
        <>
          <Skeleton height={207} />
        </>
      )}
      {!isLoading && (
        <>
          <Bar options={options} data={data} />
        </>
      )}
    </div>
  );
}

export default VerbatimGraph;
