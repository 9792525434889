import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IUser,
  UserFormData,
  UserType,
} from "../../../../utils/api/user/user.type";
import {
  useAddOrEditUserMutation,
  useDeleteUserMutation,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide, validatePhone } from "../../../../utils/Utils";
import { useDelete } from "../../../../utils/helpers";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function UseCrudUtilisateur(
  modalId: string,
  user?: IUser,
  isFromDetail?: boolean
) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    telephone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("Email"),
    adresse: yup.string().label("Adresse"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditUserMutation();

  let navigate = useNavigate();
  const [address, setAddress] = useState("");

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !user
          ? "Utilisateur ajouté avec succès"
          : "L'utilisateur a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        isFromDetail && navigate(`/admin/utilisateurs`);
        onHide(modalId);
        if (!user) {
          reset();
        }
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    console.log("isFromDetail", isFromDetail);
    if (user?.id) {
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
      setValue("adresse", user?.adresse);
      setAddress(user?.adresse);
      setValue("user_type", user?.user_type);
      if (user?.telephone) {
        setPhone(user.telephone);
      }
    }
  }, [user]);

  const handleReset = () => {
    if (!user) {
      reset();
    }
  };

  const onSubmit = (data: UserFormData) => {
    data["user_type"] = UserType?.salarie;
    addOrUpdateUser({ slug: user?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    user,
    address,
    onChangeAddress,
    phone,
    handleChangePhone,
    reset,
    handleReset,
  };
}

export function DeleteUser({ item }: { item: IUser }) {
  const [deleteItem, { isSuccess, isError, error }] = useDeleteUserMutation();
  const onDelete = useDelete<IUser>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cet utilisateur",
    successMessage: "Utilisateur supprimé",
  });
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseCrudUtilisateur;
