import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"
import * as yup from "yup"
import { Color } from "../../../../utils/theme"
import { cleannerError, closeModal, getImage, onHide } from "../../../../utils/Utils"
import { useDelete } from "../../../../utils/helpers"
import { FaTrash } from "react-icons/fa"
import { useAddOrEditThematiqueMutation, useDeleteThematiqueMutation } from "../../../../utils/api/thematique/thematique"
import { Thematique, ThematiqueFormData } from "../../../../utils/api/thematique/thematique.type"



function useCrudThematique(modalId: string, contenu?: Thematique) {
  const validationSchema = yup.object().shape({
    libelle: yup.string().required().label("Le libelle"),
  });
  // type === "choice" || type === "checkbox";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError
  } = useForm<ThematiqueFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [sendDataThematique, { isLoading, isSuccess, error, isError }] =
    useAddOrEditThematiqueMutation();

  const [libelle, setLibelle] = useState("")


  const handleChange = (value: any) => {
    setValue("libelle", libelle);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Thématique ${contenu ? "modifiée" : "ajoutée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!contenu) {
          reset();
          setLibelle("")
        }
        closeModal(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err",errors)
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async(data: ThematiqueFormData) => {
    console.log("DATA SUBMITED", data);

    await  sendDataThematique({ slug: contenu?.slug, data: data });
  };

  useEffect(() => {
    if (contenu?.id) {
      let fields: (keyof ThematiqueFormData)[] = [
        "slug",
        "libelle",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, contenu[field])
      }
      setLibelle(contenu?.libelle);
    }
  }, [contenu]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    handleChange,
    libelle,
  };
}

export function DeleteThematique({ item }: { item: Thematique }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteThematiqueMutation();
  const onDelete = useDelete<Thematique>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette thématique",
    successMessage: "Thématique supprimée",
  })
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  )
}

export default useCrudThematique;
