import React, { useState } from "react"
import Icon1 from "../../../../assets/icons/icon.png"
import Icon2 from "../../../../assets/icons/icon2.png"
import Icon3 from "../../../../assets/icons/icon3.png"
import RdvTables from "./Tables/RDVTables/RdvTables"
import RecentPatientTable from "./Tables/RecentPatientTables/RecentPatientTable"
import "./DashboardPraticien.css"
import { useAppSelector } from "../../../../redux/hooks"
import { useGetDashboardPraticienQuery } from "../../../../utils/api/user/user.api"
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

function DashboardPraticien() {
  const user = useAppSelector((s) => s?.user?.user)
  const [date, setDate] = useState(new Date())
  const [frequence, setFrequence] = useState("week")
  const { data: dashboard } = useGetDashboardPraticienQuery({
    slug: user?.slug
  })

  return (
    <div className="dashbord-visiteur-component">
      <div className="row row-card-stat-visiteur mb-4">
        <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon1}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">
                Nombre de patients TMS
              </p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#F4925B" }}
              >
                {dashboard?.patient_tms_count}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon2}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">Rendez-vous</p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#12CBD6" }}
              >
                {dashboard?.rdv_count}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
          <div className="content-col-stat-dashboard-admin">
            <div className="content-img-folder-dashboard-visiteur">
              <img
                src={Icon3}
                alt="dashboard visiteur"
                className="img-folder-dashboard-visiteur"
              />
            </div>
            <div className="info-user-admin-stat-dashboard">
              <p className="text-stat-dashboard-visiteur mb-1">
                Nombre total de patients
              </p>
              <p
                className="text-chiffre-dashboard-admin mb-0"
                style={{ color: "#795BF4" }}
              >
                {dashboard?.patient_count}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="dashboard-praticien-titre-rdv">Rendez-vous</h4>
                </div>
                <div className="col-md-6 mb-2 d-flex calendars-container">
                  <div className="w-auto">
                    <select
                      className="dashboard-select px-3 py-2"
                      name="freq"
                      id="freq"
                      value={frequence}
                      onChange={(e) => setFrequence(e?.target?.value)}
                    >
                      {/* <option value="">Fréquence</option> */}
                      <option value="week">Hebdomadaire</option>
                      <option value="month">Mensuelle</option>
                      <option value="year">Annuelle</option>
                    </select>
                  </div>
                  <div className="w-auto">
                    
                    <DatePicker
                      showIcon
                      className="dashboard-select px-3 py-2"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      locale="fr"
                      dateFormat={
                        frequence === "week"
                          ? "dd/MM/yyyy"
                          : frequence === "month"
                          ? "MMMM/yyyy"
                          : "yyyy"
                      }
                      showMonthYearPicker={frequence === "month"}
                      showYearPicker={frequence === "year"}
                    />
                  </div>
                </div>
              </div>

              <div className="dashboard-praticien-table-rdv-container my-4">
                <RdvTables date={date} frequence={frequence} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
              <h4 className="dashboard-praticien-titre-rdv">
                Récents patients
              </h4>
              <div className="dashboard-praticien-table-recent-patient-container my-4">
                <RecentPatientTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPraticien
