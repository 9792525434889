import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Specialite,
  SpecialiteFormData,
} from "../../../../utils/api/specialite/specialite.type";
import { useAddOrUpdateSpecialiteMutation, useDeleteSpecialiteMutation } from "../../../../utils/api/specialite/specialite.api";
import { cleannerError, closeModal, onHide } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";

function UseCrudSpecialiteForm(modalId: string, specialite?: Specialite) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<SpecialiteFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateSpecialiteMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !specialite
          ? "Spécialité ajoutée avec succès!"
          : "La spécialité a été modifiée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!specialite) {
          reset();
        }
        closeModal(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (specialite?.id) {
      console.log("here");
      let fields: (keyof SpecialiteFormData)[] = [
        "nom",
        "slug"
      ];
      
      
      for (let field of fields) {
        register(field);
        setValue(field, specialite[field]);
      }
    } 
  }, [specialite]);

  const onSubmit = (data: SpecialiteFormData) => {
    console.log("data", data);
    addData(data)
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset
  };
}

export default UseCrudSpecialiteForm;

export function UseDeleteSpecialite(slug: string) {
  const [deleteData] = useDeleteSpecialiteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette spécialité?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (!result?.value?.data?.error) {
          Swal.fire({
            icon: "success",
            title: `Spécialité supprimés avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result?.value?.data?.error?.message
              ? result?.value?.data?.error?.message
              : `Erreur de statut ${result?.value?.data?.error?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}