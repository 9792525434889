type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};

export type FieldType =
	| "dashboard"
	| "utilisateurs"
	| "parametres"
	| "objectifs"
	| "contenus"
	| "praticiens"
	| "condition"
export const Authorisations: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/admin/dashboard",
	},
	{
		field: "utilisateurs",
		label: "Utilisateurs",
		path: "/admin/utilisateurs",
	},
	{
		field: "objectifs",
		label: "Objectifs",
		path: "/admin/objectifs-et-actions",
	},
	{
		field: "contenus",
		label: "Contenus",
		path: "/admin/contenus",
	},
	{
		field: "praticiens",
		label: "Praticiens",
		path: "/admin/praticiens",
	},
	{
		field: "condition",
		label: "Condition",
		path: "/admin/parametres",
	},
	{
		field: "parametres",
		label: "Paramètre",
		path: "/admin/parametres",
	},
];
export const AuthorisationsRh: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/rh/dashboard",
	},
	{
		field: "utilisateurs",
		label: "Organisations",
		path: "/rh/organisations",
	},
	{
		field: "objectifs",
		label: "Objectifs",
		path: "/rh/objectifs-et-actions",
	},
	// {
	// 	field: "contenus",
	// 	label: "Contenus",
	// 	path: "/admin/contenus",
	// },
	{
		field: "parametres",
		label: "Profil",
		path: "/rh/mon-profil",
	},
];