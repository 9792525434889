import React from "react"
import { useStep } from "react-hooks-helper"
import { AiOutlinePlusSquare } from "react-icons/ai"
import { useStepType } from "../../../utils/type"
import BreadCrumb from "../../shared/BreadCrumb"
import "./ListeContenus.css"

import AddOrUpdateCitationModal from './Modals/AddOrUpdateCitationModal'
import AutoDiagnostique from './ContenusSteps/AutoDiagnostique'
import EvaluationBienEtre from './ContenusSteps/EvaluationBienEtre'
import AddOrUpdateAutoDiagnostiqueModal from './Modals/AddOrUpdateAutoDiagnostiqueModal'
import CrudEvalution from './Modals/CrudEvalution'
import { NavLink } from "react-router-dom"
import Thematique from "../Parametres/ParametresTabs/Thematique"
import { showModal } from "../../../utils/Utils"
import { BsPlusSquare } from "react-icons/bs"
import AddOrEditThematiqueModal from "../Parametres/Modals/AddOrEditThematiqueModal"


const steps = [
  // { id: "minutes-cares", Component: MinutesCares },
  { id: "theme", Component: Thematique },
  // { id: "minute-eval", Component: MinuteEval },
  { id: "auto-diagnostique", Component: AutoDiagnostique },
  { id: "eval-bien-etre", Component: EvaluationBienEtre },
];

const tabs = [
  // "Minutes cares",
  "Thématiques",
  // "Une minute d’évaluation",
  "Auto-diagnostic",
  "Évaluation bien-être",
];

function ListeContenus() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps })
  const { Component } = step

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Contenus" title="Contenus" />
        </div>
        {index === 0 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout"
              onClick={() => showModal("Add0rEditThematiqueModal")}
            >
              <BsPlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter une thématique
            </button>

            <div
              className="modal fade"
              id="Add0rEditThematiqueModal"
              aria-labelledby="Add0rEditThematiqueModalLabel"
              aria-hidden="true"
            >
              <AddOrEditThematiqueModal modalId="Add0rEditThematiqueModal" />
            </div>
          </>
        )}
        {/* {index === 0 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout-deux"
              data-bs-toggle="modal"
              data-bs-target="#AddOrUpdateMinuteEvalModal"
            >
              <AiOutlinePlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button>

            <div
              className="modal fade"
              id="AddOrUpdateMinuteEvalModal"
              aria-labelledby="AddOrUpdateMinuteEvalModalLabel"
              aria-hidden="true"
            >
              <AddOrUpdateMinuteEvalModal modalId="AddOrUpdateMinuteEvalModal" />
            </div>
          </>
        )} */}
        {index === 1 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout-deux"
              data-bs-toggle="modal"
              data-bs-target="#AddOrUpdateAutoDiagnostiqueModal"
            >
              <AiOutlinePlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button>

            <div
              className="modal fade"
              id="AddOrUpdateAutoDiagnostiqueModal"
              aria-labelledby="AddOrUpdateAutoDiagnostiqueModalLabel"
              aria-hidden="true"
            >
              <AddOrUpdateAutoDiagnostiqueModal modalId="AddOrUpdateAutoDiagnostiqueModal" />
            </div>
          </>
        )}
        {index === 2 && (
          <>
            <button
              className="workcare-dash-admin-btn-ajout-deux"
              data-bs-toggle="modal"
              data-bs-target="#AddOrEditEvaluationModal"
            >
              <AiOutlinePlusSquare className="workcare-dash-admin-btn-ajout-icone" />{" "}
              Ajouter
            </button>

            <div
              className="modal fade"
              id="AddOrEditEvaluationModal"
              aria-labelledby="AddOrEditEvaluationModalLabel"
              aria-hidden="true"
            >
              <CrudEvalution modalId="AddOrEditEvaluationModal" />
            </div>
          </>
        )}
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <div className="kanimmo-tabs-locataire-container">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="tabs-container pt-5 tabs-container-agence">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item  ${index === key && "tab-active-item"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>

          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListeContenus
