import { Specialite } from "../specialite/specialite.type"

export enum UserType {
  admin = "admin",
  superAdmin = "superadmin",
  praticien = "praticien",
  rh = "rh",
  salarie = "salarie",
}

export type CiviliteType = "homme" | "femme"

export interface IUser {
  id: number;
  slug: string | any;
  email: string;
  nom: string;
  prenom: string;
  password: string;
  date_naissance: string;
  user_type: UserType;
  civilite: CiviliteType;
  telephone: string;
  avatar: string;
  adresse: string;
  first_connexion: boolean;
  created_at: string;
  last_login: string;
  role: string;
  username: string;
  nom_entreprise: string;
  specialite: Specialite;
  service: string;
  experience: string;
  bio: string;
  is_active: boolean;
  is_archive: boolean;
  password_reset_count: number;
  deletion_id;
  // condition: any
  sous_type: any;
  rh: any;
  entreprise: any[];
  objectifs: boolean;
  contenus: boolean;
  praticiens: boolean;
  condition: boolean;
  dashboard: boolean;
  utilisateurs: boolean;
  parametres: boolean;
  nb_mutuelles: number;
  nb_salaries: number;
}

export type UserFormData = Partial<{
  id: number;
  slug: string | any;
  email: string;
  nom: string;
  prenom: string;
  password: string;
  date_naissance: string;
  user_type: UserType;
  civilite: CiviliteType;
  telephone: string;
  avatar: string;
  adresse: string;
  first_connexion: boolean;
  created_at: string;
  last_login: string;
  role: string;
  username: string;
  from: string;
  service: string;
  specialite: number | string;
  experience: string;
  bio: string;
  is_active: boolean;
  is_archive: boolean;
  password_reset_count: number;
  deletion_id;
  // condition: any
  sous_type: any;
  rh: any;
  entreprise: any[];
  objectifs: boolean;
  contenus: boolean;
  praticiens: boolean;
  condition: boolean;
  dashboard: boolean;
  utilisateurs: boolean;
  parametres: boolean;
  
}>;

export interface AuthState {
  user: IUser | any
  token?: string | null
}

export type PaginationResults<T> = {
  count: number
  next?: string | null
  previous?: string | null
  results: T[]
}

export type TypeQuery = Partial<{
  page?: number
  limit?: number
  word?: string | null
  entreprise?: string | null
  categorie?: string | null
  slug?: string
  slugRh?: string
  name?: string | null
  q?: string | null;
  date?:string | Date;
  frequence?: string
}>

export interface SuppressionType {
  id: number;
  slug: string;
  password: string;
}

export type SuppressionTypeFromData = Partial<SuppressionType>;

export interface IDashboardPraticien {
  patient_tms_count: number,
  rdv_count: number,
  patient_count: number
}

export interface IDashboardRh {
  rdv_count: number,
  objectifs_count: number,
  salaries_count: number,
  percentage_prise_en_charge: number,
  percentage_non_prise_en_charge: number,
  total_prise_en_charge: number,
  total_non_prise_en_charge: number,
  total_salaries: number,
  total_salaries_engages: number,
  graph_auto_diagnostic: any[],
  graph_user_do_eval: any[]
}
