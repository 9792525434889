import React, { useState } from "react";
import { AiFillEyeInvisible } from "react-icons/ai";
import { RiEyeLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import { BtnSubmit } from "../common/Button";
import ErrorMessage from "../common/ErrorMessage";
import useRegisterForm from "./useAuthForm/useRegisterForm";
import PhoneInput from "react-phone-input-2";
import { SpecialiteType } from "../../utils/api/auth/auth.type";

const SpecialiteOption = [
  {
    value: SpecialiteType.ostéopathe,
    label: "Ostéopathe",
  },
  {
    value: SpecialiteType.psychologue,
    label: "Psychologue",
  },
  {
    value: SpecialiteType.kinésithérapeute,
    label: "Kinésithérapeute",
  },
  {
    value: SpecialiteType.sophrologue,
    label: "Sophrologue",
  },
  {
    value: SpecialiteType.diététicien,
    label: "Diététicien",
  },
  {
    value: SpecialiteType.ergothérapeute,
    label: "Ergothérapeute",
  },
  {
    value: SpecialiteType.ergonome,
    label: "Ergonome",
  },
  {
    value: SpecialiteType.coach_sportif,
    label: "Coach sportif",
  },
  {
    value: SpecialiteType.autre,
    label: "Autre",
  },
];

export const RegisterForm = ({
  onSubmit,
  errors,
  register,
  setValue,
  isLoading,
  previewAvatar,
  handelAvatarChange,
  phone,
  handleChangePhone,
  specialites,
}) => {
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const [PasswordInput, setPasswordInput] = React.useState();

  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPasswordInput(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirPassword, setShowConfirPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const toggleShowConfirPassword = () => {
    setShowConfirPassword((showConfirPassword) => !showConfirPassword);
  };

  const [showAutre, setShowAutre] = useState(false);
  const [showSpecialite, setShowSpecialite] = useState(false);
  const [showNomEntreprise, setShowNomEntreprise] = useState(false);
  const [showNomSalarie, setShowNomSalarie] = useState(false);
  const [showNomMutuelle, setShowNomMutuelle] = useState(false);

  const handleChangeUserType = (e: any) => {
    // console.log("e.target.value", e.target.value);
    if (e.target.value !== undefined) {
      setShowAutre(true);
      if (e.target.value === "rh") {
        setShowNomEntreprise(true);
        setShowNomSalarie(true);
        setShowNomMutuelle(true);
        setShowSpecialite(false);
      } else {
        setShowNomEntreprise(false);
        setShowNomSalarie(false);
        setShowNomMutuelle(false);
        setShowSpecialite(true);
      }
    }
  };

  return (
    <div className=" content-auth-form">
      <form id="auth-form" onSubmit={onSubmit}>
        <div className="row auth-form-row">
          <div className="col-md-6 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">
                Prénom
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <input
                type={`text`}
                className="form-control auth-form-control"
                id="prenom"
                aria-label="Prenom"
                aria-describedby="basic-addon1"
                placeholder="Prénom"
                {...register("prenom")}
              />
              {errors?.prenom && (
                <ErrorMessage message={errors?.prenom?.message} />
              )}
            </div>
          </div>
          <div className="col-md-6 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">
                Nom
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <input
                type={`text`}
                className="form-control auth-form-control"
                id="nom"
                aria-label="Nom"
                aria-describedby="basic-addon1"
                placeholder="Nom"
                {...register("nom")}
              />
              {errors?.nom && <ErrorMessage message={errors?.nom?.message} />}
            </div>
          </div>
          <div className="col-md-6 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">
                Email professionnel
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <input
                type={`email`}
                className="form-control auth-form-control"
                id="email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                placeholder="Email professionnel"
                {...register("email")}
              />
              {errors?.email && (
                <ErrorMessage message={errors?.email?.message} />
              )}
            </div>
          </div>
          <div className="col-md-6 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">
                Téléphone
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <PhoneInput
                
                containerClass="form-control auth-form-control mb-3 p-0"
                inputClass="form-control auth-form-control"
                country={"fr"}
                placeholder=""
                inputProps={{
                  name: "telephone",
                  required: true,
                  // autoFocus: true,
                }}
                countryCodeEditable={false}
                enableAreaCodes={true}
                prefix="+"
                value={phone}
                onChange={(phone, country: any) => {
                  handleChangePhone(phone, country);
                }}
                inputStyle={{
                  width: "100%",
                  paddingBottom: "22px",
                  borderRadius: "10px",
                }}
              />
              {errors?.telephone && (
                <ErrorMessage message={errors?.telephone?.message} />
              )}
            </div>
          </div>
          <div className="col-md-6 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">
                Genre
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <select
                id="civilite"
                className="form-select auth-form-control auth-form-select"
                {...register("civilite")}
              >
                <option value="" selected disabled>
                  Genre
                </option>
                <option value="homme">Homme</option>
                <option value="femme">Femme</option>
              </select>
              {errors?.civilite && (
                <ErrorMessage message={errors?.civilite?.message} />
              )}
            </div>
          </div>
          <div className="col-md-6 auth-input-col">
            <div className="form-group auth-form-group auth-form-group-login">
              <label className="form-label-login">
                Type d'utilisateur
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <select
                id="user_type"
                className="form-select auth-form-control auth-form-select"
                {...register("user_type")}
                onChange={handleChangeUserType}
              >
                <option value="" selected disabled>
                  Type d'utilisateur
                </option>
                <option value="rh">RH</option>
                <option value="praticien">Praticien</option>
              </select>
              {errors?.user_type && (
                <ErrorMessage message={errors?.user_type?.message} />
              )}
            </div>
          </div>
          {showAutre && showSpecialite && (
            <div className="col-md-6 auth-input-col">
              <div className="form-group auth-form-group auth-form-group-login">
                <label className="form-label-login">Spécialité</label>
                <select
                  id="specialite"
                  className="form-select auth-form-control auth-form-select"
                  {...register("specialite")}
                >
                  <option value="">Spécialité</option>
                  {specialites?.map((option) => (
                    <option value={option?.id} key={option?.slug}>
                      {option?.nom}
                    </option>
                  ))}
                </select>
                {errors?.specialite && (
                  <ErrorMessage message={errors?.specialite?.message} />
                )}
              </div>
            </div>
          )}
          {showAutre && showNomEntreprise && (
            <div className="col-md-6 auth-input-col">
              <div className="form-group auth-form-group auth-form-group-login">
                <label className="form-label-login">
                  Nom entreprise
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <input
                  type={`text`}
                  className="form-control auth-form-control"
                  id="nom_entreprise"
                  aria-label="nom_entreprise"
                  aria-describedby="basic-addon1"
                  placeholder="Nom entreprise"
                  {...register("nom_entreprise")}
                />
                {errors?.nom_entreprise && (
                  <ErrorMessage message={errors?.nom_entreprise?.message} />
                )}
              </div>
            </div>
          )}

          {showAutre && showNomSalarie && (
            <div className="col-md-6 auth-input-col">
              <div className="form-group auth-form-group auth-form-group-login">
                <label className="form-label-login">
                  Nombre de salariés
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <input
                  type={`number`}
                  className="form-control auth-form-control"
                  id="nb_salaries"
                  aria-label="nb_salaries"
                  aria-describedby="basic-addon1"
                  placeholder="Nombre de salariés"
                  {...register("nb_salaries")}
                  min={0}
                />
                {errors?.nb_salaries && (
                  <ErrorMessage message={errors?.nb_salaries?.message} />
                )}
              </div>
            </div>
          )}

          {/* {showAutre && showNomMutuelle && (
            <div className="col-md-6 auth-input-col">
              <div className="form-group auth-form-group auth-form-group-login">
                <label className="form-label-login">
                  Nombre de mutuelles
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <input
                  type={`number`}
                  className="form-control auth-form-control"
                  id="nb_mutuelles"
                  aria-label="nb_mutuelles"
                  aria-describedby="basic-addon1"
                  placeholder="Nombre de mutuelles"
                  {...register("nb_mutuelles")}
                  min={0}
                />
                {errors?.nb_mutuelles && (
                  <ErrorMessage message={errors?.nb_mutuelles?.message} />
                )}
              </div>
            </div>
          )} */}

          <div className="col-md-6 auth-input-col ">
            <div className="form-group auth-form-group auth-form-group-login position-relative">
              <label className="form-label-login">
                Mot de passe
                <span className="text-danger" style={{ fontSize: 24 }}>
                  *
                </span>
              </label>
              <input
                type={`${showPassword ? "text" : "password"}`}
                className="form-control auth-form-control"
                id="password"
                data-testid="passwordId"
                aria-label="Password"
                aria-describedby="basic-addon1"
                {...register("password")}
                onChange={passwordHandleChange}
              />
              <span
                className="span-eye-password-log"
                onClick={toggleShowPassword}
              >
                {!showPassword ? <RiEyeLine /> : <AiFillEyeInvisible />}
              </span>
            </div>
            {errors?.password && (
              <ErrorMessage message={errors?.password?.message} />
            )}
          </div>
          <div className="col-md-6 auth-input-col ">
            <div className="form-group auth-form-group auth-form-group-login position-relative">
              <label className="form-label-login">
                Confirmez le mot de passe{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={`${showConfirPassword ? "text" : "password"}`}
                className="form-control auth-form-control"
                id="confirm-password"
                {...register("confirmPassword")}
              />
              <span
                className="span-eye-password-log"
                onClick={toggleShowConfirPassword}
              >
                {!showConfirPassword ? <RiEyeLine /> : <AiFillEyeInvisible />}
              </span>
            </div>
            {errors?.confirmPassword && (
              <ErrorMessage message={errors?.confirmPassword?.message} />
            )}
            {/* <span className="span-eye-password">
                  <AiFillEyeInvisible />{" "}
                </span> */}
          </div>

          {/* ============= Validation password ============= */}

          {PasswordInput && (
            <div className="col-md-12">
              <div className="input-group my-2 password-validation-terms-row">
                <div className="password-validation-terms-container">
                  <h4 className="password-validation-terms-title">
                    Votre mot de passe doit contenir :{" "}
                  </h4>
                  <ul className="password-validation-terms-ul p-l-14">
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinCharacter && "text-success")
                      }
                    >
                      Au minimum 8 caractères
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinUppercase && "text-success")
                      }
                    >
                      Au minimum 1 caractère en majuscule
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinLowercase && "text-success")
                      }
                    >
                      Au minimum 1 caractère en minuscule
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinNumber && "text-success")
                      }
                    >
                      Au minimum 1 nombre
                    </li>
                    <li
                      className={
                        "password-rule-item " +
                        (haveMinSpecialCharacter && "text-success")
                      }
                    >
                      Au minimum 1 caractère spéciale
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">Photo</label>
              <input
                type="file"
                className="form-control auth-form-control"
                id="avatar"
                accept="image/*"
                onChange={(e) => handelAvatarChange(e)}
                name="avatar"
              />
              {errors?.avatar && (
                <ErrorMessage message={errors?.avatar?.message} />
              )}
              {previewAvatar && (
                <div className="img-preview-container mb-3">
                  <img
                    src={previewAvatar}
                    className="type-img-preview"
                    alt="photos avatar"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-4">
            <BtnSubmit
              className="btn auth-submit-btn-login trans-0-2"
              type="submit"
              label="S'inscrire"
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

function Register() {
  const {
    register,
    onSubmit,
    setValue,
    errors,
    isLoading,
    handelAvatarChange,
    previewAvatar,
    phone,
    handleChangePhone,
    specialites,
  } = useRegisterForm();
  return (
    <div className="auth-component">
      <div className="empty-header-login fixed-top">
        <div className="content-logo-empty-header-login">
          <NavLink to={"/"} className="no-link">
            <img
              src={Logo}
              alt="Logo"
              className="img-logo-empty-header-login"
              style={{ width: 217 }}
            />
          </NavLink>
        </div>
      </div>
      <div className="fixed-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-5 auth-col auth-left-side-col"></div>
              <div className="col-md-7 auth-col auth-right-side-col">
                <div className="register-right-side-container ">
                  <div className="auth-form-container">
                    <div className="content-text-redirect-auth ">
                      <span className="info-connect-redirection">
                        Vous avez déjè un compte ?
                      </span>
                      <NavLink to="/" className="redirect-text-auth ps-1">
                        Connectez-vous
                      </NavLink>
                    </div>
                    <h1 className="auth-form-title">Inscription</h1>
                    <RegisterForm
                      register={register}
                      onSubmit={onSubmit}
                      errors={errors}
                      setValue={setValue}
                      isLoading={isLoading}
                      handelAvatarChange={handelAvatarChange}
                      previewAvatar={previewAvatar}
                      phone={phone}
                      handleChangePhone={handleChangePhone}
                      specialites={specialites}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
