import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { ApiBaseUrl } from "../../http"
import { QueryUrl } from "../../Utils"
import { EvenementApi } from "../evenement/evenement.api";
import { prepareHeaders } from "../user/user.api";
import {
  AuthState,
  PaginationResults,
  TypeQuery,
} from "../user/user.type"
import { DisponibiliteFormData, IDisponibilite } from "./disponibilite.type"

export const DisponibiliteApi = createApi({
  reducerPath: "disponibiliteApi",
  tagTypes: ["disponibilite"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrUpdateDisponibilite: builder.mutation<
      IDisponibilite,
      { slug?: string; data: DisponibiliteFormData | FormData }
    >({
      invalidatesTags: ["disponibilite"],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            EvenementApi.util.invalidateTags([
              "evenementEntreprise"
            ])
          );
        }, 1000);
      },
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `disponibilites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `disponibilites/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getDisponibilite: builder.query<
      PaginationResults<IDisponibilite>,
      TypeQuery
    >({
      query: (query) => QueryUrl(`disponibilites/`, query),
      providesTags: ["disponibilite"],
    }),
    getDisponibiliteByPraticien: builder.query<IDisponibilite[], TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`praticien/${slug}/disponibilites/`, query),
      providesTags: ["disponibilite"],
    }),

    deleteDisponibilite: builder.mutation<DisponibiliteFormData, string>({
      query: (slug) => ({
        url: `disponibilites/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["disponibilite"],
    }),
  }),
});

export const {
    useAddOrUpdateDisponibiliteMutation,
    useDeleteDisponibiliteMutation,
    useGetDisponibiliteQuery,
    useGetDisponibiliteByPraticienQuery
} = DisponibiliteApi
