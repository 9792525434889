import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { useDelete } from "../../../../../utils/helpers";
import {
  ObjectifFormData,
  ObjectifType,
} from "../../../../../utils/api/objectif/objectif.types";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useAddObjecftifActionMutation,
  useDeleteObjectifActionMutation,
} from "../../../../../utils/api/objectif/objectif.api";
import { isAdminUser } from "../../../../../routes/router";
import { useGetRhQuery } from "../../../../../utils/api/user/user.api";

function UseCrudObjectifAction(
  modalId: string,
  objectif?: ObjectifType,
  isFromDetail?: boolean
) {
  const validationSchema = yup.object().shape({
    isAdmin: yup.boolean(),
    titre: yup.string().required().label("Titre"),
    contenu: yup.string().required().label("Contenu"),
    rh: yup
      .string()
      .when("isAdmin", {
        is: true,
        then: yup.string().required().label("L'entreprise").nullable(),
      })
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<ObjectifFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [addObjecftifAction, { isLoading, isSuccess }] =
    useAddObjecftifActionMutation();
  const [word, setWord] = useState("");
  const { data = { results: [], count:0 } } = useGetRhQuery({
    limit: 100,
    word,
  });
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<{ label: string; value: number }>();
  const [description, setDescription] = React.useState<any>("");
  let navigate = useNavigate();
  const { user } = useAppSelector((s) => s.user);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((item) => {
          return {
            label: item?.nom_entreprise || item?.prenom + " " + item?.nom,
            value: item?.id,
          };
        })
      );
    } else {
      setOptions([]);
    }
  }, [data]);

  const handleInputChange = (val) => {
    setWord(val);
  };

  const onChangeEntreprise = (selected: any) => {
    setOption(selected);
    setValue("rh", selected?.value);
  };

  const handleChange = (value: any) => {
    setDescription(value);
    setValue("contenu", value);
  };

  useEffect(() => {
    setValue("isAdmin", isAdminUser(user));
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !objectif
          ? "Objectif a été ajouté avec succès"
          : "Objectif a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        isFromDetail && navigate(`/rh/objectifs`);
        onHide(modalId);
        handleReset()
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log(errors)
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    // console.log("isFromDetail", isFromDetail);
    if (objectif?.id) {
      let fields: (keyof ObjectifFormData)[] = ["titre", "contenu", "rh"];
      for (let field of fields) {
        register(field);
        if (field !== "rh") {
          setValue(field, objectif[field]);
        } else {
          setValue(field, objectif[field]?.id);
        }
      }
      setDescription(objectif?.contenu || "");

      if (objectif?.rh?.id) {
        const entreprise = {
          label:
            objectif?.rh?.nom_entreprise ||
            objectif?.rh?.prenom + " " + objectif?.rh?.nom,
          value: objectif?.rh?.id,
        };
        setOption(entreprise);
      }
    }
  }, [objectif]);

  const handleReset = () => {
    if (!objectif) {
      reset();
      setDescription("");
      setOption(undefined)
    }
  };

  const onSubmit = (data: ObjectifFormData) => {
    if (!objectif && !isAdminUser(user)) {
      data.rh = user?.id;
    }
    addObjecftifAction({ slug: objectif?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    objectif,
    reset,
    handleReset,
    description,
    setDescription,
    handleChange,
    options,
    option,
    handleInputChange,
    onChangeEntreprise,
    Controller,
    control,
    isAdmin: isAdminUser(user)
  };
}

export function DeleteObject({ item }: { item: ObjectifType }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteObjectifActionMutation();
  const onDelete = useDelete<ObjectifType>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cet objectif ",
    successMessage: "Objectif supprimé",
  });
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseCrudObjectifAction;
