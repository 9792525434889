import React, { useState } from "react";
import "./DashboardAdmin.css";
import Icon1 from "../../../assets/icons/icon.png";
import Icon2 from "../../../assets/icons/icon2.png";
import Icon3 from "../../../assets/icons/icon3.png";
import Icon4 from "../../../assets/icons/Icon4.png";
import Icon5 from "../../../assets/icons/Icon5.png";
import Icon6 from "../../../assets/icons/Icon6.png";
import Icon7 from "../../../assets/icons/Icon7.png";
import Icon8 from "../../../assets/icons/Icon8.png";
import Icon9 from "../../../assets/icons/Icon9.png";
import Icon10 from "../../../assets/icons/Icon10.png";
import EvolutionGraph from "./Graph/EvolutionGraph";
import ProgressGraph from "./Graph/ProgressGraph";
import PerformanceGraph from "./Graph/PerformanceGraph";
import { MdThumbDownAlt, MdThumbUp } from "react-icons/md";
import { useGetDashboardAdminQuery } from "../../../utils/api/dashboard/dashboard.api";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import VerbatimGraph from "./Graph/VerbatimGraph";

const CardSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill(3)
        .map((shopCard, i) => (
          <div className="col-md-4 col-stat-dashboard-visiteur mb-3" key={i}>
            <div className="content-col-stat-dashboard-admin">
              <div className="content-img-folder-dashboard-visiteur">
                <Skeleton
                  width={66}
                  height={66}
                  className="img-folder-dashboard-visiteur"
                />
              </div>
              <div className="info-user-admin-stat-dashboard">
                <Skeleton
                  className="text-stat-dashboard-visiteur mb-1"
                  width={120}
                />
                <Skeleton
                  className="text-chiffre-dashboard-admin mb-0"
                  style={{ color: "#F4925B" }}
                  width={45}
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
const DashboardAdmin = () => {
  const [frequence, setFrequence] = useState("year");
  const [date, setDate] = useState<Date | string>(new Date());
  const { data, isLoading } = useGetDashboardAdminQuery({
    date: moment(date).format("DD-MM-YYYY"),
    frequence: frequence,
  });

  return (
    <div className="dashbord-visiteur-component">
      <div className="row row-card-stat-visiteur mb-3">
        {!!isLoading && <CardSkeleton />}
        {!isLoading && (
          <>
            {/* <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon4}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Entreprises engagées</p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#F4925B" }}
                  >
                    {data?.salaries_engages || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon5}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Mutuelles engagées
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#12CBD6" }}
                  >
                    {data?.rdv || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon6}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Praticiens engagées
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#795BF4" }}
                  >
                    {data?.total_salaries || 0}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon7}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Entreprises réengagées</p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#00538E" }}
                  >
                    {data?.salaries_engages || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon5}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Mutuelles engagées
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#12CBD6" }}
                  >
                    {data?.rdv || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon6}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Praticiens réengagées
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#795BF4" }}
                  >
                    {data?.total_salaries || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Effectif total de l’entreprise
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#795BF4" }}
                  >
                    {data?.total_salaries || 0}
                  </p>
                </div>
              </div>
            </div> */}

            
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon1}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Salariés</p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#F4925B" }}
                  >
                    {data?.salaries_engages || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon2}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                    Rendez-vous
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#12CBD6" }}
                  >
                    {data?.rdv || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                    Total salarié
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#795BF4" }}
                  >
                    {data?.total_salaries || 0}
                  </p>
                </div>
              </div>
            </div>


          </>
        )}
      </div>

      {/* <hr />
      <div className="row row-card-stat-visiteur mb-4">
        {!isLoading && (
          <>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Salariés inscrits (H/F)</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon8}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Salariés engagés (H/F)</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#D86D00" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon9}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés suivis (H/F)</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#12CBD6" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon10}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Comptes-rendus envoyés
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#333333" }}
                  >
                    {data?.rdv || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Salariés suivis (H/F)</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon2}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">
                  Rendez-vous à venir
                  </p>
                  <p
                    className="text-chiffre-dashboard-admin mb-0"
                    style={{ color: "#333333" }}
                  >
                    {data?.total_salaries || 0}
                  </p>
                </div>
              </div>
            </div>


            </>
        )
}
      </div>

      <div className="row row-card-stat-visiteur mb-4">
        {!isLoading && (
          <>
            <div className="col-md-12 col-stat-dashboard-visiteur mb-3">
              <h2 className="titre-evolution-sante">Évolution santé et bien-être</h2>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés souffrant de TMS </p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés suivis/pris en charge (H/F) pour les TMS</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés en stress, anxiété, dépression</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés suivis/pris en charge (H/F) pour la santé mentale (stress, anxiété, dépression, nervosité …)</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés en mal-être (H/F) + par service</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés malades (H/F) + par service</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-stat-dashboard-visiteur mb-3">
              <div className="content-col-stat-dashboard-admin">
                <div className="content-img-folder-dashboard-visiteur">
                  <img
                    src={Icon3}
                    alt="dashboard visiteur"
                    className="img-folder-dashboard-visiteur"
                  />
                </div>
                <div className="info-user-admin-stat-dashboard">
                  <p className="text-stat-dashboard-visiteur mb-1">Nombre et % de salariés en mal-être (H/F) + par service</p>
                  <div className="text-chiffre-pourcentage-container">
                    <p
                      className="text-chiffre-dashboard-admin mb-0"
                      style={{ color: "#333333" }}
                    >
                      {data?.salaries_engages || 0}
                    </p>
                    <div className="text-pourcentage-dashboard-admin" style={{ color: "#795BF4" }}>26,4%</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        </div> */}

      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
              <EvolutionGraph
                stats={data?.graph_auto_diagnostic}
                date={date}
                setDate={setDate}
                setFrequence={setFrequence}
                frequence={frequence}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row row-dashboard-visiteur mb-4">
        <div className="col-md-12 col-left-dashboard-visiteur mb-3">
          <div className="content-col-left-dashboard-visiteur">
            <div className="content-col-graph-admin">
            <VerbatimGraph
              stats={data?.graph_verbatim}
              isLoading={isLoading}
            />
            </div>
          </div>
        </div>
      </div>

      <div className="row row-graph-admin">
        {/* <div className="col-md-12 col-stat-dashboard-visiteur mb-3">
          <h2 className="titre-evolution-sante">Indicateurs performances & évolution : Satisfaction et Effort</h2>
        </div> */}
        <div className="col-md-6 col-left-graph-admin mb-3 d-flex">
          <div className="content-col-graph-admin content-col-left-graph-admin w-100">
            <ProgressGraph
              stats={data?.graph_user_do_eval}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="col-md-6 col-left-graph-admin mb-3 d-flex">
          <div className="content-col-graph-admin content-col-left-graph-admin w-100">
            <div className="row mb-3">
              <div className="col-md-12 mb-2">
                <h2 className="chart-item-admin-title-admin mb-0">
                Ces_Indicateurs de performances
                </h2>
              </div>
            </div>
            <div className="container-graph-donut flex-sb justify-content-center">
              <div className="content-graph-donut1 text-center">
                <p className="text-describe-graph-donut mb-4">
                CES_Facilitation (suivi & prise en charge/Praticien)
                </p>
                <PerformanceGraph
                  stats={{
                    prise_en_charge: data?.percentage_prise_en_charge,
                    non_prise_en_charge: data?.percentage_non_prise_en_charge,
                  }}
                  isLoading={isLoading}
                />
                {!isLoading && (
                  <div className="content-legend-donut-graph mt-4">
                    <div
                      className="content-legend-donut-graph-item"
                      title="Prise en charge"
                    >
                      <MdThumbUp style={{ color: "#0DCFB8", fontSize: 20 }} />
                      <span className="text-legend-donut-graph">
                        {!!data && data?.percentage_prise_en_charge}%{" "}
                      </span>
                    </div>
                    <div
                      className="content-legend-donut-graph-item"
                      title="Non prise en charge"
                    >
                      <MdThumbDownAlt
                        style={{ color: "#F45B5B", fontSize: 20 }}
                      />
                      <span className="text-legend-donut-graph">
                        {data?.percentage_non_prise_en_charge}%{" "}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="content-graph-donut1 text-center">
                <p className="text-describe-graph-donut mb-4">
                CES_Satisfaction prise en charge entreprise
                </p>
                <PerformanceGraph
                  stats={{
                    prise_en_charge: data?.percentage_prise_en_charge,
                    non_prise_en_charge: data?.percentage_non_prise_en_charge,
                  }}
                  isLoading={isLoading}
                />
                {!isLoading && (
                  <div className="content-legend-donut-graph mt-4">
                    <div className="content-legend-donut-graph-item">
                      <MdThumbUp style={{ color: "#0DCFB8", fontSize: 20 }} />
                      <span className="text-legend-donut-graph">60% </span>
                    </div>
                    <div className="content-legend-donut-graph-item">
                      <MdThumbDownAlt
                        style={{ color: "#F45B5B", fontSize: 20 }}
                      />
                      <span className="text-legend-donut-graph">40% </span>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
