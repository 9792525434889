import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useAddOrEditUserMutation, useDeleteProfilMutation } from "../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../redux/slice/User.slice";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import UseEditUserForm from "../../Praticien/MonProfil/useForm/UseEditUserForm";
import { getAvatar, getName } from "../../../../utils/Utils";
import { BsCameraFill } from "react-icons/bs";
import ChangePasswordModal from "../../../admin/MonCompte/Modals/ChangePasswordModal";
import SupprimerProfilModal from "../../../admin/MonCompte/Modals/SupprimerProfilModal";
import ModifierInfosPersoRhModal from "./Modals/ModifierInfosPersoRhModal";
import { FaShareSquare, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./ProfilRh.css";


function ProfilRh() {
  const user = useAppSelector((s) => s?.user?.user);
  const [avatar, setAvatar] = useState<any>(null);
  const { errors } = UseEditUserForm("rh", "");
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const dispatch = useAppDispatch();
  // const [deleteProfil] = useDeleteProfilMutation();


  useEffect(() => {
    if (done) {
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, data: fd });
  };

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);


  function ouvrirGmail() {
    var url = "https://mail.google.com/mail/?view=cm&fs=1&to=";
    
    window.open(url, "_blank");
  }

  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="workcare-dash-admin-titre-container">
            <h4 className="workcare-dash-admin-titre-deux">Profil</h4>
          </div>
          <div className="content-pp-profil-visiteur text-center mb-3">
            <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
              <label htmlFor="input-test" className="input-testt">
                <img
                  src={avatar || getAvatar(user?.avatar)}
                  alt="profil utilisateur"
                  className="pp-profil-user-visiteur"
                />
                <div className="leader-auto-user-avatar-edit-hover">
                  {load ? (
                    <span
                      className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                      role="status"
                    ></span>
                  ) : (
                    <BsCameraFill className="edit-avatar-camera-icon" />
                  )}
                </div>
              </label>
              <input
                type="file"
                className="input-add-avatar"
                id="input-test"
                onChange={(e) => changeImage(e)}
              />
            </div>
            <div className="content-text-name-user">
              <h2 className="custom-name-user-admin">{getName(user)}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <div>
                <div className="workcare-dash-admin-section-edit-profil-container">
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Prénom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {user?.prenom !== undefined && user.prenom !== null && user.prenom !== "null"
                          ? user.prenom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Nom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {user?.nom !== undefined && user.nom !== null && user.nom !== "null"
                          ? user.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Age
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        23 ans
                      </div>
                      
                    </div>
                  </div>
                  <hr /> */}
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Téléphone
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {user?.telephone !== undefined &&
                        user.telephone !== null && user.telephone !== "null"
                          ? user.telephone
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Adresse mail
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {user?.email !== undefined && user.email !== null && user.email !== "null"
                          ? user.email
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="flex-r mb-3">
                    <button
                      className="btn btn-change-password"
                      data-bs-toggle="modal"
                      data-bs-target="#changeInfosPerso"
                    >
                      Modifier
                    </button>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="changeInfosPerso"
                  aria-labelledby="changeInfosPersoLabel"
                  aria-hidden="true"
                >
                  <ModifierInfosPersoRhModal
                    modalId="changeInfosPerso"
                    user={user}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4 footer-btn-profil">
              <h5 className="workcare-dash-admin-titre-secondaire-deux">
                Code Rh: <span className="custom-code-rh">{user?.rh_code}</span>
              </h5>
              {/* <button className="btn btn-change-password" onClick={ouvrirGmail}>Partager <FaShareSquare /></button> */}
              <Link className="btn btn-change-password" to={"mailto:?subject=Joyatwork Code Rh "+user?.rh_code+"&body= %0D%0A Bonjour %0D%0A Veuillez trouvez le code RH "+user?.rh_code+" à renseigner lors de votre inscription sur la plateforme Joyatwork %0D%0A %0D%0A Bien à vous"} target="_blank">Partager</Link>
          </div>
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4 footer-btn-profil">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire-deux">
                Mot de passe
              </h5>
              <div className="workcare-dash-admin-profile-section-password">
                <button
                  className="btn btn-change-password"
                  data-bs-toggle="modal"
                  data-bs-target="#ChangePasswordModal"
                >
                  Changer mot de passe
                </button>
              </div>
              <ChangePasswordModal />
            </div>
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire-deux">
                Supprimer mon profil
              </h5>
              <div className="workcare-dash-admin-profile-section-password">
                <button
                  className="btn btn-supprimer-profil with-tooltip"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteProfileModale"
                >
                  Supprimer profil <FaTrash />
                </button>
              </div>
              <SupprimerProfilModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilRh;
