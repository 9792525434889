import { ObjectifApi } from "./../utils/api/objectif/objectif.api";
import { configureStore } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import { AdminApi } from "../utils/api/admin/admin.api";
import ConditionApi from "../utils/api/condition/condition.api";
import ContenusApi from "../utils/api/contenus/contenus.api";
import RdvApi from "../utils/api/rdv/rdv.api";
import { DisponibiliteApi } from "../utils/api/disponibilite/disponibilite.api";
import { MinuteEvalApi } from "../utils/api/minuteEval/minuteEval";
import { AutoDiagnostiqueApi } from "../utils/api/autoDiagnostique/autoDiagnostique";
import { EvalBienEtreApi } from "../utils/api/evalBienEtre/EvalBienEtre";
import { EvenementApi } from "../utils/api/evenement/evenement.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { CompteRenduApi } from "../utils/api/compteRendu/compteRendu.api";
import { SpecialiteApi } from "../utils/api/specialite/specialite.api";
import { DocumentApi } from "../utils/api/document/document.api";
import { DashboardApi } from "../utils/api/dashboard/dashboard.api";
import { QuestionDiagnostiqueApi } from "../utils/api/questions/question.api";
import { ThematiqueApi } from "../utils/api/thematique/thematique";


const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [ObjectifApi.reducerPath]: ObjectifApi.reducer,
    [AdminApi.reducerPath]: AdminApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [ContenusApi.reducerPath]: ContenusApi.reducer,
    [RdvApi.reducerPath]: RdvApi.reducer,
    [DisponibiliteApi.reducerPath]: DisponibiliteApi.reducer,
    [EvenementApi.reducerPath]: EvenementApi.reducer,
    [MinuteEvalApi.reducerPath]: MinuteEvalApi.reducer,
    [AutoDiagnostiqueApi.reducerPath]: AutoDiagnostiqueApi.reducer,
    [EvalBienEtreApi.reducerPath]: EvalBienEtreApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [CompteRenduApi.reducerPath]: CompteRenduApi.reducer,
    [SpecialiteApi.reducerPath]: SpecialiteApi.reducer,
    [DocumentApi.reducerPath]: DocumentApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [QuestionDiagnostiqueApi.reducerPath]: QuestionDiagnostiqueApi.reducer,
    [ThematiqueApi.reducerPath]: ThematiqueApi.reducer,

  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    ObjectifApi.middleware,
    AdminApi.middleware,
    ConditionApi.middleware,
    ContenusApi.middleware,
    RdvApi.middleware,
    DisponibiliteApi.middleware,
    EvenementApi.middleware,
    MinuteEvalApi.middleware,
    AutoDiagnostiqueApi.middleware,
    EvalBienEtreApi.middleware,
    NotificationApi.middleware,
    ContactApi.middleware,
    CompteRenduApi.middleware,
    SpecialiteApi.middleware,
    DocumentApi.middleware,
    DashboardApi.middleware,
    QuestionDiagnostiqueApi.middleware,
    ThematiqueApi.middleware,
    
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
