import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Document } from "./document.type";

export const DocumentApi = createApi({
  reducerPath: "documentApi",
  tagTypes: ["documentsByPatient"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDocumentsByPatient: builder.query<
      PaginationResults<Document>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`salarie/${slug}/documents`, query),
      providesTags: ["documentsByPatient"],
    }),
  }),
});

export const {useGetDocumentsByPatientQuery} = DocumentApi