import React, { useState } from "react";
import { BtnCloseModal } from "../../../common/Button";
import UseCrudAutoDiagnostique from "../useForm/useCrudAutoDiagnostique";
import FormContent from "./FormContent";

function AddOrUpdateAutoDiagnostiqueModal({
  contenu,
  modalId,
}: {
  contenu?: any;
  modalId: string;
}) {
  const [inputFields, setInputFields] = useState([
    {
      response: "",
    },
  ]);
  const {
    register,
    errors,
    isLoading,
    handleChange,
    description,
    onSubmit,
    type,
    onSelectType,
    handelImageChange,
    previewImage,
    fields,
    append,
    remove,
  } = UseCrudAutoDiagnostique(modalId, inputFields, contenu);

  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content text-start">
        <div className="modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {contenu
              ? "Modifier l'auto diagnostic"
              : "Ajouter un auto diagnostic"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <FormContent
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              isLoading={isLoading}
              item={contenu}
              description={description}
              handleChange={handleChange}
              type={type}
              onSelectType={onSelectType}
              from="autoDiagnotique"
              handelImageChange={handelImageChange}
              preview={previewImage}
              setInputFields={setInputFields}
              inputFields={inputFields}
              theme={"autodiagnostique"}
              fields={fields}
              append={append}
              remove={remove}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateAutoDiagnostiqueModal;
