import React from "react";
import { createMarkup, getImage } from "../../../../utils/Utils";
import { BtnCloseModal } from "../../../common/Button";

function VoirCitationModal({
  contenu,
  modalId,
}: {
  contenu?: any;
  modalId: string;
}) {
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {"Détails de la citation"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <div className="row">
              <div className="col-md-12">
                <div className="detail-contenu-infos-container mb-3">
                  <div className="detail-contenu-infos-name">Titre:</div>
                  <div className="detail-contenu-infos-value">
                    {contenu?.titre}
                  </div>
                </div>
                <div className="detail-contenu-infos-container mb-3">
                  <div className="detail-contenu-infos-name">Ordre:</div>
                  <div className="detail-contenu-infos-value">
                    {contenu?.ordre}
                  </div>
                </div>
                <div className="detail-contenu-infos-container mb-3 gap-2 row text-start">
                  <div className="detail-contenu-infos-name col-md-12">
                    Citation:
                  </div>
                  <div
                    className="detail-contenu-infos-value"
                    dangerouslySetInnerHTML={createMarkup(contenu?.contenu)}
                  />
                </div>
                {!!contenu?.background_image && (
                  <div className="detail-contenu-infos-container mb-3 gap-2 row text-start">
                    <div className="detail-contenu-infos-name col-md-12">
                      Image:
                    </div>
                    <div>
                      <img
                        src={getImage(contenu?.background_image)}
                        alt="image background"
                        className="custom-img-bg-preview"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoirCitationModal;
