import { di } from "@fullcalendar/core/internal-common";
import React from "react";
import { Diagnostic } from "../../../../utils/api/autoDiagnostique/autoDiagnostique.type";
import { createMarkup, getImage } from "../../../../utils/Utils";
import { BtnCloseModal } from "../../../common/Button";

function ShowQuestionModal({ item }: { item: Diagnostic }) {
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content text-start">
        <div className="modal-header px-2 py-2 ">
          <h5 className="modal-title">
            {"Détails"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <div className="row">
              <div className="col-md-12">
                <div className="detail-contenu-infos-container mb-3">
                  <div className="detail-contenu-infos-name">Question:</div>
                  <div className="detail-contenu-infos-value">
                    {item?.contenu}
                  </div>
                </div>
                <div className="detail-contenu-infos-container mb-3">
                  <div className="detail-contenu-infos-name">Ordre:</div>
                  <div className="detail-contenu-infos-value">
                    {item?.ordre}
                  </div>
                </div>
                <div className="detail-contenu-infos-container mb-3 gap-2 text-start align-items-baseline">
                  <div className="detail-contenu-infos-name">
                    Légende:
                  </div>
                  <div
                    className="detail-contenu-infos-value"
                    dangerouslySetInnerHTML={createMarkup(item?.legende)}
                  />
                </div>
                {!!item?.background_image ? 
                  (<div className="detail-contenu-infos-container mb-3 gap-2 row text-start">
                    <div className="detail-contenu-infos-name col-md-12">
                      Image:
                    </div>
                    <div>
                      <img
                        src={getImage(item?.background_image)}
                        alt="image background"
                        className="custom-img-bg-preview"
                      />
                    </div>
                  </div> )
                  : 
                  (<div className="detail-contenu-infos-container mb-3 gap-2 row text-start">
                    <div className="detail-contenu-infos-name col-md-12">
                      Image:
                    </div>
                    <div>
                      <em>Aucune image ajoutée pour l'instant</em>
                    </div>
                  </div> )
                }
                <div className="custom-detail-contenu-infos-container mb-3">
                  <div className="detail-contenu-infos-name mb-3">Réponses:</div>
                  <div className="detail-contenu-infos-value ps-4">
                    {!item?.responses?.length && "Aucune réponse disponible"}
                    {!!item?.responses?.length &&
                      [...item?.responses]?.sort((a,b) => a?.id - b?.id)?.map((el, i) => (
                        <div>
                          Réponse {i + 1}: {el?.contenu}{" "}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowQuestionModal;
