import React from "react";
import { BiEditAlt } from "react-icons/bi";
import { useGetPolicyQuery } from "../../../../utils/api/condition/condition.api";
import { ICondition } from "../../../../utils/api/condition/condition.type";
import { createMarkup, showModal } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import AddOrUpdatePolitique from "../Modals/AddOrUpdatePolitique";
import { UseDeleteCondition } from "../useForm/UseCrudCondition";
import { IoMdTrash } from "react-icons/io";
import { FaTrash } from "react-icons/fa";

function PolitiqueConfidentialite() {
  const { data, isLoading } = useGetPolicyQuery({});
  return (
    <div>
      <ul className="workcares-custom-ul">
        {data?.results?.length === 0 && <AlertInfo message="Pas de données" />}
        {data?.results?.map((contenu) => (
          <li
            key={contenu?.id}
            className="mb-5 kanimmo-cond-utilisation-ol-custom-li"
          >
            <span className="custom-condition-title">
              {contenu?.type === "cgu"
                ? "Conditions générales d'utilisation"
                : contenu?.type === "technical"
                ? "Technical"
                : contenu?.type === "policy"
                ? "Politique de confidentialité"
                : contenu?.type === "aide"
                ? "Aide"
                : "Mention légale"}
            </span>
            <div className="kanimmo-cond-utilisation-ol-item-container mt-3">
              <p
                className="mb-3 custom-politique-confidentialite"
                dangerouslySetInnerHTML={createMarkup(contenu?.text)}
              />
            </div>
            <div className="workcares-cond-utilisation-ol-btn-container">
              <button
                className="btn workcares-cond-utilisation-ol-btn-edit"
                // data-bs-toggle="modal"
                // data-bs-target={`#UpdatePolitique${contenu?.slug}`}
                onClick={() => showModal(`UpdatePolitique${contenu?.slug}`)}
              >
                <BiEditAlt />
              </button>
              <DeleteCondition item={contenu} />

              <div
                className="modal fade"
                id={`UpdatePolitique${contenu?.slug}`}
                aria-labelledby="UpdatePolitiqueLabel"
                aria-hidden="true"
              >
                <AddOrUpdatePolitique
                  modalId={`UpdatePolitique${contenu?.slug}`}
                  condition={contenu}
                  type="policy"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div
        className="modal fade"
        id="AddOrUpdatePolitique"
        aria-labelledby="AddOrUpdatePolitiqueLabel"
        aria-hidden="true"
      >
        <AddOrUpdatePolitique modalId="AddOrUpdatePolitique" type="policy" />
      </div>
    </div>
  );
}

export default PolitiqueConfidentialite;

export function DeleteCondition({ item }: { item: ICondition }) {
  const deleteCondition = UseDeleteCondition(item);
  return (
    <button
      className="btn workcares-cond-utilisation-ol-btn-edit with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={deleteCondition}
    >
      <FaTrash />
    </button>
  );
}
