import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import { Input } from "../../../common/Input";
import UseCrudContenus from "../useForm/UseCrudContenus";
import { useGetThematiqueQuery } from "../../../../utils/api/thematique/thematique";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function AddOrUpdateCitationModal({
  contenu,
  modalId,
}: {
  contenu?: any;
  modalId: string;
}) {
  const {
    register,
    errors,
    onSubmit,
    setValue,
    isLoading,
    description,
    setDescription,
    handleChange,
    handelImageChange,
    previewImage,
    setPreviewImage,
    reset,
    setOption
  } = UseCrudContenus(modalId, contenu);

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (contenu) {
      setValue("theme", contenu?.theme?.id);
      setValue("slug", contenu?.slug);
      setValue("contenu", contenu?.contenu);
      setValue("ordre", contenu?.ordre);
      setDescription(contenu?.contenu || "");
      setOption({
        label: contenu?.theme?.libelle,
        value: contenu?.theme?.id
      })
    }
  }, [contenu]);

  const handleReset = () => {
    if (!contenu) {
      reset();
      setDescription("");
      setPreviewImage(null);
    }
  };


  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header px-2 py-3">
          <h5 className="modal-title" id="AddTagModalLabel">
            {contenu ? "Modifier la citation" : "Ajouter une citation"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" onClick={() => handleReset()} />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
              
                  {/* <div className="col-md-9">
                    <div className="content-col-form-add-student">
                      <label className="form-label form-label-modal-custom">
                        {" "}
                        Sélectionner un theme
                      </label>
                      <Controller
                        name="theme"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              // onInputChange={handleInputChange}
                              components={animatedComponents}
                              placeholder="Selectionner ou chercher"
                              isMulti={false}
                              filterOption={() => true}
                              options={options || []}
                              classNamePrefix="react-select-custom"
                              onChange={onChangeThematique}
                              value={option}
                            />
                          );
                        }}
                      />
                      {errors.theme && (
                        <ErrorMessage message={errors.theme?.message} />
                      )}
                    </div>
                  </div> */}
                
                {/* <div className="col-md-9">
                  <Input
                    type="text"
                    label="Titre"
                    id="titre"
                    placeholder="Titre"
                    {...register("titre")}
                  />
                  {errors.titre && (
                    <ErrorMessage message={errors.titre?.message} />
                  )}
                </div> */}
                <div className="col-md-12">
                  <Input
                    type="number"
                    label="Ordre"
                    id="ordre"
                    min={1}
                    {...register("ordre")}
                  />
                  {errors.ordre && (
                    <ErrorMessage message={errors.ordre?.message} />
                  )}
                </div>
                <div className="col-md-12 text-start mb-3">
                  <Input
                    type="file"
                    label="Image"
                    id={`image${contenu?.slug}`}
                    onChange={(e) => handelImageChange(e)}
                    accept="image/*"
                    name="background_image"
                    error={errors?.background_image?.message || ""}
                  />
                  {previewImage && (
                    <div className="img-preview-container">
                      <img src={previewImage} className="type-img-preview" />
                    </div>
                  )}
                  {/* {errors.contenu && (
                    <ErrorMessage message={errors.contenu?.message} />
                  )} */}
                </div>
                <div className="col-md-12 text-start mb-3">
                  <label
                    htmlFor="content"
                    className="form-label form-label-modal-custom"
                  >
                    Contenu
                  </label>
                  <ReactQuill
                    className="editor-cgu custom-textarea"
                    value={description}
                    onChange={(value) => handleChange(value)}
                  />
                  {errors.contenu && (
                    <ErrorMessage message={errors.contenu?.message} />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    <BtnSubmit
                      label={`${contenu ? "Modifier" : "Terminer"}`}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateCitationModal;

