import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useAdminsListQuery } from "../../../../utils/api/admin/admin.api";
import { IAdmin } from "../../../../utils/api/admin/admin.type";
import { Color } from "../../../../utils/theme";
import { getName } from "../../../../utils/Utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { useToggleArchiveAdmin } from "../useForm/UseCrudAdmin";
import { TbArchive, TbArchiveOff } from "react-icons/tb";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";

function AdministrateursTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useAdminsListQuery({ limit: perPage, page });
  // console.log("admin list", data);
  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={`/admin/administrateur/${row?.slug}`}
          className="btn btn-action-admin with-tooltip no-link"
          data-tooltip-content="Modifier"
          state={row}
        >
          <MdModeEditOutline />
        </NavLink>
        <DeleteAdmin item={row} />
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "PRÉNOM ET NOM",
      formatter: (cell: any, row: any) => getName(row),
    },
    {
      dataField: "email",
      text: "Adresse mail",
    },
    {
      dataField: "telephone",
      text: "TÉLÉPHONE",
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data ? data?.results : []}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export function DeleteAdmin({ item }: { item: IAdmin }) {
  const onDelete = useToggleArchiveAdmin(item);
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content={`${item?.is_archive ? "Désarchiver" : "Archiver"}`}
      onClick={onDelete}
    >
      {item?.is_archive ? (
        <TbArchive color={Color.success} />
      ) : (
        <TbArchiveOff />
      )}
    </button>
  );
}

export default AdministrateursTable;
