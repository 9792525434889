import React, { useEffect, useRef } from "react"
import PhoneInput from "react-phone-input-2";
import { AuthorisationsRh } from "../../../../../../utils/api/constant";
import {
  BtnAnnulerModal,
  BtnCloseModal,
  BtnSubmit,
} from "../../../../../common/Button"
import { FormError, Input } from "../../../../../common/Input"
import UseCrudOrganisationMembre from "../useForm/UseCrudOrganisationMembre"

function AddMembreModal({ user, modalId }: { user?: any; modalId: string }) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handleReset,
    phone,
    handleChangePhone,
  } = UseCrudOrganisationMembre(modalId, user);
    const ref = useRef<any>();
    useEffect(() => {
      if (errors?.telephone?.message) {
        ref.current.scrollIntoView();
      }
    }, [errors?.telephone]);
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {user ? "Modifier l'organisateur" : "Ajouter un organisateur"}
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" onClick={handleReset} />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Prénom"
                    id="prenom"
                    placeholder="Prénom"
                    {...register("prenom")}
                    error={errors?.prenom}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors?.nom}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="email"
                    label="Adresse email"
                    id="email"
                    placeholder="Email"
                    {...register("email")}
                    error={errors?.email}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Service"
                    id="service"
                    placeholder="Service"
                    {...register("service")}
                    error={errors?.service}
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="phone"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="phone"
                    >
                      Numéro de téléphone
                      <span
                        ref={ref}
                        className="text-danger"
                        style={{ fontSize: 24 }}
                      >
                        *
                      </span>
                    </label>
                    <PhoneInput
                      
                      containerClass="form-control form-control-modal-custom mb-3 p-0"
                      inputClass="form-control form-control-modal-custom custom-padding"
                      country={"fr"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any) => {
                        handleChangePhone(phone, country);
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="custom-titre-details-parametre text-start">
                        Permissions
                      </p>
                      <div className="container-checkbox-input">
                        <div className="form-checkbox-input checkbox-input">
                          <div className="custom-permission-container">
                            {AuthorisationsRh?.map((item) => (
                              <label key={item?.field}>
                                <input
                                  type="checkbox"
                                  {...register(item?.field)}
                                  id={`checkbox-${item?.field}`}
                                />
                                <span>{item?.label}</span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal
                      label={"Annuler"}
                      isLoading={false}
                      onClick={handleReset}
                    />
                    <BtnSubmit
                      label={user ? "Modifier" : "Ajouter"}
                      isLoading={isLoading}
                      style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMembreModal
