import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline, MdPermContactCalendar } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetRhQuery } from "../../../../utils/api/user/user.api";
import { getAvatar, getName } from "../../../../utils/Utils";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { IUser } from "../../../../utils/api/user/user.type";

function EntrepriseTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetRhQuery({
    page: page,
    limit: perPage,
  });

//   console.log("data rh", data);

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <NavLink
          to={`/admin/entreprises/${row?.slug}`}
          className="btn btn-action-admin with-tooltip no-link"
          data-tooltip-content="Voir"
          state={row}
        >
          <AiFillEye />
        </NavLink>
        {/* <DeleteUser item={row} /> */}
      </div>
    );
  };
  const nameAvatarFormatter = (cell: string, row: IUser) => {
    return (
      <div>
        <img
          src={getAvatar(row?.avatar)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {cell || getName(row)}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom_entreprise",
      text: "Nom",
      formatter: (cell: string, row: IUser) => nameAvatarFormatter(cell, row),
      // headerStyle: () => {
      //   return { width: "150px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "email",
      text: "Adresse mail",
      style: { whiteSpace: "normal" },
      // headerStyle: () => {
      //   return { width: "150px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "telephone",
      text: "TÉLÉPHONE",
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: IUser) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndicator={<AlertInfo message="Pas de données" />}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EntrepriseTable;
