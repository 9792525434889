import React, { useEffect, useState } from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import "./MonCompte.css";
import { BsCameraFill } from "react-icons/bs";
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import ModifierInfosPersoModal from "./Modals/ModifierInfosPersoModal";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useAddOrUpdateAdminMutation } from "../../../utils/api/admin/admin.api";
import { onSetUser } from "../../../redux/slice/User.slice";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { getAvatar, getName } from "../../../utils/Utils";

function MonCompte() {
  const [avatar, setAvatar] = useState<any>(null);
  const admin = useAppSelector((s) => s?.user?.user);
  console.log({ admin });

  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrUpdateAdminMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (done) {
      console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);
  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: admin?.slug, user_type: admin?.user_type, data: fd });
  };
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Mon profil" title="Mon compte" />
        </div>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="content-pp-profil-visiteur text-center mb-3">
          <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
            <label htmlFor="input-test" className="input-testt">
              <img
                src={avatar || getAvatar(admin?.avatar)}
                alt="profil utilisateur"
                className="pp-profil-user-visiteur"
              />
              <div className="leader-auto-user-avatar-edit-hover">
                {load ? (
                  <span
                    className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                    role="status"
                  ></span>
                ) : (
                  <BsCameraFill className="edit-avatar-camera-icon" />
                )}
              </div>
            </label>
            <input
              type="file"
              className="input-add-avatar"
              id="input-test"
              onChange={(e) => changeImage(e)}
            />
          </div>
          <div className="content-text-name-user">
            <h2 className="custom-name-user-admin">{getName(admin)}</h2>
          </div>
        </div>
      </div>

      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <div>
                <div className="workcare-dash-admin-section-edit-profil-container">
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Prénom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {admin?.prenom !== undefined && admin.prenom !== null && admin.prenom !== "null"
                          ? admin.prenom
                          : "NON RENSEIGNÉ"}
                      </div>
                      {/* btn workcare-dash-admin-section-bio-btn-edit-deux */}
                      {/* <button 
                        className="btn btn-change-password"
                        data-bs-toggle="modal"
                        data-bs-target="#changeInfosPerso"
                        >
                        Edit
                      </button> */}
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Nom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {admin?.nom !== undefined && admin?.nom !== null && admin?.nom !== "null"
                          ? admin?.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Age
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {admin?.age !== undefined && admin?.age !== null && admin?.age !== "null"
                          ? admin?.age + " ans"
                          : "NON RENSEIGNÉ"}
                        {/* 23 ans */}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Téléphone
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {admin?.telephone !== undefined &&
                        admin?.telephone !== null && admin?.telephone !== "null" 
                          ? admin?.telephone
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item-deux">
                        Adresse mail
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item-deux">
                        {admin?.email !== undefined && admin?.email !== null && admin?.email !== "null"
                          ? admin?.email
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="flex-r mb-3">
                    <button
                      className="btn btn-change-password"
                      data-bs-toggle="modal"
                      data-bs-target="#changeInfosPerso"
                    >
                      Modifier
                    </button>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="changeInfosPerso"
                  aria-labelledby="changeInfosPersoLabel"
                  aria-hidden="true"
                >
                  <ModifierInfosPersoModal
                    modaleId="changeInfosPerso"
                    admin={admin}
                    hide={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-6">
									<Input
										type="text"
										label="Nom"
										id="nom"
										placeholder="Nom"
										required
										{...register("nom")}
										error={errors?.nom}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Prénom"
										id="prenom"
										placeholder="Prénom"
										required
										{...register("prenom")}
										error={errors?.prenom}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="email"
										label="Email"
										id="email"
										placeholder="Email"
										required
										{...register("email")}
										error={errors?.email}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Téléphone"
										id="phone"
										placeholder="Téléphone"
										required
										{...register("telephone")}
										error={errors?.telephone}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Adresse"
										id="adresse"
										placeholder="Adresse"
										{...register("adresse")}
										error={errors?.adresse}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Code postale"
										id="boite_postale"
										placeholder="Code postale"
										{...register("code_postal")}
										error={errors?.code_postal}
									/>
								</div>
							</div>
							<div className="container-btn-modal row my-4">
								<div className="col-md-6 offset-md-6">
									<div className="flex-r gap-4">
										<BtnSubmit
											label="Enregistrer"
											isLoading={isLoading}
										/>
									</div>
								</div>
							</div>
						</form> */}

      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire-deux">
                Mot de passe
              </h5>
              <div className="workcare-dash-admin-profile-section-password">
                <button
                  className="btn btn-change-password"
                  data-bs-toggle="modal"
                  data-bs-target="#ChangePasswordModal"
                >
                  Changer mot de passe
                </button>
              </div>
              <ChangePasswordModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonCompte;
