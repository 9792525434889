import React from "react";
import "react-nice-dates/build/style.css";
import "./Disponibilite.css";
import { BsPlusSquare } from "react-icons/bs";
import DisponibiliteAgenda from "./DisponibiliteAgenda";
import GererDisponibiliteModal from "./Modals/GererDisponibiliteModal";

function Disponibilite() {
  return (
        <div className="dashbord-visiteur-component mb-4">
      <div className="col-md-12 col-left-dashboard-visiteur mb-3">
        <div className="content-col-left-dashboard-visiteur">
          <div className="content-col-graph-admin">
            <div className="agenda-praticien-btn-gerer-mes-disponibilite-container-deux pt-2">
              <button
                className="btn agenda-praticien-btn-gerer-mes-disponibilite-deux"
                data-bs-toggle="modal"
                data-bs-target="#GererDisponibiliteModal"
              >
                <BsPlusSquare className="agenda-praticien-btn-gerer-mes-disponibilite-deux-icon" />{" "}
                Gérer mes disponibilités
              </button>

              <div
                className="modal fade"
                id="GererDisponibiliteModal"
                aria-labelledby="GererDisponibiliteModalLabel"
                aria-hidden="true"
              >
                <GererDisponibiliteModal modalId="GererDisponibiliteModal" />
              </div>
            </div>
            <div className="dashboard-praticien-table-recent-patient-container w-75 mx-auto my-4">
              <DisponibiliteAgenda />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disponibilite;
