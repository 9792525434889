import React from "react";
import { IRdv } from "../../../../../../utils/api/rdv/rdv.type";

function InfosPersonnelles({ rdv }: { rdv: IRdv }) {
  return (
    <div className="pt-3">
      <div className="workcare-dash-admin-section-edit-profil-item">
        <div className="workcare-dash-praticien-section-edit-profil-prenom">
          <div className="workcare-dash-praticien-section-edit-profil-prenom-item-deux">
            Prénom & Nom
          </div>
        </div>
        <div className="workcare-dash-praticien-section-edit-profil-btn-container">
          <div className="workcare-dash-praticien-section-edit-profil-btn-item-deux">
            {rdv?.salarie?.prenom !== undefined &&
            rdv?.salarie?.prenom !== null &&
            rdv?.salarie?.prenom !== "null"
              ? rdv?.salarie?.prenom
              : "NON RENSEIGNÉ"}{" "}
            {rdv?.salarie?.nom !== undefined &&
            rdv?.salarie?.nom !== null &&
            rdv?.salarie?.nom !== "null"
              ? rdv?.salarie?.nom
              : "NON RENSEIGNÉ"}
          </div>
        </div>
      </div>
      <hr className="workcares-dash-praticien-custom-divider" />

      <div className="workcare-dash-admin-section-edit-profil-item">
        <div className="workcare-dash-praticien-section-edit-profil-prenom">
          <div className="workcare-dash-praticien-section-edit-profil-prenom-item-deux">
            Entreprise
          </div>
        </div>
        <div className="workcare-dash-praticien-section-edit-profil-btn-container">
          <div className="workcare-dash-praticien-section-edit-profil-btn-item-deux">
            {rdv?.salarie?.rh?.nom_entreprise !== undefined &&
            rdv?.salarie?.rh?.nom_entreprise !== null &&
            rdv?.salarie?.rh?.nom_entreprise !== "null"
              ? rdv?.salarie?.rh?.nom_entreprise
              : "NON RENSEIGNÉ"}{" "}
          </div>
        </div>
      </div>
      <hr className="workcares-dash-praticien-custom-divider" />

      <div className="workcare-dash-admin-section-edit-profil-item">
        <div className="workcare-dash-praticien-section-edit-profil-prenom">
          <div className="workcare-dash-praticien-section-edit-profil-prenom-item-deux">
            Téléphone
          </div>
        </div>
        <div className="workcare-dash-praticien-section-edit-profil-btn-container">
          <div className="workcare-dash-praticien-section-edit-profil-btn-item-deux">
            {rdv?.salarie?.telephone}
          </div>
        </div>
      </div>
      <hr className="workcares-dash-praticien-custom-divider" />

      <div className="workcare-dash-admin-section-edit-profil-item">
        <div className="workcare-dash-praticien-section-edit-profil-prenom">
          <div className="workcare-dash-praticien-section-edit-profil-prenom-item-deux">
            Date de naissance
          </div>
        </div>
        <div className="workcare-dash-praticien-section-edit-profil-btn-container">
          <div className="workcare-dash-praticien-section-edit-profil-btn-item-deux">
            {rdv?.salarie?.date_naissance}
            {rdv?.salarie?.date_naissance !== undefined &&
            rdv?.salarie?.date_naissance !== null &&
            rdv?.salarie?.date_naissance !== "null"
              ? rdv?.salarie?.date_naissance
              : "NON RENSEIGNÉ"}{" "}
          </div>
        </div>
      </div>
      <hr className="workcares-dash-praticien-custom-divider" />

      <div className="workcare-dash-admin-section-edit-profil-item">
        <div className="workcare-dash-praticien-section-edit-profil-prenom">
          <div className="workcare-dash-praticien-section-edit-profil-prenom-item-deux">
            Adresse
          </div>
        </div>
        <div className="workcare-dash-praticien-section-edit-profil-btn-container">
          <div className="workcare-dash-praticien-section-edit-profil-btn-item-deux">
            {rdv?.salarie?.adresse}
            {rdv?.salarie?.adresse !== undefined &&
            rdv?.salarie?.adresse !== null &&
            rdv?.salarie?.adresse !== "null"
              ? rdv?.salarie?.adresse
              : "NON RENSEIGNÉ"}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosPersonnelles;
