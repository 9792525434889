import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { TypeQuery } from "../user/user.type";
import { Dashboard } from "./dashboard.type";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboardAdmin: builder.query<Dashboard, TypeQuery>({
      query: (query) => QueryUrl(`admin/dashboard`, query),
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetDashboardAdminQuery } = DashboardApi;
