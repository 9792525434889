import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { ApiBaseUrl } from "../../http"
import { prepareHeaders } from "../user/user.api"
import { PaginationResults, TypeQuery } from "../user/user.type"
import { ContenusFormdata, IContenus } from "./contenus.type"
import { QueryUrl } from "../../Utils"

const ContenusApi = createApi({
  reducerPath: "contenusApi",
  tagTypes: ["contenus", "contenusByTheme"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addContenu: build.mutation<
      IContenus,
      { slug?: string; data: IContenus | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `contenus/${slug}/` : `contenus/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["contenus", "contenusByTheme"],
    }),
    getContenus: build.query<PaginationResults<IContenus>, TypeQuery>({
      query: (query) => QueryUrl(`contenus/`, query),
      providesTags: ["contenus"],
    }),
    getContenusByTheme: build.query<PaginationResults<IContenus>, TypeQuery>({
      query: ({ slug, ...query }) => QueryUrl(`theme/${slug}/contenus/`, query),
      providesTags: ["contenusByTheme"],
    }),
    deleteContenu: build.mutation<ContenusFormdata, string>({
      query: (slug) => ({
        url: `contenus/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contenus", "contenusByTheme"],
    }),
  }),
});

export default ContenusApi

export const {
  useAddContenuMutation,
  useDeleteContenuMutation,
  useGetContenusQuery,
  useGetContenusByThemeQuery
} = ContenusApi
