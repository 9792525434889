import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline, MdPermContactCalendar } from "react-icons/md";
import { NavLink } from "react-router-dom";
import AjouterPraticienModal from "../Modals/AjouterPraticienModal";
import {
  useGetPraticienQuery,
  useGetUsersQuery,
} from "../../../../utils/api/user/user.api";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import { getAvatar, getName } from "../../../../utils/Utils";
import { DeleteUser } from "../useForm/UseCrudPraticien";
import AssignationRhEntrepriseModal from "../Modals/AssignationRhEntrepriseModal";
import { AlertInfo } from "../../../common/Alert";
import { Specialite } from "../../../../utils/api/specialite/specialite.type";

function ListePraticiensTables() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetPraticienQuery({
    page: page,
    limit: perPage,
  });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-action gap-2 d-flex">
        <button
          className="btn btn-action-admin with-tooltip"
          data-tooltip-content="Contact"
          data-bs-toggle="modal"
          data-bs-target={`#AssignationRhEntrepriseModal${row?.slug}`}
        >
          <MdPermContactCalendar />
        </button>
        <button
          className="btn btn-action-admin with-tooltip"
          data-tooltip-content="Modifier"
          data-bs-toggle="modal"
          data-bs-target={`#AjouterPraticienModal${row?.slug}`}
        >
          <MdModeEditOutline />
        </button>
        <NavLink
          to={`/admin/praticien/${row?.slug}`}
          className="btn btn-action-admin with-tooltip no-link"
          data-tooltip-content="Voir"
          state={row}
        >
          <AiFillEye />
        </NavLink>
        <DeleteUser item={row} />
        <div
          className="modal fade"
          id={`AjouterPraticienModal${row?.slug}`}
          aria-labelledby={`AjouterPraticienModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <AjouterPraticienModal
            modalId={`AjouterPraticienModal${row?.slug}`}
            user={row}
          />
        </div>
        <div
          className="modal fade"
          id={`AssignationRhEntrepriseModal${row?.slug}`}
          aria-labelledby={`AssignationRhEntrepriseModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <AssignationRhEntrepriseModal
            modalId={`AssignationRhEntrepriseModal${row?.slug}`}
            user={row}
          />
        </div>
      </div>
    );
  };
  const nameAvatarFormatter = (cell: any, row: any) => {
    return (
      <div>
        <img
          src={getAvatar(row?.avatar)}
          alt="user avatar"
          className="workcares-table-user-avatar"
        />{" "}
        {getName(row)}
      </div>
    );
  };
  const entrepriseFormatter = (cell: any, row: any) => {
    return (
      <div>
        {row?.entreprise?.length &&
          row?.entreprise
            ?.slice(0, 2)
            ?.map((entreprise, i) => (
              <div>
                {getName(entreprise) + (i === 1 && i + 1 < row?.entreprise?.length
                  ? "..."
                  : ",")}
              </div>
            ))}
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "PRÉNOM ET NOM",
      formatter: (cell: any, row: any) => nameAvatarFormatter(cell, row),
      // headerStyle: () => {
      //   return { width: "150px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "email",
      text: "Adresse mail",
      style: { whiteSpace: "normal" },
      // headerStyle: () => {
      //   return { width: "150px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "telephone",
      text: "TÉLÉPHONE",
      headerStyle: () => {
        return { width: "90px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "specialite",
      text: "Spécialité",
      formatter: (cell: Specialite) => cell?.nom || "",
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "entreprise",
      text: "Entreprise",
      formatter: (cell: any, row: any) => entrepriseFormatter(cell, row),
      headerStyle: () => {
        return { width: "110px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "action",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "120px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndicator={<AlertInfo message="Pas de données" />}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListePraticiensTables;
