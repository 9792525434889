import React, { ReactElement } from "react";
import Login from "../components/auth/Login";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "../components/admin/AdminLayout/AdminLayout";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import ListeUtilisateurs from "../components/admin/Utilisateurs/ListeUtilisateurs";
import DetailsUtilisateur from "../components/admin/Utilisateurs/DetailsUtilisateur";
import ListePraticiens from "../components/admin/Praticien/ListePraticiens";
import DetailsPraticien from "../components/admin/Praticien/DetailsPraticien";
import ListeObjectifs from "../components/admin/OcbjectifsActions/ListeObjectifs";
import ListeContenus from "../components/admin/Contenus/ListeContenus";
import MonCompte from "../components/admin/MonCompte/MonCompte";
import Parametre from "../components/admin/Parametres/Parametre";
import DetailsAdministrateur from "../components/admin/Parametres/DetailsAdministrateur";
import Agenda from "../components/admin/Agenda/Agenda";
import PriseEnChargeListe from "../components/TableauDeBord/Praticien/PriseEnCharge/PriseEnChargeListe";
import DashboardPraticien from "../components/TableauDeBord/Praticien/Dashboard/DashboardPraticien";
import MonProfil from "../components/TableauDeBord/Praticien/MonProfil/MonProfil";
import Diagnostique from "../components/TableauDeBord/Praticien/PriseEnCharge/Diagnostique/Diagnostique";
import DashboardRh from "../components/TableauDeBord/RH/Dashboard/DashboardRh";
import ObjectisActionsRh from "../components/TableauDeBord/RH/ObjectifsActions/ObjectisActionsRh";
import OrganisationsRh from "../components/TableauDeBord/RH/Organisations/OrganisationsRh";
import MonAgenda from "../components/TableauDeBord/Praticien/MonAgenda/MonAgenda";
import Disponibilite from "../components/TableauDeBord/Praticien/MonAgenda/Disponibilite";
import { IUser, UserType } from "../utils/api/user/user.type";
import { useAppSelector } from "../redux/hooks";
import { IAdmin } from "../utils/api/admin/admin.type";
import { isOrganisateur, isPraticien, isRH } from "../utils/Utils";
import Register from "../components/auth/Register";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import OrganisateurDetail from "../components/TableauDeBord/RH/Organisations/OrganisationsRhTables/OrganisateurDetail";
import ProfilRh from "../components/TableauDeBord/RH/Profil/ProfilRh";
import { FieldType } from "../utils/api/constant";
import AgendaPraticien from "../components/TableauDeBord/Praticien/MonAgenda/AgendaPraticien";
import ListeNotification from "../components/shared/ListeNotification";
import ParametreRh from "../components/TableauDeBord/RH/ParametreRh/ParametreRh";
import Entreprises from "../components/admin/Entreprises/Entreprises";
import EntrepriseDetails from "../components/admin/Entreprises/EntrepriseDetails";
import Questions from "../components/admin/Questions/Questions";
import DetailsQuestion from "../components/admin/Questions/DetailsQuestion";
import DetailsQuestionEval from "../components/admin/Questions/DetailsQuestionEval";
import QuestionsRh from "../components/TableauDeBord/RH/QuestionsRh/QuestionsRh";
import DetailsQuestionsRh from "../components/TableauDeBord/RH/QuestionsRh/DetailsQuestionsRh";
import DetailsQuestionsEvalRh from "../components/TableauDeBord/RH/QuestionsRh/DetailsQuestionsEvalRh";
import MinutesCares from "../components/admin/Contenus/ContenusSteps/MinutesCares";
// UserRoutes

export const ProtectedRoutes = ({
  children,
  userType,
}: {
  children: ReactElement;
  userType: UserType[];
}) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  // const is_AdminAgent = isAdminAgent(user);
  const isAccess = userType?.includes(user?.user_type);
  const auth = user?.id;
  return isAccess && isAccess !== undefined ? (
    children
  ) : auth && auth !== undefined ? (
    <Navigate to="/" replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export const AdminRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id) return <Navigate to="/" state={{ from: location }} replace />;
  if (isRH(user)) return <Navigate to="/rh/dashboard" />;
  if (isPraticien(user)) return <Navigate to="/praticien/dashboard" />;

  if (isAdminUser(user)) return children;
  return <Navigate to="/" />;
};

export const RhRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id) return <Navigate to="/" state={{ from: location }} replace />;

  if (isRH(user)) return children;
  if (isPraticien(user)) return <Navigate to="/praticien/dashboard" />;
  if (isAdminUser(user)) return <Navigate to="/admin/dashboard" />;
  return <Navigate to="/" />;
};

export const PraticienRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id) return <Navigate to="/" state={{ from: location }} replace />;

  if (isPraticien(user)) return children;
  if (isRH(user)) return <Navigate to="/rh/dashboard" />;
  if (isAdminUser(user)) return <Navigate to="/admin/dashboard" />;
  return <Navigate to="/" />;
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path = "/";
  console.log("redirect");
  if (isRH(user)) {
    path = "/rh/dashboard";
  } else if (isPraticien(user)) {
    path = "/praticien/dashboard";
  } else if (
    user?.user_type === UserType.superAdmin ||
    user?.user_type === UserType.admin
  ) {
    path = "/admin/dashboard";
  }
  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

const IsAdminAllow = ({
  children,
  field,
}: {
  children: ReactElement;
  field?: FieldType;
}) => {
  const { user } = useAppSelector((state) => state.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const navigate = useNavigate();
  if (field) {
    if (!user[field] && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1);
      }, 10);
    }
  }

  return children;
};
const IsRhAllow = ({
  children,
  field,
}: {
  children: ReactElement;
  field: FieldType;
}) => {
  const { user } = useAppSelector((state) => state.user);
  console.log("user", user);
  const is_Organisateur = useAppSelector((s) => isOrganisateur(s.user?.user));
  const is_Rh = useAppSelector((s) => isRH(s.user?.user));
  const navigate = useNavigate();
  if (!user[field] && is_Rh && user?.sous_type) {
    setTimeout(() => {
      navigate(-1);
    }, 10);
  }
  return children;
};

export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: <ForgotPassword />,
  },
  {
    path: "/modifier-mot-de-passe",
    element: <ResetPassword />,
  },
  {
    path: "/inscription",
    element: <Register />,
  },
  {
    path: "admin/",
    element: (
      <ProtectedRoutes userType={[UserType.admin, UserType.superAdmin]}>
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: (
          // <IsAdminAllow field="dashboard">
          <DashboardAdmin />
          // </IsAdminAllow>
        ),
      },
      {
        path: "agenda",
        element: (
          // <IsAdminAllow field="agenda">
          <Agenda />
          // </IsAdminAllow>
        ),
      },
      {
        path: "notifications",
        element: <ListeNotification />,
      },
      {
        path: "utilisateurs",
        element: (
          // <IsAdminAllow field="utilisateurs">
          <ListeUtilisateurs />
          // </IsAdminAllow>
        ),
      },
      {
        path: "utilisateur/:slug",
        element: (
          // <IsAdminAllow field="utilisateurs">
          <DetailsUtilisateur />
          // </IsAdminAllow>
        ),
      },
      {
        path: "praticiens",
        element: (
          // <IsAdminAllow field="praticiens">
          <ListePraticiens />
          // </IsAdminAllow>
        ),
      },
      {
        path: "entreprises",
        element: (
          // <IsAdminAllow>
          <Entreprises />
          // </IsAdminAllow>
        ),
      },
      {
        path: "entreprises/:slug",
        element: (
          // <IsAdminAllow>
          <EntrepriseDetails />
          // </IsAdminAllow>
        ),
      },
      {
        path: "praticien/:slug",
        element: (
          // <IsAdminAllow field="praticiens">
          <DetailsPraticien />
          // </IsAdminAllow>
        ),
      },
      {
        path: "objectifs-et-actions",
        element: (
          // <IsAdminAllow field="objectifs">
          <ListeObjectifs />
          // </IsAdminAllow>
        ),
      },

      {
        path: "contenus",
        element: (
          // <IsAdminAllow field="contenus">
          <ListeContenus />
          // </IsAdminAllow>
        ),
      },
      {
        path: "thematique/:slug/minutes-cares",
        element: <MinutesCares />,
      },
      {
        path: "mon-compte",
        element: <MonCompte />,
      },
      {
        path: "questions",
        element: <Questions />,
      },
      {
        path: "question-diagnostique/:slug",
        element: <DetailsQuestion />,
      },
      {
        path: "question-evaluation/:slug",
        element: <DetailsQuestionEval />,
      },
      {
        path: "parametres",
        element: (
          // <IsAdminAllow field="parametres">
          <Parametre />
          // </IsAdminAllow>
        ),
      },
      {
        path: "administrateur/:slug",
        element: (
          // <IsAdminAllow field="parametres">
          <DetailsAdministrateur />
          // </IsAdminAllow>
        ),
      },
    ],
  },
  {
    path: "praticien/",
    element: (
      <PraticienRoutes>
        <AdminLayout />
      </PraticienRoutes>
    ),
    children: [
      { element: <Navigate to="/praticien/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardPraticien />,
      },
      // {
      //   path: "mon-agenda",
      //   element: <MonAgenda />,
      // },
      {
        path: "mon-agenda",
        element: <AgendaPraticien />,
      },
      {
        path: "notifications",
        element: <ListeNotification />,
      },
      {
        path: "mon-agenda/disponibilite",
        element: <Disponibilite />,
      },
      {
        path: "prise-en-charge",
        element: <PriseEnChargeListe />,
      },
      {
        path: "mon-profil",
        element: <MonProfil />,
      },
      {
        path: "patient/:slug",
        element: <Diagnostique />,
      },
    ],
  },
  {
    path: "rh/",
    element: (
      // <ProtectedRoutes userType={[UserType.rh]}>

      <RhRoutes>
        <AdminLayout />
      </RhRoutes>
      // </ProtectedRoutes>
    ),
    children: [
      { element: <Navigate to="/rh/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: (
          <IsRhAllow field="dashboard">
            <DashboardRh />
          </IsRhAllow>
        ),
      },
      {
        path: "objectifs-et-actions",
        element: (
          <IsRhAllow field="objectifs">
            <ObjectisActionsRh />
          </IsRhAllow>
        ),
      },
      {
        path: "organisations",
        element: (
          <IsRhAllow field="utilisateurs">
            <OrganisationsRh />
          </IsRhAllow>
        ),
      },
      {
        path: "organisations/:slug",
        element: (
          <IsRhAllow field="utilisateurs">
            <OrganisateurDetail />
          </IsRhAllow>
        ),
      },
      {
        path: "mon-profil",
        element: (
          <IsRhAllow field="parametres">
            <ProfilRh />
          </IsRhAllow>
        ),
      },
      {
        path: "questions",
        element: <QuestionsRh />,
      },
      {
        path: "question-diagnostique/:slug",
        element: <DetailsQuestionsRh />,
      },
      {
        path: "question-evaluation/:slug",
        element: <DetailsQuestionsEvalRh />,
      },
      {
        path: "parametres",
        element: (
          <IsRhAllow field="parametres">
            <ParametreRh />
          </IsRhAllow>
        ),
      },
    ],
  },
];

export function isAdminUser(user?: IUser | IAdmin) {
  return (
    user && (user.user_type === "admin" || user.user_type === "superadmin")
  );
}

export function isSuperAdmin(user?: IUser | IAdmin) {
  return user && user.user_type === "superadmin";
}
