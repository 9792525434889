import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl } from "../../Utils";
import { ChangePasswordData, LoginResult , DeleteProfilData } from "../auth/auth.type";
import {
  AuthState,
  IDashboardPraticien,
  IUser,
  PaginationResults,
  SuppressionType,
  SuppressionTypeFromData,
  TypeQuery,
  UserFormData,
  IDashboardRh,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }: any) => {
  const token =
    (getState() as { user: LoginResult }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["user", "userList", "organisationList", "salarieList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      transformResponse: ({ data }) => data,
      query: () => {
        return "auth/me/";
      },
    }),

    addOrEditUser: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `users/`,
          method: "POST",
          body: data,
        };
      },
    }),

    addMembreOrganisation: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `organisations/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `organisations/`,
          method: "POST",
          body: data,
        };
      },
    }),

    supprimerUser: builder.mutation<SuppressionType, SuppressionTypeFromData>({
      invalidatesTags: ["user", "userList"],
      query: ({ slug, ...data }) => {
        return {
          url: `user/suppression/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getUsers: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`users/`, query),
      providesTags: ["userList"],
    }),
    getSalaries: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`users/`, query),
      providesTags: ["userList"],
    }),
    getPraticien: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`praticiens/`, query),
      providesTags: ["userList"],
    }),
    deleteUser: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["user", "userList"],
    }),

    getOrganisation: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`organisations/`, query),
      providesTags: ["userList"],
    }),
    getRh: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: (query) => QueryUrl(`entreprises/`, query),
      providesTags: ["userList"],
    }),
    getOrganisationByRhAction: builder.query<
      PaginationResults<IUser>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`entreprise/${slug}/organisations/`, query),
      providesTags: ["organisationList"],
    }),
    getSalariesByRhAction: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`entreprise/${slug}/salaries/`, query),
      providesTags: ["salarieList"],
    }),
    deleteOrganisation: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `organisations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["user", "userList"],
    }),

    deleteProfil: builder.mutation<
      IUser,
      { slug?: string; data: DeleteProfilData | FormData }
    >({
      invalidatesTags: ["user", "userList"],
      query: ({ slug, data }) => {
        return {
          url: `user/${slug}/suppression/`,
          method: "POST",
          body: data,
        };
      },
    }),

    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `auth/change-password/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getDashboardPraticien: builder.query<IDashboardPraticien, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`praticien/${slug}/dashboard/`, query),
      providesTags: ["user", "userList", "organisationList", "salarieList"],
    }),
    getSalariesByPraticien: builder.query<PaginationResults<IUser>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`praticien/${slug}/salaries/`, query),
      providesTags: ["salarieList"],
    }),
    getDashboardRhWeek: builder.query<IDashboardRh, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`entreprise/${slug}/dashboard/?frequence=week/`, query),
      providesTags: ["user", "userList", "organisationList", "salarieList"],
    }),
    getDashboardRhMonth: builder.query<IDashboardRh, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`entreprise/${slug}/dashboard/?frequence=month/`, query),
      providesTags: ["user", "userList", "organisationList", "salarieList"],
    }),
    getDashboardRhYear: builder.query<IDashboardRh, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`entreprise/${slug}/dashboard/?frequence=year/`, query),
      providesTags: ["user", "userList", "organisationList", "salarieList"],
    }),
  }),
});

export const {
  useMeQuery,
  useAddOrEditUserMutation,
  useAddMembreOrganisationMutation,
  useGetUsersQuery,
  useDeleteUserMutation,
  useGetSalariesQuery,
  useGetOrganisationQuery,
  useDeleteOrganisationMutation,
  useChangePasswordMutation,
  useGetPraticienQuery,
  useGetRhQuery,
  useGetOrganisationByRhActionQuery,
  useGetSalariesByRhActionQuery,
  useDeleteProfilMutation,
  useSupprimerUserMutation,
  useGetDashboardPraticienQuery,
  useGetSalariesByPraticienQuery,
  useGetDashboardRhWeekQuery,
  useGetDashboardRhMonthQuery,
  useGetDashboardRhYearQuery,
} = UserApi;
