import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { UserFormData } from "../../../../../utils/api/user/user.type";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import { cleannerError, onHide, validatePhone } from "../../../../../utils/Utils";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { Color } from "../../../../../utils/theme";
import { useGetSpecialitesByVisitorQuery } from "../../../../../utils/api/specialite/specialite.api";

function UseEditUserForm(from: unknown, modalId: string) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    from: yup.string().nullable(),
    nom: yup
      .string()
      .when("from", {
        is: "praticien",
        then: yup.string().required().label("Nom"),
      })
      .nullable(),
    prenom: yup
      .string()
      .when("from", {
        is: "praticien",
        then: yup.string().required().label("Prénom"),
      })
      .nullable(),

    email: yup.string().email().required().label("Email").nullable(),
    telephone: validatePhone(yup, code).required(),
    adresse: yup.string().nullable().label("Adresse"),
    specialite: yup
      .number()
      .nullable()
      .label("Spécialite")
      .transform((value) => (isNaN(value) ? undefined : value)),
    experience: yup.string().nullable().label("Expérience"),
    bio: yup.string().nullable().label("Bio"),
  });

  const { data: specialites = [] } = useGetSpecialitesByVisitorQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useAppDispatch();

  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditUserMutation();
  let user = useAppSelector((s) => s?.user?.user);
  const [address, setAddress] = useState("");

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, item);
    } else {
      setValue(`telephone`, "");
    }
  };
  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  useEffect(() => {
    if (from === "praticien") {
      setValue("from", "praticien");
    }
  }, [from]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(onSetUser(data));
      Swal.fire({
        icon: "success",
        title: "Informations modifiées avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (specialites?.length) {
      setValue("specialite", user?.specialite?.id);
    }
  }, [specialites]);

  useEffect(() => {
    if (user?.id) {
      const fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
        "adresse",
        "specialite",
        "experience",
        "bio",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "specialite") {
          setValue(field, user[field]);
        } else if (user?.specialite?.id) {
          setValue(field, user?.specialite?.id);
          // console.log("user user", getValues("specialite"));
        }
      }
      setAddress(user?.adresse);
      if (user?.telephone) {
        setPhone(user?.telephone);
      }

    }
  }, [user]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key] !== "null") {
        fd.append(key, data[key]);
      }
    }

    sendData({ slug: user?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    onChangeAddress,
    address,
    phone,
    specialites,
    handleChangePhone,
  };
}

export default UseEditUserForm;
