import React from "react";
import {
  useGetSalarieResponseAutoDiagnostiqueQuery,
} from "../../../../../../utils/api/autoDiagnostique/autoDiagnostique";
import { AlertInfo } from "../../../../../common/Alert";

function AutoDiagnostique({ rdv }: { rdv: any }) {
  const { data: autoDiagnostiqueData = [] } =
    useGetSalarieResponseAutoDiagnostiqueQuery({
      slug: rdv?.salarie?.slug,
    });
  return (
    <div className="pt-3">
      <div className="workcare-dash-praticien-section-diagnostique mb-3">
        <div className="row">
          {!autoDiagnostiqueData?.length && (
            <AlertInfo message="Aucune donnée trouvée" />
          )}
          {autoDiagnostiqueData
            ?.filter((item) => item?.question?.type !== "message")
            ?.map((itm) => (
              <div className="col-md-6 mb-4" key={itm.id}>
                <div className="workcare-dash-praticien-section-diagnostique-label">
                  {itm.question?.contenu}
                </div>
                {itm.response?.length > 0 ? (
                  <div className="workcare-dash-praticien-section-diagnostique-value">
                    {itm.response?.map((res) => res?.contenu + ", ")}
                  </div>
                ) : itm.response_free ? (
                  <div className="workcare-dash-praticien-section-diagnostique-value">
                    {itm.response_free}
                  </div>
                ) : (
                  <span className="badge bg-info text-dark">Non renseigné</span> //custom-badge-empty
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default AutoDiagnostique;
