import React, { useEffect, useRef, useState } from "react"
import "./MonProfil.css"
import ChangePasswordModal from "../../../admin/MonCompte/Modals/ChangePasswordModal"
import { TiCamera } from "react-icons/ti"
import { NavLink } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { getAvatar, getName } from "../../../../utils/Utils"
import { onSetUser } from "../../../../redux/slice/User.slice"
import Swal from "sweetalert2"
import { Color } from "../../../../utils/theme"
import UseEditUserForm from "./useForm/UseEditUserForm"
import { useAddOrEditUserMutation } from "../../../../utils/api/user/user.api"
import ModifierInfosPersosPraticien from "./Modals/ModifierInfosPersosPraticien"
import { MdOutlineManageAccounts } from "react-icons/md"
import ConfirmDeleteAccountModal from "./Modals/ConfirmDeleteAccountModal"

function MonProfil() {
  const user = useAppSelector((s) => s?.user?.user)
  const [avatar, setAvatar] = useState<any>(null)
  const { errors } = UseEditUserForm("praticien", "")
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (done) {
      dispatch(onSetUser(data))
      setAvatar(null)
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      })
    }
    const err = error as any
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      })
    }
  }, [done, isError])

  const changeImage = (e: any) => {
    const file = e.target.files[0]
    setAvatar(URL.createObjectURL(file))
    let fd = new FormData()
    fd.append("avatar", file)
    sendData({ slug: user?.slug, data: fd })
  }

  const ref = useRef<any>()

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView()
    }
  }, [errors?.telephone])

  return (
    <div className="dashbord-visiteur-component mb-4">
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container workcare-dash-praticien-page-container">
          <div className="workcare-dash-praticien-btn-disponilites-container">
            <NavLink
              to={"/praticien/mon-agenda"}
              className="btn btn-disponibilite no-link"
            >
              Gérer mes disponibilités
            </NavLink>
          </div>
          <div className="workcare-dash-admin-titre-container">
            <h4 className="workcare-dash-admin-titre-deux">Profil</h4>
          </div>
          <div className="workcares-dash-admin-profil-main-page-container ps-0 py-3">
            <div className="container-profil-user-admin py-4 text-center position-relative">
              <div className="container-img-profil-user mb-4">
                <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
                  <label htmlFor="input-test" className="input-testt">
                    <img
                      src={avatar || getAvatar(user?.avatar)}
                      alt="Profil"
                      className="custom-img-profil-user-admin"
                    />
                    <div className="leader-auto-user-avatar-edit-hover-deux">
                      {load ? (
                        <span
                          className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                          role="status"
                        ></span>
                      ) : (
                        <TiCamera className="edit-avatar-camera-icon" />
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    className="input-add-avatar"
                    id="input-test"
                    onChange={(e) => changeImage(e)}
                  />
                </div>
              </div>
              <div className="content-text-name-user">
                <h2 className="custom-name-user-admin">{getName(user)}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire">Bio</h5>
              <div className="workcare-dash-admin-section-bio-para-container">
                <p className="workcare-dash-admin-section-bio-para">
                  {user?.bio !== undefined &&
                  user?.bio !== null &&
                  user?.bio !== "null"
                    ? user?.bio
                    : "NON RENSEIGNÉ"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <div>
                <div className="workcare-dash-admin-section-edit-profil-container">
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Prénom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.prenom !== undefined &&
                        user?.prenom !== null &&
                        user?.prenom !== "null"
                          ? user?.prenom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Nom
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.nom !== undefined &&
                        user?.nom !== null &&
                        user?.nom !== "null"
                          ? user?.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Adresse
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.adresse !== undefined &&
                        user.adresse !== null &&
                        user.adresse !== "null"
                          ? user.adresse
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Adresse mail
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.email !== undefined &&
                        user.email !== null &&
                        user.email !== "null"
                          ? user?.email
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Téléphone
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.telephone &&
                        user.telephone !== "null"
                          ? user.telephone
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Spécialité
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.specialite
                          ? user.specialite?.nom
                          : "NON RENSEIGNÉ"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="workcare-dash-admin-section-edit-profil-item">
                    <div className="workcare-dash-admin-section-edit-profil-prenom">
                      <div className="workcare-dash-admin-section-edit-profil-prenom-item">
                        Expérience
                      </div>
                    </div>
                    <div className="workcare-dash-admin-section-edit-profil-btn-container">
                      <div className="workcare-dash-admin-section-edit-profil-btn-item">
                        {user?.experience !== undefined &&
                        user.experience !== null &&
                        user.experience !== "null"
                          ? user?.experience 
                          : "NON RENSEIGNÉ"}
                      </div>
                      {/* <button
                        className="btn workcare-dash-admin-section-bio-btn-edit"
                        data-bs-toggle="modal"
                        data-bs-target="#changeInfosPerso"
                      >
                        Edit
                      </button> */}
                    </div>
                  </div>
                  <hr />
                  <div className="flex-r mb-3">
                    <button
                      className="btn btn-change-password"
                      data-bs-toggle="modal"
                      data-bs-target="#changeInfosPersoPraticient"
                    >
                      Modifier
                    </button>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="changeInfosPersoPraticient"
                  aria-labelledby="changeInfosPersoPraticientLabel"
                  aria-hidden="true"
                >
                  <ModifierInfosPersosPraticien
                    user={user}
                    modalId={"changeInfosPersoPraticient"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire-deux">
                Mot de passe
              </h5>
              <div className="workcare-dash-admin-profile-section-password">
                <button
                  className="btn btn-change-password"
                  data-bs-toggle="modal"
                  data-bs-target="#ChangePasswordModal"
                >
                  Changer mot de passe
                </button>
              </div>
              <ChangePasswordModal />
            </div>
          </div>
        </div>
      </div>

      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <div className="flex-r mb-3">
                <button
                  className="workcare-dash-admin-btn-ajout-deux no-background"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteAccountModal"
                >
                  <MdOutlineManageAccounts className="workcare-dash-admin-btn-ajout-icone" />{" "}
                  Supprimer mon compte
                </button>
                <div
                  className="modal fade"
                  id="deleteAccountModal"
                  data-bs-backdrop="static"
                  aria-labelledby="deleteAccountModalLabel"
                  aria-hidden="true"
                >
                  <ConfirmDeleteAccountModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonProfil
