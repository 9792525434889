import React, { ChangeEvent, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  ContenusFormdata,
  IContenus,
} from "../../../../utils/api/contenus/contenus.type";
import {
  useAddContenuMutation,
  useDeleteContenuMutation,
} from "../../../../utils/api/contenus/contenus.api";
import { Color } from "../../../../utils/theme";
import {
  cleannerError,
  closeModal,
  getImage,
  onHide,
} from "../../../../utils/Utils";
import { useDelete } from "../../../../utils/helpers";
import { FaTrash } from "react-icons/fa";
import {
  useGetThematiqueQuery,
  useThematiqueFromLocation,
} from "../../../../utils/api/thematique/thematique";
import { useParams } from "react-router-dom";

function UseCrudContenus(modalId: string, contenu?: IContenus) {
  const validationSchema = yup.object().shape({
    hasFile: yup.boolean(),
    theme: yup.string().nullable().label("theme"),
    background_image: yup.mixed().when("hasFile", {
      is: false,
      then: yup.mixed().required().label("Image"),
    }),
    contenu: yup.string().required().label("Contenu"),
    ordre: yup
      .number()
      .required()
      .label("Ordre")
      .nullable()
      .transform((val) => (isNaN(val) ? null : val)),
  });

  const { data = { results: [] } } = useGetThematiqueQuery({ limit: 1000 });
  const [item] = useThematiqueFromLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    control,
    resetField,
  } = useForm<ContenusFormdata | any>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateContenu, { isLoading, isSuccess, error, isError }] =
    useAddContenuMutation();

  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<{ label: string; value: number }>();
  const [description, setDescription] = React.useState<any>("");
  const [word, setWord] = useState("");

  useEffect(() => {
    if (!contenu && item) {
      setValue("theme", item?.id);
    }
  }, [item]);

  useEffect(() => {
    setOptions(
      data?.results?.map((item) => {
        return {
          label: item?.libelle,
          value: item?.id,
        };
      })
    );
  }, [data]);

  const handleChange = (value: any) => {
    setDescription(value);
    setValue("contenu", value);
  };

  const [previewImage, setPreviewImage] = useState(
    contenu?.background_image ? getImage(contenu?.background_image) : null
  );
  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("background_image", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      register("background_image");
      setValue("background_image", file);
    }
  }, []);

  const handleInputChange = (val) => {
    setWord(val);
  };

  const onChangeThematique = (selected: any) => {
    setOption(selected);
    setValue("theme", selected?.value);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Contenu ${contenu ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!contenu) {
          reset();
          setDescription("");
          setPreviewImage(null);
        }
        closeModal(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: ContenusFormdata) => {
    if (!contenu && item) {
      data["theme"] = item?.id;
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "background_image") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    addOrUpdateContenu({ slug: contenu?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    description,
    setDescription,
    handleChange,
    handelImageChange,
    previewImage,
    setPreviewImage,
    option,
    options,
    Controller,
    control,
    handleInputChange,
    onChangeThematique,
    setOption,
  };
}

export function DeleteContenu({ item }: { item: IContenus }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteContenuMutation();
  const onDelete = useDelete<IContenus>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce contenu",
    successMessage: "Contenu supprimé",
  });
  return (
    <button
      className="btn btn-action-admin with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseCrudContenus;
