import React from 'react'
import CompteRenduTable from '../DiagnostiqueTables/CompteRenduTable'

function CompteRendu({rdv}) {
  return (
    <div className='dashboard-praticien-table-recent-patient-container my-4'>
      <CompteRenduTable rdv={rdv} />
    </div>
  )
}

export default CompteRendu