import React from "react";
import { Document } from "../../../../../../utils/api/document/document.type";
import { getFileUrl, get_url_extension } from "../../../../../../utils/Utils";

function ShowDocumentFile({
  modalId,
  item,
}: {
  item: Document;
  modalId: string;
}) {
  let name = item?.document
    ?.substring(item?.document?.lastIndexOf("/") + 1)
    ?.split(".")[0];
  return (
    <div
      className="modal fade "
      id={modalId}
      tabIndex={-1}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Document {name}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="pb-5 pt-3 text-start " style={{ height: "800px" }}>
              {getFileUrl(item?.document) &&
                (get_url_extension(item?.document) === "pdf" ? (
                  <iframe
                    src={getFileUrl(item?.document)}
                    width={`100%`}
                    height={`100%`}
                  ></iframe>
                ) : (
                  <iframe
                    src={
                      "https://view.officeapps.live.com/op/embed.aspx?src=" +
                      getFileUrl(item?.document)
                    }
                    width={`100%`}
                    height={`100%`}
                  ></iframe>
                ))}{" "}
              {/* <object data={baseUrl + document?.fichier} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowDocumentFile;
