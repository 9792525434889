import React from "react";
import { BiUserPlus } from "react-icons/bi";
import BreadCrumb from "../../shared/BreadCrumb";
import "./ListePraticiens.css";
import AjouterPraticienModal from "./Modals/AjouterPraticienModal";
import ListePraticiensTables from "./PraticiensTables/ListePraticiensTables";

function ListePraticiens() {
  return (
    <div className="dashbord-visiteur-component">
      <div className="workcare-dash-admin-titre-btn-container mb-4">
        <div className="workcare-dash-admin-titre-container">
          <BreadCrumb pageName="Praticiens" title="Praticiens" />
        </div>
        <>
          <button
            className="workcare-dash-admin-btn-ajout"
            data-bs-toggle="modal"
            data-bs-target="#AjouterPraticienModal"
          >
            <BiUserPlus className="workcare-dash-admin-btn-ajout-icone" />{" "}
            Ajouter
          </button>

          <div
            className="modal fade"
            id="AjouterPraticienModal"
            aria-labelledby="AjouterPraticienModalLabel"
            aria-hidden="true"
          >
            <AjouterPraticienModal modalId="AjouterPraticienModal" />
          </div>
        </>
      </div>
      <div className="workcare-dash-admin-page-content-container">
        <ListePraticiensTables />
      </div>
    </div>
  );
}

export default ListePraticiens;
