import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IContact, IResponseContact, ResponseFormData, TContactForm, TEditContactForm, TResponseContact } from "./contact.type";

export const ContactApi = createApi({
  reducerPath: "contactApi",
  tagTypes: ["contacts", "response"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getContactMessages: builder.query<PaginationResults<IContact>, TypeQuery>({
      query: (query) => QueryUrl("contacts/", query),
      providesTags: ["contacts"],
    }),
    getResponsesContact: builder.query<
      PaginationResults<TResponseContact>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`responsebycontact/${slug}`, query),
      providesTags: ["response"],
    }),
    addContact: builder.mutation<IContact, TContactForm>({
      invalidatesTags: ["contacts"],
      query: (data) => {
        return {
          url: `contact/add/`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateContact: builder.mutation<
      IContact,
      { slug?: string; data: TEditContactForm | FormData }
    >({
      invalidatesTags: ["contacts"],
      query: ({ slug, data }) => {
        return {
          url: `contacts/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteContact: builder.mutation<IContact, string>({
      query: (slug) => ({
        url: `contacts/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contacts"],
    }),
    responseContact: builder.mutation<IResponseContact, ResponseFormData>({
      invalidatesTags: ["contacts"],
      query: (data) => {
        return {
          url: `contact/responses/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
	useAddContactMutation,
	useGetContactMessagesQuery,
	useDeleteContactMutation,
	useUpdateContactMutation,
	useResponseContactMutation,
	useGetResponsesContactQuery,
} = ContactApi;